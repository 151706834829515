import React, { useContext, useEffect, useRef, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { Table, Tbody, Td, Th, Thead, Tr, useSortableData } from '../../NecttosComp/Table/Table';
import { useQuery } from 'react-query';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosInstance, getAxiosTokenInstance } from '../../utils/axiosInstance';
import moment from 'moment';
import { getDepartmentName, getMinorName } from '../Form/Component';
import { getAllMinor } from '../../NecttosComp/APICaller/ApiServices';

const getCollegeDetailsee = async ({ queryKey }: any) => {
	const [_key, collegeId] = queryKey;

	try {
		const instance = await getAxiosInstance();
		const { data } = await instance.get('/college/admissionProcess/getCollegeDetails', {
			params: { collegeId },
		});
		if (data?.statusCode === 200) {
			return data.collegeData;
		} else {
			throw new Error(data?.message || 'Failed to fetch college details.');
		}
	} catch (error) {
		throw new Error('API Error');
	}
};

function ConfirmedCourseWiseAllotment({ onClose }: { onClose: () => void }) {
	const { collegeId, user } = useContext(FirebaseContext);
	const [tableData, setTableData] = useState<any>([]);
	const [showAdvance, setAdvance] = useState<boolean>(false);
	const [needWl, setWl] = useState<boolean>(false);
	const [subjectId, setSelectClass] = useState('');
	const [date, setDate] = useState('--:--');
	const [type, setType] = useState('');
	const [newAll, setNewAll] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [catogory, setCatogory] = useState('');
	const [isLoading, setLoading] = useState<boolean>(false);

	const { data = {} } = useQuery('getAllMinor', getAllMinor(collegeId));
	const subjectList = data?.list;

	const { data: collegeData } = useQuery([`collegddeData`, collegeId], getCollegeDetailsee);

	const [table, setTable] = useState<any>([]);
	const ref = useRef<any>(null);

	const getAutoIndexdddd = async (uptodated: boolean | null) => {
		setLoading(true);
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.get('/college/fyugp/getConfirmedCourseAllotmentCourseWise', {
				params: {
					collegeId,
					type,
					subjectId,
				},
			});
			if (resp.data.statusCode === 400) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				if (resp?.data?.list?.length > 0) {
					setTableData(resp?.data.list);
					setDate(moment(resp?.data?.date).format('hh:mm A (MMM DD)'));
					setNewAll(false);
				} else {
					setTableData([]);
					setNewAll(false);
					setDate(moment(resp?.data?.date).format('hh:mm A (MMM DD)'));
				}
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (subjectId) {
			getAutoIndexdddd(null);
		}
	}, [subjectId, showAdvance, needWl]);

	useEffect(() => {
		if (catogory.length > 1) {
			getAutoIndexdddd(null);
		}
	}, [catogory]);

	useEffect(() => {
		if (tableData) {
			let data = tableData;
			if (searchTerm) {
				data = data?.filter((item: any) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
			}
			setTable(data);
		}
	}, [tableData, searchTerm]);

	const { tableItems, requestSort, getIcon } = useSortableData(table);
	const [progress, setProgress] = useState(0);
	const [remainingTime, setRemainingTime] = useState(5 * 60);

	useEffect(() => {
		const interval = setInterval(() => {
			setProgress((prev: any) => {
				if (prev < 1000) {
					return prev + 1;
				} else {
					clearInterval(interval);
					return 100;
				}
			});

			setRemainingTime((prev: any) => {
				if (prev > 0) {
					return prev - 1;
				} else {
					clearInterval(interval);
					return 0;
				}
			});
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	const formatTime = (seconds: number) => {
		const minutes = Math.floor(seconds / 60);
		const secs = seconds % 60;
		return `${minutes} minute(s) and ${secs} second(s)`;
	};
	return isLoading ? (
		<Main title='Confirmed Course Wise Allotment' height='100%' width='100%'>
			<Header>
				<div className='flex'>
					<Button type='close' onClick={onClose}>
						Close
					</Button>
				</div>
			</Header>
			<Body>
				<div className='flex flex-col items-center justify-center h-screen text-center bg-gradient-to-b from-blue-800 to-blue-600 text-white overflow-hidden font-sans'>
					<p className='text-6xl font-bold my-10 animate-scroll'>Getting FYUGP allotment List...</p>
					<p className='text-4xl my-10 animate-scroll animation-delay-2000'>Please wait, this process may take some time.</p>
					<p className='text-3xl my-10 animate-scroll animation-delay-4000'>We appreciate your patience as we generate accurate and up-to-date rankings for you.</p>
					<p className='text-3xl my-10 animate-scroll animation-delay-4000'>We strive to save you time and improve your life balance.</p>
				</div>
			</Body>
		</Main>
	) : (
		<>
			<Main title={'Confirmed Course Wise Allotment ' + tableItems?.length} height='100%' width='100%'>
				<Header>
					<div className='flex'>
						<Input fieldName='Filter by Catogory' state={type} setState={setType} options={['DSC_B', 'DSC_C', 'MDC']} type='drop' width='10vw' />
						<Input fieldName='Filter by Course' state={subjectId} setState={setSelectClass} didntShowKey optionDisplay='subjectName' optionKey='_id' options={subjectList?.filter((x: any) => x.type === type)} type='drop' width='35vw' />
						<Input fieldName='Search' state={searchTerm} setState={setSearchTerm} type='text' width='15vw' />
					</div>
					<div className='flex'>
						<Button
							type={showAdvance ? 'save' : 'fetch'}
							onClick={() => {
								setAdvance(!showAdvance);
							}}>
							{showAdvance ? 'Show Normal Mode' : 'Show Advance Mode'}
						</Button>
						<Button fileName={getMinorName(subjectId, subjectList)?.toUpperCase()} type='print' tableRef={ref.current}>
							Print
						</Button>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>

				<Body>
					<div ref={ref} className='printable overflow-auto '>
						<Table width='100%' innerWidth={['10%', '30%', '6%', '10%', '10%', '10%']}>
							<table className='min-w-full table-auto'>
								<thead>
									<tr>
										<td
											className='p-1 bg-white '
											style={{
												width: '70%',
												fontFamily: 'Times New Roman',
											}}>
											<img src={collegeData?.dashBoardLink} alt='College Logo' className='h-[100px] w-auto mx-auto' />
										</td>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td
											className='p-1 text-center text-black bg-white text-xl font-bold'
											style={{
												width: '70%',
												fontFamily: 'Times New Roman',
											}}>
											FYUGP 2024 - CONFIRMED STUDENT ALLOTMENT LIST FROM NECTTOS @ {moment(new Date()).format('DD-MM-YYYY hh:mm A')}
										</td>
									</tr>
									<tr>
										<td
											className='p-1 text-center text-black bg-white text-lg'
											style={{
												width: '70%',
												fontFamily: 'Times New Roman',
											}}>
											Paper Name: {getMinorName(subjectId, subjectList)?.toUpperCase()}
										</td>
									</tr>
									<tr>
										<td
											className='p-1 text-center text-black bg-white text-lg'
											style={{
												width: '70%',
												fontFamily: 'Times New Roman',
											}}>
											Department: {getDepartmentName(subjectId, subjectList).toUpperCase()}
										</td>
									</tr>
								</tbody>
							</table>

							<Thead>
								<Tr>
									<Th textAlign='center' position={0}>
										Admn.No.
									</Th>
									<Th textAlign='center' position={2} onClick={() => requestSort('rank')} icon={getIcon('rank')}>
										Rank
									</Th>
									<Th textAlign='center' position={2} onClick={() => requestSort('rank')} icon={getIcon('rank')}>
										Seat Conformed At
									</Th>
									<Th position={2} onClick={() => requestSort('name')} icon={getIcon('name')}>
										Phone
									</Th>
									<Th position={1} onClick={() => requestSort('name')} icon={getIcon('name')}>
										Name of the Candidate
									</Th>
									<Th position={0} onClick={() => requestSort('className')} icon={getIcon('className')}>
										Department
									</Th>
									{showAdvance && (
										<>
											<Th textAlign='center' position={3} onClick={() => requestSort('priority')} icon={getIcon('priority')}>
												Priority
											</Th>
											<Th textAlign='center' position={2} onClick={() => requestSort('index')} icon={getIcon('index')}>
												Index %
											</Th>
											<Th textAlign='center' position={3} onClick={() => requestSort('quota')} icon={getIcon('quota')}>
												Alloted Quota
											</Th>
											<Th textAlign='center' position={3} onClick={() => requestSort('ogQuoata')} icon={getIcon('ogQuoata')}>
												Actual Quota
											</Th>
										</>
									)}
								</Tr>
							</Thead>

							<Tbody height='auto'>
								{tableItems?.map((x: any, i: number) => (
									<>
										<Tr>
											<Td textAlign='center' index={i} position={0}>
												{x?.admisionNo}
											</Td>
											<Td textAlign='center' index={i} position={2}>
												{x?.rank}
											</Td>
											<Td color={x.seatConformed ? 'green' : 'red'} textAlign='center' index={i} position={2}>
												{x?.confirmedAt}
											</Td>
											<Td index={i} position={2}>
												{x?.phoneNumber}
											</Td>
											<Td index={i} position={1}>
												{x?.name?.toUpperCase()}
											</Td>
											<Td index={i} position={0}>
												{x?.courseName?.toUpperCase()}
											</Td>
											{showAdvance && (
												<>
													<Td textAlign='center' index={i} position={3}>
														{x?.priority}
													</Td>
													<Td textAlign='center' index={i} position={2}>
														{String(x.index).slice(0, 7)}
													</Td>
													<Td textAlign='center' index={i} position={3}>
														{x?.quota?.toUpperCase()}
													</Td>
													<Td textAlign='center' index={i} position={3}>
														{x?.ogQuoata?.toUpperCase()}
													</Td>
												</>
											)}
										</Tr>
									</>
								))}
							</Tbody>
						</Table>
					</div>
				</Body>
			</Main>
		</>
	);
}

export default ConfirmedCourseWiseAllotment;
