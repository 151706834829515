import React, { useEffect, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import './ScienceFestForm.css';
import { NECTOTS_API_URL } from '../../config';
import axios from 'axios';
import moment from 'moment';
import Button from '../../NecttosComp/Button/Button';

const ScienceFestForm = () => {
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		choice: [],
		departments: [],
	});
	console.log({ formData });

	const [otpSent, setOtpSent] = useState(false);
	const [currentPage, setCurrentPage] = useState('form');
	const [otpVerified, setOtpVerified] = useState(false);
	const [startDate, setStartDate] = useState(new Date());
	const [confirm, setConfirm] = useState(new Date());
	const [isLoading, setIsLoading] = useState(false);
	console.log({ confirm });
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const verifyOtp = () => {
		axios
			.post(`${NECTOTS_API_URL}/fest/fest-verify-otp`, { token: confirm, otp: formData.otp })
			.then((response) => {
				localStorage.setItem('festToken', response.data.jwtToken);
				console.log('Token set:', localStorage.getItem('festToken'));
				setOtpVerified(true);
			})
			.catch((err) => {
				console.log("error setting otp");
				
			});
	};
	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const response = await axios.get(`${NECTOTS_API_URL}/fest/getByUser`, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('festToken')}`,
					},
				});

				const { data } = response;
				if (data && data._id) {
					setFormData({
						...formData,
						...data,
					});
					setCurrentPage(data.timeSlots || 'form');

					setOtpVerified(true);
				}
			} catch (error) {
				console.error('Error fetching user data:', error);
			}
		};

		fetchUserData();
	}, [isLoading, localStorage.getItem('festToken')]);

	const dynamicColor = (index) => {
		const colors = ['#ff4500', '#4caf50', '#00bcd4', '#f44336', '#9c27b0'];
		return colors[index % colors.length];
	};

	const handleCheckboxChange = (e) => {
		const value = e.target.value;
		setFormData((prevState) => {
			console.log('Current Departments:', prevState.departments);
			console.log('Toggled Department:', value);

			if (prevState.departments.includes(value)) {
				const newDepartments = prevState.departments.filter((dep) => dep !== value);
				console.log('New Departments after removal:', newDepartments);
				return { ...prevState, departments: newDepartments };
			} else {
				const newDepartments = [...prevState.departments, value];
				console.log('New Departments after addition:', newDepartments);
				return { ...prevState, departments: newDepartments };
			}
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		axios
			.post(`${NECTOTS_API_URL}/fest/updateFest`, { ...formData })
			.then((response) => {
				console.log({ response });
			})
			.catch((err) => {});

		console.log('Form data submitted:', formData);
	};

	const sendOtp = () => {
		axios
			.post(`${NECTOTS_API_URL}/fest/fest-sent-otp`, { phoneNumber: `+91${formData.mobile}` })
			.then((response) => {
				setConfirm(response.data.token);
			})
			.catch((err) => {});
		console.log(`OTP sent to ${formData.mobile}`);
		setOtpSent(true);
	};

	const currentDate = new Date();
	const year = currentDate.getFullYear();
	const month = currentDate.getMonth() + 1; // getMonth() returns 0-11
	const minDate = `${year}-${month.toString().padStart(2, '0')}-16`;
	const maxDate = `${year}-${month.toString().padStart(2, '0')}-18`;

	const handleNextPage = () => {
		setIsLoading(true);

		axios
			.post(`${NECTOTS_API_URL}/fest/updateFest`, { ...formData })
			.then((response) => {
				console.log({ response });
				setTimeout(() => {
					setIsLoading(false);
					setCurrentPage('timeSlots');
				}, 400);
			})
			.catch((err) => {
				setTimeout(() => {
					setIsLoading(false);
				}, 400);
			});
	};

	const handleEdit = () => {
		setCurrentPage('form');
	};
	const handleDateChange = (date) => {
		setStartDate(date);
	};

	const scienceDepartments = ['Biotechnology', 'Botany', 'Chemistry', 'Computer Science', 'Environment Science', 'Geology', 'Life Science', 'Mathematics', 'Nanoscience and Technology', 'Physics', 'Statistics', 'Zoology', 'Institute of Engineering'];

	const otherDepartments = ['Arabic', 'Commerce and Management Studies', 'Education', 'English', 'Folklore Studies', 'Hindi', 'History', 'Law', 'Library and Information Science', 'Malayalam & Kerala Studies', 'Mass communication and Journalism', 'Philosophy', 'Physical Education', 'Political Science', 'Psychology', 'Russian & Comparative Literature', 'Sanskrit', 'Sociology', "Women's Studies"];
	
	const imageUrls = ['https://firebasestorage.googleapis.com/v0/b/necttos-production.appspot.com/o/banner1.jpeg?alt=media&token=65fc02d3-1b5d-4f33-a0ad-b8c904210fe7'];

	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
		}, 3000);
		return () => clearInterval(intervalId);
	}, [imageUrls.length]);

	console.log({formData});
	

	return (
		<div className='form-container'>
			<h1 className='form-title'>Shashtrayan - Open House : Registration</h1>
			<form className='form' onSubmit={handleSubmit}>
				{!otpVerified ? (
					<>
						<div className='image-container'>
							<img src={imageUrls[currentImageIndex]} alt='Science Fest' className='form-image' />
						</div>
						<div className='form-group'>
							<label class='form-label' htmlFor='mobile'>
								Mobile Number:
							</label>
							<input className='form-input' type='tel' id='mobile' name='mobile' value={formData.mobile} onChange={handleChange} maxLength='10' pattern='\d{10}' title='Please enter exactly 10 digits' />
						</div>
						<Button type='button' onClick={sendOtp}>
							Send OTP
						</Button>
						{otpSent && (
							<div className='form-group'>
								<label class='form-label' htmlFor='otp'>
									Enter OTP:
								</label>
								<input className='form-input' type='text' id='otp' name='otp' maxLength='6' value={formData.otp} onChange={handleChange} />
								<Button type='fetch' onClick={verifyOtp}>
									Verify OTP
								</Button>
							</div>
						)}
					</>
				) : (
					<>
						{currentPage === 'form' ? (
							<>
								<div className='image-container'>
									<img src={imageUrls[currentImageIndex]} alt='Science Fest' className='form-image' />
								</div>
								<div className='form-group'>
									<label class='form-label' htmlFor='name'>
										Name:
									</label>
									<input className='form-input' type='text' id='name' name='name' value={formData.name} onChange={handleChange} />
								</div>
								<div className='form-group'>
									<label class='form-label' htmlFor='email'>
										Email:
									</label>
									<input className='form-input' type='email' id='email' name='email' value={formData.email} onChange={handleChange} />
								</div>
								<div className='form-group'>
									<label className='form-label' htmlFor='teamLength'>
										No. of Participants
									</label>
									<input className='form-input' type='number' id='teamLength' name='teamLength' value={formData.teamLength} onChange={handleChange} min='1' max='50' />
								</div>
								<div className='form-group'>
									<label class='form-label'>Select Science Departments:</label>

									<div className='flex flex-wrap gap-3'>
										{scienceDepartments.map((dep, index) => (
											<div className='flex pl-1 pr-4 bg-purple-200 items-center flex-auto justify-start rounded pt-2'>
											<div className='flex items-center gap-3 pl-2 py-2' key={index}>
												<input
												className='mt-2'
													type='checkbox'
													id={dep}
													value={dep}
													onChange={handleCheckboxChange}
													checked={formData.departments.includes(dep)} // Ensure this line is correct
												/>
												<label htmlFor={dep} className='text-nowrap text-lg font-bold'>{dep}</label>
											</div>
											</div>
										))}
									</div>
									
								</div>
								<div className='form-group'>
								<label class='form-label'>Other Departments:</label>
									<div className='flex flex-wrap gap-3'>
										{otherDepartments.map((dep, index) => (
											<div className='flex pl-1 pr-4 bg-purple-200 items-center flex-auto justify-start rounded pt-2'>
											<div className='flex items-center gap-3 pl-2 py-2' key={index}>
												<input
												className='mt-2'
													type='checkbox'
													id={dep}
													value={dep}
													onChange={handleCheckboxChange}
													checked={formData.departments.includes(dep)} // Ensure this line is correct
												/>
												<label htmlFor={dep} className='text-nowrap text-lg font-bold'>{dep}</label>
											</div>
											</div>
										))}
									</div>
									
								</div>
								<div className='form-group'>
									<Button type='save' onClick={handleNextPage}>
										Save and Next
									</Button>
								</div>
							</>
						) : (
							<div>
								<div className='header-info'>
									<div className='image-container'>
										<img src={imageUrls[currentImageIndex]} alt='Science Fest' className='form-image' />
									</div>
									<span>Name: {formData.name} </span>
									<span>Email: {formData.email} </span>
									<span>No. of Participants: {formData.teamLength} </span>
									<div className='department-list'>
										{formData.departments.map((dep, index) => (
											<span key={index} className='department-item' style={{ color: 'white', backgroundColor: dynamicColor(index) }}>
												{dep}
											</span>
										))}
									</div>
									<button type='button' onClick={handleEdit}>
										Edit
									</button>
								</div>
								<h1 className='form-title'>Available Time Slots</h1>
								<div className='form-group'>
									<label className='form-label' htmlFor='otp'>
										Select Date:
									</label>
									<input
										className='form-input'
										type='date'
										id='date'
										name='date'
										min={minDate}
										max={maxDate}
										value={formData.date}
										onChange={(e) => {
											handleChange(e);
											handleDateChange(e);
										}}
									/>
									<Button type='fetch'>Get Time Slot</Button>
								</div>
								<TimeSlots setFormData={setFormData} formData={formData} selectedDepartments={formData.departments.slice(0, 6)} />
								<Button type='save' onClick={handleNextPage}>
									{isLoading ? 'Saving...' : 'Save'}
								</Button>
								<div className='update-card'>
									<span className='update-text'>Successfully Updated On:</span>
									<span className='update-date'>{moment(new Date(formData?.updateOne)).format('DD-MMM-YYYY hh:mm A')}</span>
								</div>
							</div>
						)}
					</>
				)}
			</form>
		</div>
	);
};

export default ScienceFestForm;

const TimeSlots = ({ setFormData, formData, selectedDepartments }) => {
	const [allocatedTime, setAllocatedTime] = useState({});

	const generateTimeSlots = (startTime, endTime) => {
		let slots = [];
		let currentTime = startTime;

		while (currentTime < endTime) {
			slots.push(currentTime);
			currentTime += 1;
		}
		return slots;
	};

	const formatTime = (time) => {
		if (time === undefined) {
			return 'Select Time';
		}

		let hours = Math.floor(time);
		let minutes = (time % 1) * 60;
		const suffix = hours >= 12 ? 'PM' : 'AM';

		// Convert to 12-hour format
		hours = hours % 12 || 12;

		// Add leading zero to minutes if needed
		const minutesFormatted = minutes === 0 ? '00' : minutes;

		return `${hours}:${minutesFormatted} ${suffix}`;
	};

	const timeSlots = generateTimeSlots(10, 17);

	const allocateTime = (department, time) => {
		setAllocatedTime({
			...allocatedTime,
			[department]: time,
		});

		setFormData({ ...formData, choice: allocatedTime });
	};

	useEffect(() => {
		setAllocatedTime({
			...formData.choice,
			...allocatedTime,
		});
	}, [formData.date]);

	return (
		<Formik
			initialValues={{
				departments: selectedDepartments.reduce((acc, department) => ({ ...acc, [department]: '' }), {}),
			}}
			onSubmit={(values) => console.log('Submitted:', values)}>
			{(formikProps) => (
				<Form>
					{selectedDepartments.map((department, index) => (
						<div key={index} className='department-time'>
							<h2>{department}</h2>
							<Field
								as='select'
								name={`departments.${department}`}
								onChange={(e) => {
									const selectedTime = e.target.value;
									allocateTime(department, selectedTime);
									formikProps.setFieldValue(`departments.${department}`, selectedTime);
								}}>
								<option value=''>{formatTime(allocatedTime[department])}</option>
								{timeSlots.map((time, i) => {
									if (time === 13) {
										return null;
									}
									const hours = Math.floor(time);
									const minutes = (time % 1) * 60;
									return (
										<option key={i} value={time}>
											{`${hours}:${minutes === 0 ? '00' : minutes}`}
										</option>
									);
								})}
							</Field>
						</div>
					))}
				</Form>
			)}
		</Formik>
	);
};
