import React, { useContext, useEffect, useRef, useState } from 'react';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useMutation, useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Table, Tbody, Td, Th, Thead, Tr, useSortableData } from '../../NecttosComp/Table/Table';
import { getAdmissionRegister3, getAllMembers, getBatches, getKeys, getNewClasses, getNextSequenceTcNumber, getNextTcNumber, getProfileData, getStudentAttendance1, getTCRequests, getTcLists } from '../../NecttosComp/APICaller/ApiServices';
import moment from 'moment';
import { getCollegeDetailsee } from '../Admission/RemoveAction';
import toast, { Toaster } from 'react-hot-toast';
import { FaCog } from 'react-icons/fa';
import { PulseLoader } from 'react-spinners';
import { Progress, message } from 'antd';
import { debounce } from 'lodash';
import InstantTc from './InstantTc';
import EditTc from './EditTc';
import ApplyTc from './ApplyTc';
import { getEditData } from '../Accounts/FeesBilling/feesManagementServices';
import { BillNumberModal2 } from '../Admission/BillNumberModal2';
import TCView2 from './TCView2';
import EditTcFinal from './EditTcFinal';

interface invoiceManagementProps {
	onClose: () => void;
}

export const exportPdfCollectionReport = async ({ postData }: any) => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.post(`/college/reportGeneration/generateTcCc`, postData, {
			responseType: 'blob',
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			console.log({ resp: resp.data });

			return resp.data;
		}
	} catch (e) {
		throw new Error();
	}
};

function NewTransferCertificate({ onClose }: invoiceManagementProps) {
	const [addNew, setAddNew] = useState<any>();
	const [admn, setAdmn] = useState<any>();
	const [tcNo, setTcNo] = useState<any>();
	const [tcDate, setTcDate] = useState<any>();
	const { collegeId, user, collegeList } = useContext(FirebaseContext);
	const selectedCollege = collegeList?.find((x: any) => x.collegeId === collegeId);
	const ref = useRef<any>(null);
	const [selectedClass, setSelectClass] = useState('');
	const [selectedBatch, setSelectedBatch] = useState<any>({});
	const [selectModal, setSelectModal] = useState(false);
	const [advanced, setAdvanced] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [filterData, setFilter] = useState<any>([]);
	const [tableData, setTableData] = useState<any>([]);
	const [selectedFields, setSelectedFields] = useState<any>([]);
	const [classData, setClassData] = useState<any>([]);
	const [filterKey, setFilterKey] = useState('ALL');
	const [printable, setPritable] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [viewMissing, setViewMissing] = useState(false);
	const [viewMissingTc, setViewMissingTc] = useState(false);
	const [profileData, setProfileData] = useState<any>({});
	const [missingAdmNo, setMissingAdmNo] = useState<any>([]);
	const [missingTcNo, setMissingTcNo] = useState<any>([]);
	const [openAccordion, setOpenAccordion] = useState<string | null>(null);
	const [originalClassData, setOriginalClassData] = useState<any>([]);
	const [advancedSearch, setAdvancedSearch] = useState('');
	const [iSearch, setISearch] = useState(true);
	const [tcView2, setTcView2] = useState<any>(false);

	const { data: classes } = useQuery(['getNewClasses'], getNewClasses(collegeId, user?.uid));
	const { data: batches } = useQuery('getBatches', getBatches({ collegeId }));
	const { data: fieldsData } = useQuery('getKeys', getKeys());
	const { data: collegeData } = useQuery([`collegddeData`, collegeId], getCollegeDetailsee);

	const mainKeyArray = ['name', 'batchName', 'shortName', '_id', 'uid'];
	const { data: sequenceData } = useQuery(['getSequence'], getNextTcNumber(collegeId));

	const [requests, setRequests] = useState<boolean>(false);

	const [advancedSearchQuery, setAdvancedSearchQuery] = useState('');
	const [isAdvancedSearch, setIsAdvancedSearch] = useState(true);
	const [filteredMembers, setFilteredMembers] = useState([]);
	const [loading, setLoading] = useState<any>(false);
	const [tcLoading, seTctLoading] = useState<any>(false);
	const [Edit, setEdit] = useState<any>({ value: [], isEdit: false });
	const [editTc, setEditTc] = useState<any>({ value: [], isEditTc: false });
	const [instTc, setInstTc] = useState(false);
	const [apply, setapply] = useState(false);
	const [userData, setUserData] = useState({});
	const [data, setData] = useState<any>([]);
	const [refresh, setRefresh] = useState(false);
	const refetch = () => {
		setRefresh(!refresh);
	};

	const [state, setState] = React.useState({
		startDate: '',
		endDate: '',
		lastTcNumber: '',
		classId: '',
	});
	// const { data = [], refetch, isFetching }:any = useQuery(['getTcLists', state?.classId, addNew?.startDate, addNew?.endingDate, admn?.startAdmNo, admn?.endAdmNo, tcDate?.tcStartDate, tcDate?.tcEndDate, tcNo?.startTcNo, filterKey, tcNo?.endTcNo, selectedClass, selectedBatch, selectedFields, advancedSearch], getTcLists({ collegeId, classId: selectedClass, admnStartDate: addNew?.startDate, admnEndingDate: addNew?.endingDate, startAdmNo: admn?.startAdmNo, endAdmNo: admn?.endAdmNo, tcStartDate: tcDate?.tcStartDate, tcEndDate: tcDate?.tcEndDate, startTcNo: tcNo?.startTcNo, endTcNo: tcNo?.endTcNo, filterKey, selectedBatch: selectedBatch?.batch, searchQuery: advancedSearch,setLoading }));
	const getTcLists = async (data = { collegeId, classId: selectedClass, admnStartDate: addNew?.startDate, admnEndingDate: addNew?.endingDate, startAdmNo: admn?.startAdmNo, endAdmNo: admn?.endAdmNo, tcStartDate: tcDate?.tcStartDate, tcEndDate: tcDate?.tcEndDate, startTcNo: tcNo?.startTcNo, endTcNo: tcNo?.endTcNo, filterKey, selectedBatch: selectedBatch?.batch, searchQuery: advancedSearch, setLoading }) => {
		seTctLoading(true);
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.get('/college/transferCertificate/getTCList', {
				params: {
					...data,
				},
			});
			if (resp.data.statusCode === 400) {
				seTctLoading(false);
				throw new Error(resp.data.message || 'API Error');
			} else {
				setData(resp?.data);
				seTctLoading(false);
			}
		} catch (error: any) {
			seTctLoading(false);
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		}
	};

	useEffect(() => {
		if (!requests) {
			getTcLists();
		}
	}, [refresh, state?.classId, addNew?.startDate, addNew?.endingDate, admn?.startAdmNo, admn?.endAdmNo, tcDate?.tcStartDate, tcDate?.tcEndDate, tcNo?.startTcNo, filterKey, tcNo?.endTcNo, selectedClass, selectedBatch, selectedFields]);

	const { data: requestsData = [], isFetching: isFetchReq, refetch: refetchReq } = useQuery(['tecRequest', collegeId, selectedClass, selectedBatch, filterKey, requests, refresh], getTCRequests({ collegeId, classId: selectedClass, selectedBatch: selectedBatch?.batch, filterKey, searchQuery: advancedSearch, setLoading }));

	const [settingsModal, setSettingsModal] = useState({
		openModal: false,
		newTcNo: '',
		newBatchNo: '',
		newSelfTcNo: '',
		newSelfBatchNo: '',
	});

	const aproveTcCc = async (data: any) => {
		setLoading(true);
		try {
			const instance = await getAxiosTokenInstance();
			const resp = await instance.post('/college/transferCertificate/addToMessageTcCcRequestsNew', {
				collegeId,
				messageData: '',
				checkRole: '',
				action: 'aprove',
				classId: '',
				departmentId: '',
				userId: data?.studentsDetails?._id,
			});
			if (resp.data.statusCode !== 200) {
				toast.error('Error approving TC: API error');
			}
			toast.success('Approval successful.');
		} catch (error) {
			console.error('Error approving TC:', error);
			toast.error('Error approving TC:');
		} finally {
			setLoading(false);
			refetchReq();
			refetch();
		}
	};

	const deleteTC = async (data: any) => {
		if (!window.confirm('Are you sure you want to delete this TC request?')) {
			toast('Deletion cancelled by user.');
			return;
		}
		setLoading(true);
		try {
			const instance = await getAxiosTokenInstance();
			const resp = await instance.post('/college/transferCertificate/addToMessageTcCcRequestsNew', {
				collegeId,
				messageData: '',
				checkRole: '',
				action: 'delete',
				classId: '',
				departmentId: '',
				userId: data?.studentsDetails?._id,
			});
			if (resp.data.statusCode !== 200) {
				toast.error('Error deleting TC: API error');
			}
			toast.success('Deletion successful.');
		} catch (error) {
			console.error('Error deleting TC:', error);
			toast.error('Error deleting TC');
		} finally {
			setLoading(false);
			refetchReq();
		}
	};

	// const updateTCNumber = async (data: any) => {
	// 	const instance = await getAxiosTokenInstance();
	// 	let resp;
	// 	resp = await instance.post('/college/requestAprovals/updateTcNumber', {
	// 		collegeId,
	// 		lastTcNumber: state?.lastTcNumber,
	// 	});
	// 	if (resp.data.statusCode !== 200) {
	// 		throw new Error(resp.data.message || 'API Error');
	// 	} else {
	// 		refetch();
	// 	}
	// };

	const handleTcView = (item: any) => {
		setTcView2(true);
		setUserData(item);
	};

	const exportPdfMutationDateCollectionReport = useMutation(exportPdfCollectionReport);

	const onTcCc = async (data: any) => {
		setLoading(true);
		try {
			// Ensure valid data
			if (!data || !data.tcData) {
				throw new Error('Invalid data passed to generate PDF');
			}

			const link = document.createElement('a');
			link.target = '_blank';

			let postData = {
				collegeId,
				startsNumber: '' + data?.tcNumber,
				userId: data?._id,
				applicationDate: data?.tcData[0]?.appliedOn,
				leavingDate: data?.tcData[0]?.dateOfLeaving,
				reasonForLeaving: data?.tcData[0]?.reasonForLeaving,
				exam: data?.tcData[0]?.exam,
				joinedSem: data?.tcData[0]?.joinedSem,
				leaving: data?.tcData[0]?.leaving,
				signatureDate: data?.tcData[0]?.aprovedOn,
			};
			console.log({ postData });
			const pdfData = await exportPdfMutationDateCollectionReport.mutateAsync({ postData });
			if (!pdfData || pdfData.size === 0) {
				throw new Error('Failed to generate PDF or received empty PDF data');
			}
			const blob = new Blob([pdfData], { type: 'application/pdf' });
			if (blob.size === 0) {
				throw new Error('Blob creation failed or resulted in an empty file');
			}
			link.href = URL.createObjectURL(blob);
			link.download = data?.studentsDetails?.name.split('.').join(' ');
			link.click();
			URL.revokeObjectURL(link.href);
		} catch (e) {
			console.error('Error generating PDF:', e);
		}
		setLoading(false);
	};

	const columns = [
		{
			title: 'S.N.',
			keyName: 'slNo',
			width: '40px',
		},
		{
			title: 'TC Number',
			keyName: 'tcNumber',
			width: '100px',
		},
		{
			title: 'Admission No',
			keyName: 'admisionNo',
			width: '130px',
		},
		{
			title: 'action',
			keyName: 'action',
			width: '180px',
		},
		{
			title: 'Name',
			keyName: 'name',
			width: '250px',
		},
		{
			title: 'Class Name',
			keyName: 'className',
			key: 'className',
			width: '180px',
		},
		{
			title: 'Date of Admn',
			keyName: 'dateOfAdmission',
			width: '120px',
			type: 'date',
		},

		{
			title: 'Leaving Date',
			keyName: 'tcDate',
			width: '120px',
			type: 'date',
		},
	];

	const columnsRequests = [
		{
			title: 'S.N.',
			keyName: 'slNo',
			width: '40px',
		},
		{
			title: 'TC Number',
			keyName: 'tcNumber',
			width: '100px',
		},
		{
			title: 'Admission No',
			keyName: 'admisionNo',
			width: '130px',
		},
		{
			title: 'action',
			keyName: 'action',
			width: '200px',
		},
		{
			title: 'Name',
			keyName: 'name',
			width: '250px',
		},
		{
			title: 'Class Name',
			keyName: 'className',
			key: 'className',
			width: '180px',
		},
		{
			title: 'Date of Admn',
			keyName: 'dateOfAdmission',
			width: '120px',
			type: 'date',
		},

		{
			title: 'Applied Date',
			keyName: 'appliedOn',
			width: '120px',
			type: 'date',
		},
	];

	// Debounced refetch for the advanced search
	// const debouncedRefetch = debounce(() => {
	// 	refetch();
	// }, 500);

	// useEffect(() => {
	// 	if (isAdvancedSearch) {
	// 		debouncedRefetch();
	// 	}
	// }, [advancedSearchQuery]);

	// Local search filter
	useEffect(() => {
		if (!isAdvancedSearch) {
			const result = data?.list?.filter((member: any) => member?.studentsDetails?.name?.includes(searchQuery) || member?.studentsDetails?.admisionNo?.includes(searchQuery));
			if (searchQuery === '') {
				setFilteredMembers(data?.list);
			} else {
				setFilteredMembers(result);
			}
		}
	}, [searchQuery, data, isAdvancedSearch]);

	useEffect(() => {
		if (iSearch === false) {
			setAdvancedSearch('');
		}
	}, [iSearch]);

	useEffect(() => {
		if (sequenceData) {
			setSettingsModal((prv) => ({
				...prv,
				newTcNo: sequenceData.lastTcNumber,
				newBatchNo: sequenceData.lastTcBatchNumber,
				newSelfTcNo: sequenceData.lastSelfTcNumber,
				newSelfBatchNo: sequenceData.lastSelfTcBatchNumber,
			}));
		}
	}, [sequenceData]);

	const handleSequenceUpdate = async () => {
		try {
			const instance = await getAxiosTokenInstance();
			let resp = await instance.post('/college/transferCertificate/updateTcSequenceNumbers', {
				type: 'tc',
				collegeId,
				userId: user.uid,
				tcNo: settingsModal?.newTcNo + '',
				tcBatchNo: settingsModal?.newBatchNo ? settingsModal?.newBatchNo + '' : null,
				tcNoSelf: settingsModal?.newSelfTcNo + '',
				tcBatchNoSelf: settingsModal?.newSelfBatchNo ? settingsModal?.newSelfBatchNo + '' : null,
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				setSettingsModal({ ...settingsModal, openModal: false });
			}
		} catch (error: any) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		}
	};

	const toggleAccordion = (accordionName: string) => {
		setOpenAccordion(openAccordion === accordionName ? null : accordionName);
	};

	const calculatedWidth = columns.reduce((acc: any, item: any) => {
		let wid = parseInt(item.width.slice(0, -2));
		acc += wid;
		return acc;
	}, 0);

	const tableWidth = calculatedWidth < window.innerWidth ? '100%' : `${calculatedWidth}px`;
	const handleReset = () => {
		setFilterKey('');
		setAddNew('');
		setTcDate('');
		setTcNo('');
		setAdmn('');
		setSelectClass('');
		setSelectedBatch('');
		setAdvancedSearch('');
		setSearchQuery('');
		setRefresh(!refresh);
	};

	useEffect(() => {
		if (data) {
			setTableData(data.list);
		}
	}, [data]);
	useEffect(() => {
		if (iSearch) {
			setFilterKey('');
			setAddNew('');
			setTcDate('');
			setTcNo('');
			setAdmn('');
			setSelectClass('');
			setSelectedBatch('');
			setSearchQuery('');
		}
	}, [iSearch]);

	// useEffect(() => {
	// 	getSavedSettings();
	// }, []);

	useEffect(() => {
		if (!data) return;
		let filtered = data.list;

		if (searchQuery) {
			const lowerCaseQuery = searchQuery.toLowerCase();
			filtered = filtered.filter((data: any) => data?.name?.toLowerCase().includes(lowerCaseQuery) || data?.phoneNumber?.toString().includes(searchQuery) || (data?.tcNumber && data.tcNumber.toString().includes(searchQuery)) || data?.admisionNo?.toString().includes(searchQuery));
			setTableData(filtered);
		} else {
			setTableData(data?.list);
		}
	}, [filterKey, searchQuery]);

	const { tableItems, requestSort, getIcon } = useSortableData(tableData);

	useEffect(() => {
		if (classes?.list) {
			setOriginalClassData(classes.list);
			setClassData(classes.list);
		}
	}, [classes]);

	useEffect(() => {
		let filteredData = originalClassData;
		if (selectedBatch) {
			filteredData = filteredData.filter((item: any) => item.batchName === selectedBatch.batch);
		}
		// if (selectedClass) {
		// 	filteredData = filteredData.filter((item: any) => item._id === selectedClass);
		// }
		// if (filterKey!=='ALL') {
		// 	filteredData = filteredData.filter((item: any) => item.governBody === filterKey);
		// }
		setClassData(filteredData);
	}, [selectedBatch, selectedClass, originalClassData, filterKey]);

	useEffect(() => {
		setSelectClass('');
		setSelectedBatch('');
	}, [filterKey, addNew]);

	useEffect(() => {
		// setFilterKey('');
		setAddNew('');
		setTcDate('');
		setTcNo('');
		setAdmn('');
		setSelectClass('');
	}, [selectedBatch]);
	useEffect(() => {
		// setFilterKey('');
		setAddNew('');
		setTcDate('');
		setTcNo('');
		setAdmn('');
	}, [selectedClass]);
	useEffect(() => {
		setSelectClass('');
		setSelectedBatch('');
		setAddNew('');
		setTcDate('');
		setTcNo('');
	}, [admn]);
	useEffect(() => {
		setSelectClass('');
		setSelectedBatch('');
		setAdmn('');
		setTcDate('');
		setTcNo('');
	}, [addNew]);
	useEffect(() => {
		setSelectClass('');
		setSelectedBatch('');
		setAddNew('');
		setAdmn('');
		setTcDate('');
	}, [tcNo]);
	useEffect(() => {
		setSelectClass('');
		setSelectedBatch('');
		setAdmn('');
		setAddNew('');
		setTcNo('');
	}, [tcDate]);

	//--------view fee detail modal---------//

	const ViewModal = ({ isOpen, onClose, title, children, data }: any) => {
		if (!isOpen) return null;
		console.log({ data });

		return (
			<div className='fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-10'>
				<div className='bg-white rounded-lg overflow-hidden shadow-lg max-w-7xl w-full p-2  h-auto min-h-[40vh] max-h-[70vh]'>
					<div className='p-4 border-b flex justify-between'>
						<h3 className='text-lg font-medium text-gray-900'>{title}</h3>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
					<div>
						<Table width='100%' innerWidth={['5%', '15%', '10%', '10%', '10%', '10%', '10%', '30%']}>
							<Thead>
								<Tr>
									<Th position={0}>Sl.No.</Th>
									<Th position={1}>Governed By</Th>
									<Th position={2}>Title</Th>
									<Th position={3}>Receipt Number</Th>
									<Th position={4}>Amount</Th>
									<Th position={5}>Method</Th>
									<Th position={5}>Year</Th>
									<Th position={6}>Date</Th>
								</Tr>
							</Thead>
							<Tbody height='auto'>
								{data?.innerElements && data?.innerElements?.length > 0 ? (
									data?.innerElements?.map((item: any, i: any) => (
										<Tr>
											<Td index={i} position={0} textAlign='center'>
												{i + 1}
											</Td>
											<Td index={i} position={1} textAlign='center'>
												{item?.feeType}
											</Td>
											<Td index={i} position={2} textAlign='center'>
												{item?.accountName}
											</Td>
											<Td index={i} position={3} textAlign='center'>
												{item?.billNumber}
											</Td>
											<Td index={i} position={4} textAlign='center'>
												{item?.amount}
											</Td>
											<Td index={i} position={5} textAlign='center'>
												{item?.method}
											</Td>
											<Td index={i} position={5} textAlign='center'>
												{item?.feeYear?.toUpperCase()}
											</Td>
											<Td index={i} position={6} textAlign='center'>
												{moment(item.date).format('DD-MM-YYYY')}
											</Td>
										</Tr>
									))
								) : (
									<Tr>
										<p
											style={{
												textAlign: 'center',
												color: 'red',
												fontSize: '18px',
												padding: '10px',
											}}>
											No bills available. Please add some data.
										</p>
									</Tr>
								)}
							</Tbody>
						</Table>
					</div>
					<div className='p-4 border-t'></div>
				</div>
			</div>
		);
	};

	//--------view fee detail modal---------//

	// --------Profile Modal----------//
	const ProfileModal: React.FC = () => {
		const [tab, setTab] = useState('profile');
		const shoProfilewModal = () => {
			setIsModalOpen(true);
		};

		const handleProfileOk = () => {
			setIsModalOpen(false);
		};

		const handleProfileCancel = () => {
			setIsModalOpen(false);
		};

		const { data: feeData = [] } = useQuery(['EditDataSSSS', profileData?._id], getEditData(collegeId, profileData?.uid));
		const { data: attendanceData } = useQuery('getStudentAttendance1', getStudentAttendance1({ studentId: profileData?.uid, collegeId }));
		const { data: profile } = useQuery('getProfileDataTc', getProfileData({ studentId: profileData?.uid, collegeId }));
		const attendance = attendanceData?.attedenceList[0];
		const [viewModalOpen, setViewModalOpen] = useState(false);
		const [viewModalData, setViewModalData] = useState('');
		const [state, setState] = React.useState({
			filterValue: '',
			_id: profileData?._id,
			page: 0,
			date: '',
			isBill: false,
			isAction: false,
			isDues: false,
			isBilling: true,
			openBillModal: false,
			openBillModal2: false,
			openBillModal3: false,
			newData: [],
			printType: '',
		});

		return (
			<Main width='100%' title='Profile Details' height='100vh' bgColor='gray-300'>
				<div className='mt-0 px-3 overflow-auto h-[100%]  bg-gray-300'>
					<header className='flex justify-end'>
						<Button
							type='close'
							onClick={() => {
								setIsModalOpen(false);
								setProfileData({});
							}}>
							close
						</Button>
					</header>

					{profile && (
						<main className='h-[90vh]'>
							<section className=' flex h-full'>
								<article className='w-96'>
									<div className='text-left p-3 shadow-lg border-gray-500 rounded-md h-full'>
										{profile?.photoURL && <img src={profile?.photoURL} alt='Profile' className='w-100% max-h-72 rounded-2xl border-4 border-white shadow-lg mt-0 mx-auto' />}
										<h2 className='mt-4 text-2xl font-semibold'>{profile?.name?.toUpperCase()}</h2>
										<p className='text-gray-600 text-md font-semibold'>
											{profile?.batchName?.toUpperCase()}, {profile?.shortName?.toUpperCase()}
										</p>

										<div className='text-left grid grid-cols-1 gap-2'>
											{Object.keys(profile)?.map(
												(key) =>
													!mainKeyArray.includes(key) && (
														<>
															{fieldsData?.map((field: any) => {
																if (field?.keyName === key && field?.category === 'basicDetails') {
																	// const value = field.type === 'date' && profile[key] !== (undefined || '') ? moment(profile[key]).format('DD-MM-YYYY') : profile[key] || '-';
																	const value = field.type === 'date' && profile[key] ? moment(profile[key]).format('DD-MM-YYYY') : profile[key] ?? '-';

																	return (
																		<div key={field?.keyName}>
																			<strong className='w-36 inline-block text-gray-700'>{field?.title || key.replace(/([A-Z])/g, ' $1')}:</strong>
																			<span className='text-gray-900'>{value}</span>
																		</div>
																	);
																}
																return null;
															})}
														</>
													),
											)}
										</div>
									</div>
								</article>
								<section className='w-full  h-[94%]'>
									<div className='text-sm font-medium text-center bg-gray-400 h-12 rounded p-0 m-0 shadow-lg'>
										<ul className='ml-0 pl-0 flex flex-wrap text-sm font-medium text-center list-none dark:text-gray-400'>
											<li className={`me-2 min-w-36 px-1 w-auto h-12 pt-2 rounded ${tab === 'profile' && 'bg-slate-600'} `} onClick={() => setTab('profile')}>
												<p aria-current='page' className={`${tab === 'profile' ? 'text-white ' : 'text-gray-900'}` + 'inline-block font-semibold p-0  border-transparentrounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'}>
													PROFILE
												</p>
											</li>
											<li className={`me-2 min-w-36 px-1 w-auto h-12  pt-2 rounded ${tab === 'fee' && 'bg-slate-600'} `} onClick={() => setTab('fee')}>
												<p aria-current='page' className={`${tab === 'fee' ? 'text-white ' : 'text-gray-900'}` + 'inline-block font-semibold p-0  border-transparentrounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'}>
													FEES
												</p>
											</li>
											<li className={`me-2 min-w-36 px-1  w-auto h-12  pt-2 rounded ${tab === 'attendance' && 'bg-slate-600'} `} onClick={() => setTab('attendance')}>
												<p aria-current='page' className={`${tab === 'attendance' ? 'text-white ' : 'text-gray-900'}` + 'inline-block font-semibold p-0  border-transparentrounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'}>
													ATTENDANCE
												</p>
											</li>
											<li className={`me-2 min-w-36 px-1 w-auto h-12  pt-2 rounded ${tab === 'callLogs' && 'bg-slate-600'} `} onClick={() => setTab('callLogs')}>
												<p aria-current='page' className={`${tab === 'callLogs' ? 'text-white ' : 'text-gray-900'}` + 'inline-block font-semibold p-0  border-transparentrounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'}>
													CALL LOGS
												</p>
											</li>
											<li className={`me-2 min-w-36 px-1 w-auto h-12  pt-2 rounded ${tab === 'disciplinaryRecords' && 'bg-slate-600'} `} onClick={() => setTab('disciplinaryRecords')}>
												<p aria-current='page' className={`${tab === 'disciplinaryRecords' ? 'text-white ' : 'text-gray-900'}` + 'inline-block font-semibold p-0  border-transparentrounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'}>
													DISCIPLINARY RECORDS
												</p>
											</li>
										</ul>
									</div>
									{tab === 'profile' && (
										<article className='bg-gray-300 shadow-lg rounded h-full overflow-y-scroll p-4'>
											<div className='grid grid-cols-2 gap-2 mb-4'>
												{Object.keys(profile).map(
													(key) =>
														!mainKeyArray.includes(key) && (
															<>
																{fieldsData?.map((field: any) => {
																	if (field?.keyName === key && field?.category === 'contactDetails') {
																		// const value = field?.type === 'date' && profile[key] !== (undefined || '') ? moment(profile[key]).format('DD-MM-YYYY') : profile[key] || '-';
																		const value = field.type === 'date' && profile[key] ? moment(profile[key]).format('DD-MM-YYYY') : profile[key] ?? '-';

																		return (
																			<div
																				key={field?.keyName}
																				// onClick={() => {
																				// 	setEditModalOpen(true);
																				// 	setEditModalData({ key: field, value: value, profile });
																				// }}
																			>
																				<strong className='w-56 inline-block text-gray-700'>{field?.title || key.replace(/([A-Z])/g, ' $1')}:</strong>
																				<span className='text-gray-900'>{value}</span>
																			</div>
																		);
																	}
																	return null;
																})}
															</>
														),
												)}
											</div>
											<div className='grid grid-cols-2 gap-2 mb-4'>
												{Object.keys(profile).map(
													(key) =>
														!mainKeyArray.includes(key) && (
															<>
																{fieldsData?.map((field: any) => {
																	if (field.keyName === key && field.category === 'addressDetails') {
																		// const value = field.type === 'date' && profile[key] !== (undefined || '') ? moment(profile[key]).format('DD-MM-YYYY') : profile[key] || '-';
																		const value = field.type === 'date' && profile[key] ? moment(profile[key]).format('DD-MM-YYYY') : profile[key] ?? '-';

																		return (
																			<div
																				key={field?.keyName}
																				// onClick={() => {
																				// 	setEditModalOpen(true);
																				// 	setEditModalData({ key: field, value: value, profile });
																				// }}
																			>
																				<strong className='w-56 overflow-clip inline-block text-gray-700'>{field?.title || key.replace(/([A-Z])/g, ' $1')}:</strong>
																				<span className='text-gray-900'>{value}</span>
																			</div>
																		);
																	}
																	return null;
																})}
															</>
														),
												)}
											</div>
											<div className='grid grid-cols-2 gap-2 mb-4'>
												{Object.keys(profile).map(
													(key) =>
														!mainKeyArray.includes(key) && (
															<>
																{fieldsData?.map((field: any) => {
																	if (field.keyName === key && field.category !== 'addressDetails' && field?.category !== 'basicDetails' && field?.category !== 'contactDetails') {
																		// const value = field?.type === 'date' && profile[key] !== (undefined || '') ? moment(profile[key]).format('DD-MM-YYYY') : profile[key] || '-';
																		const value = field.type === 'date' && profile[key] ? moment(profile[key]).format('DD-MM-YYYY') : profile[key] ?? '-';

																		return (
																			<div
																				key={field.keyName}
																				// onClick={() => {
																				// 	setEditModalOpen(true);
																				// 	setEditModalData({ key: field, value: value, profile });
																				// }}
																			>
																				<strong className='w-56 inline-block text-gray-700'>{field?.title || key.replace(/([A-Z])/g, ' $1')}:</strong>
																				<span className='text-gray-900'>{value}</span>
																			</div>
																		);
																	}
																	return null;
																})}
															</>
														),
												)}
											</div>
										</article>
									)}
									{tab === 'fee' && (
										<article className='bg-gray-300 shadow-lg rounded h-full overflow-y-scroll'>
											<div className='p-3'>
												<Table width='100%' innerWidth={['5%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '30%']}>
													<Thead>
														<Tr>
															<Th position={0}>Sl.No.</Th>
															<Th position={1}>Governed By</Th>
															<Th position={2}>Title</Th>
															<Th position={3}>Sub Title</Th>
															<Th position={4}>Rt No</Th>
															<Th position={5}>Amount</Th>
															<Th position={6}>Method</Th>
															<Th position={7}>Year</Th>
															<Th position={8}>Date</Th>
															<Th position={9}>Action</Th>
														</Tr>
													</Thead>
													<Tbody height='auto'>
														{feeData?.collections && feeData?.collections?.length > 0 ? (
															feeData?.collections?.map((item: any, i: any) => (
																<Tr>
																	<Td index={i} position={0} textAlign='center'>
																		{i + 1}
																	</Td>
																	<Td index={i} position={1} textAlign='center'>
																		{item.feeType}
																	</Td>
																	<Td index={i} position={2} textAlign='center'>
																		{item.title}
																	</Td>
																	<Td index={i} position={3} textAlign='center'>
																		{item.subTitle}
																	</Td>
																	<Td index={i} position={4} textAlign='center'>
																		{item.billNumber}
																	</Td>
																	<Td index={i} position={5} textAlign='center'>
																		{item.amount}
																	</Td>
																	<Td index={i} position={6} textAlign='center'>
																		{item.method}
																	</Td>
																	<Td index={i} position={7} textAlign='center'>
																		{item.year}
																	</Td>
																	<Td index={i} position={8} textAlign='center'>
																		{item.date}
																	</Td>
																	<Td index={i} position={9}>
																		<div
																			style={{
																				display: 'flex ',
																				justifyContent: 'space-evenly',
																				alignItems: 'center',
																			}}>
																			<Button
																				type='fetch'
																				onClick={() =>
																					setState({
																						...state,
																						newData: {
																							...item,
																							name: feeData?.name,
																							batchName: feeData?.batchName,
																							longName: feeData?.longName,
																							admisionNo: feeData?.admisionNo,
																						},
																						openBillModal2: true,
																						printType: 'cprint',
																					})
																				}>
																				C Print
																			</Button>
																			<Button
																				type='fetch'
																				onClick={() =>
																					setState({
																						...state,
																						newData: {
																							...item,
																							collectedData: item?.innerElements?.map((x: any) => ({
																								...x,
																								title: x?.accountName,
																								year: x?.feeYear?.toUpperCase(),
																							})),
																							name: feeData?.name,
																							batchName: feeData?.batchName,
																							longName: feeData?.longName,
																							admisionNo: feeData?.admisionNo,
																						},
																						openBillModal2: true,
																						printType: 'print',
																					})
																				}>
																				Print
																			</Button>
																			<Button
																				type='submit'
																				onClick={() => {
																					setViewModalOpen(true);
																					setViewModalData(item);
																				}}>
																				View{' '}
																			</Button>
																		</div>
																	</Td>
																</Tr>
															))
														) : (
															<Tr>
																<p
																	style={{
																		textAlign: 'center',
																		color: 'red',
																		fontSize: '18px',
																		padding: '10px',
																	}}>
																	No bills available. Please add some data.
																</p>
															</Tr>
														)}
													</Tbody>
												</Table>
											</div>
										</article>
									)}
									{tab === 'attendance' && (
										<article className='bg-gray-300 shadow-lg rounded h-full overflow-y-scroll'>
											<div>
												{attendanceData?.attedenceList?.length > 0 ? (
													<div className='p-4 h-full overflow-x-scroll bg-gray-300 shadow-lg rounded '>
														<h2 className='text-2xl font-semibold text-center mb-4'>Attendance Overview</h2>

														{/* Overall Attendance */}
														<div className='grid grid-cols-3 gap-4 text-center mb-6 rounded  p-4'>
															<div className='bg-gray-100 p-4 rounded-lg shadow-md'>
																<h3 className='text-xl font-semibold'>Total Days</h3>
																<p className='text-3xl font-bold'>{attendance?.totalDays}</p>
															</div>
															<div className='bg-gray-100 p-4 rounded-lg shadow-md'>
																<h3 className='text-xl font-semibold'>Present Days</h3>
																<p className='text-3xl font-bold'>{attendance?.totalAttendance}</p>
															</div>
															<div className='bg-gray-100 p-4 rounded-lg shadow-md'>
																<h3 className='text-xl font-semibold'>Attendance %</h3>
																<p className='text-3xl font-bold'>{attendance?.percentage}%</p>
															</div>
														</div>

														{/* Monthly Attendance */}
														<h3 className='text-xl font-semibold mb-2 text-center'>Semester & Month-wise Attendance</h3>
														{attendanceData?.attedenceList
															?.sort((a: any, b: any) => b.semester - a.semester)
															?.map((attendance: any, index: any) => (
																<>
																	<h5 className='bg-gray-800 text-white py-1 px-2 rounded'>Semester: {parseInt(attendance?.semester) + 1}</h5>
																	{attendance?.semesterWiseList?.map((monthData: any, index: any) => (
																		<div key={index} className='mb-6  p-4 rounded shadow-lg bg-gray-200'>
																			<div className='flex justify-between items-center mb-2'>
																				<h4 className='text-lg font-semibold'>{monthData?.month}</h4>
																			</div>
																			<div className='mb-2'>
																				<Progress percent={parseFloat(monthData?.percentage)} />
																			</div>
																			<div className='grid grid-cols-3 gap-4 text-center'>
																				<div className='bg-gray-50 p-4 rounded-lg shadow-md'>
																					<h5 className='text-sm font-medium'>Total Days</h5>
																					<p className='text-lg'>{monthData?.totalDays}</p>
																				</div>
																				<div className='bg-gray-50 p-4 rounded-lg shadow-md'>
																					<h5 className='text-sm font-medium'>Present Days</h5>
																					<p className='text-lg'>{monthData?.totalAttendance}</p>
																				</div>
																				<div className='bg-gray-50 p-4 rounded-lg shadow-md'>
																					<h5 className='text-sm font-medium'>Attendance %</h5>
																					<p className='text-lg'>{monthData?.percentage}%</p>
																				</div>
																			</div>

																			{/* Subject-wise Attendance */}
																			<h4 className='mt-4 text-base font-semibold'>Subject-wise Attendance</h4>
																			<div className='grid grid-cols-3 gap-4 mt-2'>
																				{monthData?.subjectWiseList?.map((subject: any, subIndex: any) => (
																					<div key={subIndex} className='bg-gray-100 p-3 rounded-lg shadow-sm flex flex-col justify-between'>
																						<p className='font-medium'>{subject?.subjectName}</p>
																						<div>
																							<Progress percent={parseFloat(subject?.percentage)} size='small' />
																						</div>
																					</div>
																				))}
																			</div>
																		</div>
																	))}
																</>
															))}
													</div>
												) : (
													<div className='h-full flex justify-center pt-24'>
														<p className='text-2xl text-red-950'>no records found</p>
													</div>
												)}
											</div>
										</article>
									)}
									{tab === 'callLogs' && (
										<article className='bg-gray-300 shadow-lg rounded h-full overflow-y-scroll'>
											<div className='h-full flex justify-center pt-24'>
												<p className='text-2xl text-red-950'>no records found</p>
											</div>
										</article>
									)}
									{tab === 'disciplinaryRecords' && (
										<article className='bg-gray-300 shadow-lg rounded h-full overflow-y-scroll'>
											<div className='h-full flex justify-center pt-24'>
												<p className='text-2xl text-red-950'>no records found</p>
											</div>
										</article>
									)}
								</section>
							</section>
						</main>
					)}

					{/* <EditModal /> */}
				</div>
				{state.openBillModal2 && (
					<BillNumberModal2
						data={state?.newData}
						date={state?.date}
						setStateData={setState}
						stateData={state}
						onClose={() => {
							setState({ ...state, openBillModal2: false, newData: [] });
						}}
					/>
				)}
				<ViewModal
					isOpen={viewModalOpen}
					onClose={() => {
						setViewModalOpen(false);
						setViewModalData('');
					}}
					title='View Fee Details'
					data={viewModalData}>
					<p>View Details.</p>
				</ViewModal>
			</Main>
		);
	};
	// --------Profile Modal End----------//

	return (
		<>
			<Main title={requests ? 'Requests for Transfer Certificate' : 'Approved Transfer Certificate'} width='100%' height='100vh'>
				<Toaster />
				<div className='flex w-full h-full'>
					<div className='w-full h-full overflow-x-auto'>
						<Body>
							{iSearch && (
								<div className='flex justify-center items-center bg-[#5c655c] rounded'>
									<Input width='600px' fieldName='Advanced Search' type='text' setState={setAdvancedSearch} state={advancedSearch}></Input>
									<Button type='search' onClick={() => setRefresh(!refresh)}>
										Search
									</Button>
									{advancedSearch && (
										<Button type='refresh' onClick={handleReset}>
											Reset
										</Button>
									)}
								</div>
							)}
							<div ref={ref} className='printable overflow-auto h-full w-full'>
								{printable && (
									<div className='flex flex-row items-center justify-center p-4 border-b-2'>
										<div className='flex items-center'>
											<img className='w-16 h-16 mr-4 print:w-16 print:h-16' src={selectedCollege?.logo} alt='College Logo' />
											<div>
												<h1 className='text-xl font-bold h-4'>{selectedCollege?.collegeShortName}</h1>
												<p className='text-md font-semibold h-1'>{selectedCollege?.accreditedGrade}</p>
												<p className='text-md font-semibold h-1'>{selectedCollege?.address}</p>
											</div>
										</div>
									</div>
								)}
								{requests ? (
									<div>
										<Table width={printable ? '100%' : tableWidth}>
											<Thead>
												<Tr>
													{columnsRequests.map((field: any) => (
														<Th width={field.width} key={field.keyName} onClick={() => requestSort(field.keyName)} icon={getIcon(field.keyName)}>
															{field.title}
														</Th>
													))}
												</Tr>
											</Thead>
											<Tbody height='full'>
												{requestsData?.list?.map((item: any, index: any) => (
													<Tr
														key={index}
														onClick={() => {
															// if (!Edit.isEdit) {
															setIsModalOpen(true);
															setProfileData(item);
															console.log(item);

															// }
														}}>
														{columnsRequests.map((field: any, i: any) => {
															const keyName = field.keyName;
															const value = item[keyName];
															if (keyName === 'slNo') {
																return (
																	<Td index={index} width={field.width} key={`${index}-${i}-${keyName}`}>
																		{index + 1}
																	</Td>
																);
															}
															if (keyName === 'action') {
																return (
																	<Td index={index} width={field.width} key={`${index}-${i}-${keyName}`}>
																		<div className='flex gap-1'>
																			{
																				<button
																					className='bg-green-500 hover:bg-green-600 text-white font-semibold py-1 px-2 rounded text-xs transition-colors duration-200 ease-in-out'
																					onClick={(e) => {
																						aproveTcCc(item);
																						e.stopPropagation();
																					}}>
																					Approve
																				</button>
																			}
																			{
																				<>
																					<button
																						className='bg-blue-500 hover:bg-blue-600 text-white font-semibold py-1 px-2 rounded text-xs transition-colors duration-200 ease-in-out'
																						onClick={async (e) => {
																							setEdit({ ...Edit, value: item, isEdit: true });
																							e.stopPropagation();
																						}}>
																						Edit
																					</button>
																					<button
																						className='bg-red-500 hover:bg-red-600 text-white font-semibold py-1 px-2 rounded text-xs ml-1 transition-colors duration-200 ease-in-out'
																						onClick={async (e) => {
																							deleteTC(item);
																							e.stopPropagation();
																						}}>
																						Delete
																					</button>
																				</>
																			}
																		</div>
																	</Td>
																);
															}
															if (field.type === 'date') {
																const formattedDate = value && moment(value).isValid() ? moment(value).format('DD-MM-YYYY') : '-';
																return (
																	<Td index={index} width={field.width} key={`${index}-${i}-${keyName}`}>
																		{formattedDate}
																	</Td>
																);
															}
															return (
																<Td index={index} width={field.width} key={`${index}-${i}-${keyName}`}>
																	{typeof value !== 'object' && value !== undefined ? value : '-'}
																</Td>
															);
														})}
													</Tr>
												))}
											</Tbody>
										</Table>
									</div>
								) : tcLoading ? (
									<div className='flex justify-center items-center h-full w-full'>
										<PulseLoader />
									</div>
								) : !tcLoading && tableData?.length > 0 ? (
									<div>
										<Table width={printable ? '100%' : tableWidth}>
											<Thead>
												<Tr>
													{columns.map((field: any) => (
														<Th width={field?.width} key={field?.keyName} onClick={() => requestSort(field?.keyName)} icon={getIcon(field?.keyName)}>
															{field?.title}
														</Th>
													))}
												</Tr>
											</Thead>
											<Tbody height='full'>
												{tableData?.map((item: any, index: any) => (
													<Tr
														key={index}
														onClick={() => {
															setIsModalOpen(true);
															setProfileData(item);
														}}>
														{columns?.map((field: any, i: any) => {
															const keyName = field?.keyName;
															const value = item[keyName];
															if (keyName === 'slNo') {
																return (
																	<Td index={index} width={field?.width} key={`${index}-${i}-${keyName}`}>
																		{index + 1}
																	</Td>
																);
															}
															if (keyName === 'action') {
																return (
																	<Td index={index} width={field?.width} key={`${index}-${i}-${keyName}`}>
																		<div className='flex gap-1'>
																			{loading ? (
																				<span>Loading...</span>
																			) : (
																				<>
																					{/* <button
																						className='bg-blue-500 hover:bg-blue-600 text-white font-semibold py-1 px-2 rounded text-xs transition-colors duration-200 ease-in-out'
																						onClick={(e: any) => {
																							onTcCc(item);
																							e.stopPropagation();
																						}}>
																						{' '}
																						{'Download'}
																					</button>
																					<button
																						className='bg-blue-500 hover:bg-blue-600 text-white font-semibold py-1 px-2 rounded text-xs transition-colors duration-200 ease-in-out'
																						onClick={(e: any) => {
																							handleView(item);
																							setProfileData(item);
																							e.stopPropagation();
																						}}>
																						{' '}
																						{'View'}
																					</button> */}
																					<Button
																						type='fetch'
																						onClick={(e: any) => {
																							handleTcView(item);
																							setProfileData(item);
																							e.stopPropagation();
																						}}>
																						Download
																					</Button>
																					<Button
																						type='download'
																						onClick={async (e) => {
																							setEditTc({ ...editTc, value: item, isEditTc: true });
																							e.stopPropagation();
																						}}>
																						Edit
																					</Button>
																				</>
																			)}
																		</div>
																	</Td>
																);
															}
															if (field.type === 'date') {
																const formattedDate = value && moment(value).isValid() ? moment(value).format('DD-MM-YYYY') : '-';
																return (
																	<Td index={index} width={field?.width} key={`${index}-${i}-${keyName}`}>
																		{formattedDate}
																	</Td>
																);
															}
															return (
																<Td index={index} width={field?.width} key={`${index}-${i}-${keyName}`}>
																	{typeof value !== 'object' && value !== undefined ? value : '-'}
																</Td>
															);
														})}
													</Tr>
												))}
											</Tbody>
										</Table>
									</div>
								) : (
									<div className='flex justify-center items-center mt-20 pt-20'>
										{' '}
										<img src='https://firebasestorage.googleapis.com/v0/b/necttos-production.appspot.com/o/nodatafound.png?alt=media&token=75763b23-2b98-4570-b308-963bc28c070b' alt='' />
									</div>
								)}
								{/*  */}
							</div>
						</Body>
					</div>
					<div className='w-96 pe-1 h-full bg-[#5c655c] pl-2 rounded-lg overflow-y-auto'>
						<header className='flex flex-col'>
							<div className='flex justify-between pt-2'>
								<Button type='print' tableRef={ref.current}>
									Print
								</Button>
								<Button type='excel' tableRef={ref.current}>
									Excel
								</Button>
								<Button type={printable ? 'search' : 'update'} onClick={() => setPritable(!printable)}>
									{printable ? 'Hide' : 'Show'}
								</Button>
								<Button type='close' onClick={onClose}>
									Close
								</Button>
							</div>
							<div className='flex justify-between w-full pe-2'>
								{/* {!requests && ( */}
								<div className='flex gap-2 mt-3 justify-between ml-2'>
									<div className=' mt-[2px]'>
										<input type='checkbox' className='appearance-none w-7 h-7 border border-gray-300 rounded-full focus:outline-none focus:ring-0 checked:bg-emerald-900 checked:border-emerald-900 relative cursor-pointer' onChange={() => setISearch(!iSearch)} checked={iSearch} />
										<style>{`
								input[type='checkbox']:checked {
									background-color: #065f46; /* Custom emerald-900 */
									border-color: #065f46;
								}
							`}</style>
									</div>
									<p className=' text-white rounded mb-3 px-2'>Advanced Search</p>
								</div>
								{/* )} */}
								<div className='mr-[-7px] mt-2'>
									<Button type='refresh' onClick={handleReset}>
										Reset
									</Button>
								</div>
							</div>
							<div className='flex justify-between w-full my-2'>
								<Button type='edit' width='1/2' onClick={() => setInstTc(!instTc)}>
									Old TC Form
								</Button>
								<Button type='submit' onClick={() => setapply(!apply)}>
									New TC Form
								</Button>
							</div>
							<div className='flex justify-between w-full pe-2'>
								{/* {!requests && ( */}
								<div className='flex gap-2 mt-3 justify-between ml-2'>
									<div className=' mt-[2px]'>
										<input type='checkbox' className='appearance-none w-7 h-7 border border-gray-300 rounded-full focus:outline-none focus:ring-0 checked:bg-emerald-900 checked:border-emerald-900 relative cursor-pointer' onChange={() => setRequests(!requests)} checked={!requests} />
										<style>{`
								input[type='checkbox']:checked {
									background-color: #065f46; /* Custom emerald-900 */
									border-color: #065f46;
								}
							`}</style>
									</div>
									<p className=' text-white rounded mb-3 px-2'> TC List</p>
								</div>
								{/* )} */}
								<div className='flex gap-2 mt-3 justify-between ml-2'>
									<div className=' mt-[2px]'>
										<input type='checkbox' className='appearance-none w-7 h-7 border border-gray-300 rounded-full focus:outline-none focus:ring-0 checked:bg-emerald-900 checked:border-emerald-900 relative cursor-pointer' onChange={() => setRequests(!requests)} checked={requests} />
										<style>{`
								input[type='checkbox']:checked {
									background-color: #065f46; /* Custom emerald-900 */
									border-color: #065f46;
								}
							`}</style>
									</div>
									<p className=' text-white rounded mb-3 px-2'>Requests </p>
								</div>
							</div>
							<div>
								<Button type={!requests ? 'fetch' : 'save'} width='96%' onClick={() => setRequests(!requests)}>
									{!requests ? 'Switch to TC Requests' : 'Switch to Approved TC'}
								</Button>
							</div>
							
							{!requests && (
								<>
									<div className='flex justify-between w-full pe-2'>
										<div className='w-56'>
											<Input fieldName='Search' state={searchQuery} setState={setSearchQuery} onChange={(event) => setSearchQuery(event.value)} type='text' width='100%' />
										</div>

										<button
											type='button'
											onClick={(e: any) => {
												handleTcView(tableData?.[0]);
												setProfileData(tableData?.[0]);
												e.stopPropagation();
											}}
											disabled={tcLoading}
											className='flex items-center h-12 mt-[10px] bg-gray-300 border border-gray-400 hover:bg-gray-400 hover:border-gray-900 text-gray-800 font-semibold py-2 px-3 ml-1 rounded-lg shadow-lg shadow-gray-900 hover:shadow-3xl transition duration-300 ease-in-out transform hover:scale-105 disabled:bg-gray-100'>
											<FaCog className=' text-gray-700' size={20} />
										</button>
									</div>

									<div className='flex justify-between w-full pe-2'></div>
									<div className='flex justify-between w-full pe-2'>
										<div className='w-56'>
											<Input fieldName='Govern By' state={filterKey} setState={setFilterKey} returnKey='' options={['ALL', 'Aided', 'Self']} type='drop' width='100%' />
										</div>
										<button type='button' onClick={() => setSettingsModal((prv) => ({ ...prv, openModal: true }))} className='flex items-center h-12 mt-[10px] bg-green-900 border border-green-800 hover:bg-green-700 hover:border-gray-700 text-gray-800 font-semibold py-2 px-3 ml-1 rounded-lg shadow-lg shadow-gray-900 hover:shadow-3xl transition duration-300 ease-in-out transform hover:scale-105'>
											<FaCog className=' text-gray-100' size={20} />
										</button>
									</div>
									<div className='flex flex-col w-72 gap-y-1'>
										<Input fieldName='Filter by Batch' state={selectedBatch} setState={setSelectedBatch} returnKey='batch' options={batches} type='drop' width='100%' />
										<div className='z-100'>
											<Input fieldName='Filter by Course' typable state={selectedClass} setState={setSelectClass} distinctKey='classes' didntShowKey optionDisplay='className' optionKey='_id' options={classData} type='drop' width='100%' />
										</div>{' '}
									</div>
								</>
							)}
							{!requests && (
								<div className='w-[100%] flex flex-col gap-y-2 pe-1'>
									<div className='bg-[#4d5358] p-2 rounded-md accordion w-[100%] cursor-pointer '>
										<p className='text-white text-center font-bold cursor-pointer' onClick={() => toggleAccordion('admissionDate')}>
											Filter by Admission Date
										</p>
										{openAccordion === 'admissionDate' && (
											<div className={`overflow-hidden transition-[max-height] duration-300 ease-in-out ${openAccordion === 'admissionDate' ? 'max-h-96' : 'max-h-0'}`}>
												<Input fieldName='Start Date *' returnKey='startDate' state={addNew} setState={setAddNew} type='date' width='97%' />
												<Input fieldName='End Date *' returnKey='endingDate' state={addNew} setState={setAddNew} type='date' width='97%' />
											</div>
										)}
									</div>
									<div className='bg-[#40515f] p-2 rounded-md accordion w-[100%] cursor-pointer'>
										<p className='text-white text-center font-bold cursor-pointer' onClick={() => toggleAccordion('admissionNo')}>
											Filter by Admission No
										</p>
										{openAccordion === 'admissionNo' && (
											<div className={`overflow-hidden transition-[max-height] duration-300 ease-in-out ${openAccordion === 'admissionNo' ? 'max-h-96' : 'max-h-0'}`}>
												<Input fieldName='Start No *' returnKey='startAdmNo' state={admn} setState={setAdmn} type='number' width='97%' />
												<Input fieldName='End No *' returnKey='endAdmNo' state={admn} setState={setAdmn} type='number' width='97%' />
												{missingAdmNo.length > 0 && admn?.startAdmNo && admn?.endAdmNo && (
													<button className='border-0 rounded-lg text-center bg-[#1b3143] text-red-600 bg-none w-full shadow text-xs italic' onClick={() => setViewMissing(true)}>
														Missing{' '}
														{missingAdmNo.slice(0, 2).map((x: any) => (
															<>{x},</>
														))}{' '}
														{missingAdmNo.length > 2 && '.. view more ...'}
													</button>
												)}
											</div>
										)}
									</div>
									<div className='bg-[#4d5358] p-2 rounded-md accordion w-[100%] cursor-pointer'>
										<p className='  text-white text-center font-bold cursor-pointer hover:scale-1.5 rounded' onClick={() => toggleAccordion('tcDate')}>
											Filter by TC Date
										</p>
										{openAccordion === 'tcDate' && (
											<div className={`overflow-hidden transition-[max-height] duration-300  ease-in-out ${openAccordion === 'tcDate' ? 'max-h-96' : 'max-h-0'}`}>
												<Input fieldName='Start Date *' returnKey='tcStartDate' state={tcDate} setState={setTcDate} type='date' width='97%' />
												<Input fieldName='End Date *' returnKey='tcEndDate' state={tcDate} setState={setTcDate} type='date' width='97%' />
											</div>
										)}
									</div>
									<div className='bg-[#40515f] p-2 rounded-md accordion w-[100%] cursor-pointer'>
										<p className='text-white text-center font-bold cursor-pointer' onClick={() => toggleAccordion('tcNo')}>
											Filter by TC No
										</p>
										{openAccordion === 'tcNo' && (
											<div className={`overflow-hidden transition-[max-height] duration-300 ease-in-out ${openAccordion === 'tcNo' ? 'max-h-96' : 'max-h-0'}`}>
												<Input fieldName='Start Tc No *' returnKey='startTcNo' state={tcNo} setState={setTcNo} type='number' width='97%' />
												<Input fieldName='End Tc No *' returnKey='endTcNo' state={tcNo} setState={setTcNo} type='number' width='97%' />
												{missingTcNo.length > 0 && (
													<button className='border-0 rounded-lg text-center bg-[#1b3143] text-red-600 bg-none w-full shadow text-xs italic' onClick={() => setViewMissingTc(true)}>
														Missing{' '}
														{missingTcNo.slice(0, 2).map((x: any) => (
															<>{x},</>
														))}{' '}
														{missingTcNo.length > 2 && '.. view more ...'}
													</button>
												)}
											</div>
										)}
									</div>
								</div>
							)}
						</header>
					</div>
				</div>
				{viewMissing && (
					<>
						<Main zIndex='100' height='80%' width='50%'>
							<div className='flex justify-end'>
								<Button type='close' onClick={() => setViewMissing(false)}>
									Close
								</Button>
							</div>
							<Body>
								<h5 className='text-center mb-4'>
									Missing Admission Number between {admn.startAdmNo} & {admn.endAdmNo}
								</h5>
								<div className='flex flex-wrap w-full text-dark font-semibold gap-2 text-md justify-center items-center'>
									{missingAdmNo.map((item: any) => (
										<li className='list-none p-2'>{item}</li>
									))}
								</div>
							</Body>
						</Main>
					</>
				)}
				{viewMissingTc && (
					<>
						<Main zIndex='100' height='80%' width='50%'>
							<div className='flex justify-end'>
								<Button type='close' onClick={() => setViewMissingTc(false)}>
									Close
								</Button>
							</div>
							<Body>
								<h5 className='text-center mb-4'>
									Missing TC Number between {tcNo.startTcNo} & {tcNo.endTcNo}
								</h5>
								<div className='flex flex-wrap w-full text-dark font-semibold gap-2 text-md justify-center items-center'>
									{missingTcNo.map((item: any) => (
										<li className='list-none p-2'>{item}</li>
									))}
								</div>
							</Body>
						</Main>
					</>
				)}
				{isModalOpen && <ProfileModal />}
			</Main>
			{settingsModal?.openModal && (
				<Main height='40%' width='40%' bgColor='white'>
					<Header>
						<div></div>
						<Button type='close' onClick={() => setSettingsModal((prv) => ({ ...prv, openModal: false }))}>
							close
						</Button>
						 ̰
					</Header>
					<Body>
						<div className='p-4 w-full'>
							<h6>Aided</h6>
							{sequenceData?.lastTcNumber && <Input width='100%' type='text' fieldName={`Last Tc Number: ${sequenceData?.lastTcNumber ?? ''}`} returnKey='newTcNo' state={settingsModal} setState={setSettingsModal}></Input>}
							{sequenceData?.lastTcBatchNumber && <Input width='100%' type='text' fieldName={`Last TC Batch Number: ${sequenceData?.lastTcBatchNumber ?? ''}`} returnKey='newBatchNo' state={settingsModal} setState={setSettingsModal}></Input>}
							{sequenceData?.lastSelfTcNumber && <h6 className='mt-4'>Self</h6>}
							{sequenceData?.lastSelfTcNumber && <Input width='100%' type='text' fieldName={`Last Self Tc Number: ${sequenceData?.lastSelfTcNumber ?? ''}`} returnKey='newSelfTcNo' state={settingsModal} setState={setSettingsModal}></Input>}
							{sequenceData?.lastSelfTcBatchNumber && <Input width='100%' type='text' fieldName={`Last Self TC Batch Number: ${sequenceData?.lastSelfTcBatchNumber ?? ''}`} returnKey='newSelfBatchNo' state={settingsModal} setState={setSettingsModal}></Input>}
							<div className='flex justify-center'>
								<Button width='200px' type='submit' onClick={handleSequenceUpdate}>
									Submit
								</Button>
							</div>
						</div>
					</Body>
				</Main>
			)}

			{instTc ? <InstantTc setLoading={setLoading} refetch={refetchReq} onClose={() => setInstTc(false)} /> : null}
			{Edit?.isEdit ? <EditTc setLoading={setLoading} refetch={refetchReq} onClose={() => setEdit({ ...Edit, value: [], isEdit: false })} data={Edit.value} /> : null}
			{editTc?.isEditTc ? <EditTcFinal setLoading={setLoading} refetch={refetch} onClose={() => setEditTc({ ...editTc, value: [], isEditTc: false })} data={editTc.value} /> : null}
			{apply ? <ApplyTc setLoading={setLoading} refetch={refetchReq} onClose={() => setapply(false)} /> : null}
			{tcView2 && <TCView2 tcView={tcView2} setTcView={setTcView2} userId={profileData?._id} />}
		</>
	);
}

export default NewTransferCertificate;
