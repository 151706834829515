import { BrowserRouter } from 'react-router-dom';
import FullScreenContextProvider from './context/FullScreenContext';
import FirebaseContextProvider from './context/FirebaseContext';
import Routes from './Routes';
import { QueryClient, QueryClientProvider } from 'react-query';
import React, { useState, useEffect } from 'react';
import NetworkSpeedModal from './NecttosComp/network';

function NectosDash() {
	const queryClient = new QueryClient();

	const [downloadSpeed, setDownloadSpeed] = useState(null);
	const [networkProvider, setNetworkProvider] = useState('Unknown');
	const [signalStrength, setSignalStrength] = useState('Unknown');
	const [modalOpen, setModalOpen] = useState(false);
	const [isOffline, setIsOffline] = useState(false);
	const [cpuCores, setCpuCores] = useState(null);

	const LOW_SPEED_THRESHOLD = 2;

	const estimateCpuStats = () => {
		setCpuCores(navigator.hardwareConcurrency || 'Unknown');
	};

	const updateNetworkDetails = () => {
		const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;

		if (connection) {
			setDownloadSpeed(connection.downlink);
			setNetworkProvider(connection.effectiveType || 'Unknown');
			setSignalStrength(() => {
				switch (connection.effectiveType) {
					case '4g':
						return 'Strong';
					case '3g':
						return 'Moderate';
					case '2g':
						return 'Weak';
					default:
						return 'Unknown';
				}
			});
		}
	};

	const checkOnlineStatus = () => {
		setIsOffline(!navigator.onLine);
		if (!navigator.onLine) {
			// setModalOpen(true);
		}
	};

	useEffect(() => {
		updateNetworkDetails();
		estimateCpuStats();
		checkOnlineStatus();

		const interval = setInterval(() => {
			updateNetworkDetails();
			checkOnlineStatus();
		}, 5000);

		window.addEventListener('online', checkOnlineStatus);
		window.addEventListener('offline', checkOnlineStatus);

		return () => {
			clearInterval(interval);
			window.removeEventListener('online', checkOnlineStatus);
			window.removeEventListener('offline', checkOnlineStatus);
		};
	}, []);

	useEffect(() => {
		if (downloadSpeed !== null && (downloadSpeed < LOW_SPEED_THRESHOLD || isOffline)) {
			// setModalOpen(true);
		}
	}, [downloadSpeed, isOffline]);

	const downloadSpeedClass = downloadSpeed < 2 ? 'bg-red-500' : downloadSpeed < 5 ? 'bg-yellow-500' : 'bg-green-500';
	const signalStrengthClass = signalStrength === 'Strong' ? 'bg-green-500' : signalStrength === 'Moderate' ? 'bg-yellow-500' : 'bg-red-500';

	const handleOpenModal = () => setModalOpen(true);
	const handleCloseModal = () => setModalOpen(false);

	return (
		<QueryClientProvider client={queryClient}>
			<FirebaseContextProvider>
				<FullScreenContextProvider>
					<BrowserRouter>
						<Routes />
						<div onClick={handleOpenModal} className={`absolute hover:bg-indigo-700 z-100 focus:outline-none cursor-pointer transition-transform transform hover:scale-110 bottom-2 right-2 flex items-center justify-center w-24 h-12 rounded-full text-sm font-bold ${downloadSpeedClass} shadow-md`} title={`Download Speed: ${downloadSpeed || '--'} Mbps`}>
							{downloadSpeed + ' Mbps' || '--'}
						</div>
						<NetworkSpeedModal isOpen={modalOpen} downloadSpeed={downloadSpeed} networkProvider={networkProvider} signalStrengthClass={signalStrengthClass} signalStrength={signalStrength} isOffline={isOffline} closeModal={handleCloseModal} cpuCores={cpuCores} />
					</BrowserRouter>
				</FullScreenContextProvider>
			</FirebaseContextProvider>
		</QueryClientProvider>
	);
}

export default NectosDash;
