import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const PieChartComponent = ({ data }) => {
	const totalValue = data.reduce((acc, curr) => acc + curr.value, 0);

	return (
		<ResponsiveContainer width='100%' height='100%'>
			<div style={{ width: '100%', height: '400px' }}>
				<ResponsiveContainer>
					<PieChart>
						<Pie data={data} dataKey='value' nameKey='name' innerRadius='0%' outerRadius='90%' startAngle={0} endAngle={360} paddingAngle={1} animationDuration={3000} animationEasing='ease-out'>
							{data.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={entry.color} />
							))}
						</Pie>
						<Tooltip
							content={({ payload }) => {
								if (payload && payload.length) {
									const { name, value } = payload[0].payload;
									const percentage = ((value / totalValue) * 100).toFixed(1);
									return (
										<div style={{ padding: '10px', backgroundColor: 'white' }}>
											<strong>{name}</strong>: {percentage}% ({value})
										</div>
									);
								}
								return null;
							}}
						/>
						<Legend verticalAlign='top' align='center' iconType='circle' />
					</PieChart>
				</ResponsiveContainer>
			</div>
		</ResponsiveContainer>
	);
};

export default PieChartComponent;
