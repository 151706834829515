import React, { useContext, useEffect, useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { fetchStatisticalDetails, getFilterAdministrations, getSettings, loadGraphicsStageOne, retrieveStatistics, retrieveVerificationInfo } from '../../NecttosComp/APICaller/ApiServices';
import { Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { mediumBackgroundColors, darkBackgroundColors, lightBackgroundColors } from '../../utils';
import moment from 'moment';
import CustomKey from '../../components/Office/CustomKey';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import PieChartComponent from '../../NecttosComp/Charts/StudentsCount';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import { getCollegeDetailsee } from '../Admission/RemoveAction';
import { useProfileData } from '../../NecttosComp/APICaller/queryHooks';

export const Statistics = ({ onClose }) => {
	const { collegeId, user } = useContext(FirebaseContext);
	const ref = useRef(null);

	const [principal, setPrincipal] = useState(true);

	useEffect(() => {
		if (['NT-DF0FADB98B53-47D4-8D56-313B7D1FD008', 'NT-1D0620108120-47FA-9854-D77E00176845', 'NSF-F192725B994B-4761-9E3A-BCBAFF576695'].includes(user.uid)) {
			setPrincipal(false);
		} else {
			setPrincipal(true);
		}
	}, [user.uid]);

	// super State
	const [baseSettings, setBaseSettings] = useState(() => {
		const saved = localStorage.getItem('baseSettingsOfStatistics');
		const initialValue = saved
			? JSON.parse(saved)
			: {
					type: 'Active Status',
					status: 'Current Active',
			  };
		return initialValue;
	});

	const [projection, setProjection] = useState(() => {
		let saved = localStorage.getItem('projectionData');
		if (!Array.isArray(saved)) {
			saved = JSON.stringify(['name']);
		}
		const initialValue = saved ? JSON.parse(saved) : ['name'];
		return initialValue;
	});

	const [dropsFilter, setDropsFilter] = useState(() => {
		let saved = localStorage.getItem('dropData');
		if (!Array.isArray(saved)) {
			saved = JSON.stringify([]);
		}
		const initialValue = saved ? JSON.parse(saved) : [];
		return initialValue;
	});

	const [filter, setFilter] = useState(() => {
		let saved = localStorage.getItem('filterData');
		if (!Array.isArray(saved)) {
			saved = JSON.stringify({});
		}
		const initialValue = saved ? JSON.parse(saved) : {};
		return initialValue;
	});

	// Save Local Host

	useEffect(() => {
		localStorage.setItem('baseSettingsOfStatistics', JSON.stringify(baseSettings));
	}, [baseSettings]);

	useEffect(() => {
		localStorage.setItem('filterData', JSON.stringify(filter));
	}, [filter]);

	useEffect(() => {
		localStorage.setItem('dropData', JSON.stringify(dropsFilter));
	}, [dropsFilter]);

	useEffect(() => {
		localStorage.setItem('projectionData', JSON.stringify(projection));
	}, [projection]);

	// Reset

	const resetStateData = () => {
		setProjection(['name']);
		localStorage.setItem('projectionData', JSON.stringify(['name']));
	};

	const resetFilterData = () => {
		setFilter({});
		localStorage.setItem('filterData', JSON.stringify({}));
	};

	const resetDropData = () => {
		setDropsFilter([]);
		localStorage.setItem('dropData', JSON.stringify([]));
	};

	// cleaing

	// cleaing

	const [settingsModal, setSettingsModal] = useState(null);
	const [list, setList] = useState({});
	const [currentList, setCurrentList] = React.useState([]);

	const [stages, setStages] = useState('base');
	const [managing, setManaging] = useState({});

	const [values, setValues] = useState([]);
	const [changingValues, setChangeValues] = useState([]);

	const [userType, setUserType] = useState([]);
	const [filterBoxs, setFilterBox] = useState([]);
	const [aditionalFilter, setAditionalFilter] = useState([]);

	let { data: strength = [] } = useQuery(['loadGraphicsStageOne', baseSettings], loadGraphicsStageOne(collegeId, baseSettings), { enabled: stages === 'base' });
	let { data: drops = [] } = useQuery(['getDatajBank', userType, baseSettings], getSettings(collegeId, 'dataBank', true, userType, baseSettings), { enabled: stages === 'base' });
	let { data: additionalFilters = [] } = useQuery(['getFilterAdministrations', userType, baseSettings], getFilterAdministrations(collegeId, 'dataBank', true, userType, baseSettings), { enabled: ['base', 'filter'].includes(stages) });

	const { data: collegeData } = useQuery([`collegddeData`, collegeId], getCollegeDetailsee, { enabled: stages === 'export' });

	const [category, setCategory] = useState([]);

	useEffect(() => {
		if (Array.isArray(filterBoxs) && Array.isArray(aditionalFilter)) {
			const uniqueCategories = [
				...new Set(
					[...filterBoxs, ...aditionalFilter]
						.filter((x) => x?.needed)
						.map((x) => x?.category)
						.filter(Boolean),
				),
			];
			const counts = {};
			uniqueCategories.forEach((category) => {
				counts[category] = [...filterBoxs, ...aditionalFilter].filter((x) => x?.category === category).length;
			});

			const sortedCategories = uniqueCategories.sort((a, b) => {
				const countA = counts[a] || 0;
				const countB = counts[b] || 0;
				return countA - countB;
			});
			setCategory(sortedCategories);
		} else {
			setCategory([]);
		}
	}, [filterBoxs, aditionalFilter]);

	const handleCheckbox = (prefix, categoryKey) => {
		const key = prefix + (categoryKey?._id || categoryKey);
		setFilter({
			...filter,
			[key]: !filter[key],
		});
	};

	const { data: count = 0 } = useQuery(['retrieveStatistics', stages, filter, baseSettings], retrieveStatistics({ collegeId, userType, durationMode: baseSettings, filter }), {
		enabled: stages === 'filter',
	});
	const { data: getSelectedDetails = [], refetch } = useQuery(['fetchStatisticalDetails', stages, baseSettings, projection], fetchStatisticalDetails({ collegeId, userType, durationMode: baseSettings, filter, projection }), {
		enabled: stages === 'export',
	});

	const { data: managedSup = [], refetch: checkNewStatus } = useQuery(['retrieveVerificationInfo', stages, managing, baseSettings], retrieveVerificationInfo({ collegeId, userType, durationMode: baseSettings, filter, projection, managing }), {
		enabled: stages === 'manageData',
	});

	const [getDataValidationData, setGetDataValidationData] = useState([]);
	const [progress, setProgress] = useState(0);
	const [recall, setRecall] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			const total = drops.settings[userType]?.length || 0;
			if (total === 0) return;
			const instance = await getAxiosTokenInstance();
			for (let index = 0; index < drops.settings[userType].length; index++) {
				if (recall) {
					setGetDataValidationData([]);
					setProgress(0);
					index = 1000;
					setRecall(false);
				}
				const element = drops.settings[userType][index];
				if (element?.needed) {
					try {
						const resp = await instance.post('/college/statistics/manageVerificationProcess', {
							collegeId,
							userType,
							durationMode: baseSettings,
							filter,
							projection,
							element,
						});
						if (resp.data.statusCode === 400) {
							throw new Error(resp.data.message || 'API Error');
						}
						const data = resp?.data?.list;
						setGetDataValidationData((previous) => [...previous, data]);
						setProgress(((index + 1) / total) * 100);
					} catch (error) {
						console.error(error?.response?.data?.message || error.message || 'API Error');
					}
				}
			}
		};
		if (stages === 'dataValidation' && progress === 0) {
			fetchData();
		}
	}, [stages, userType, recall === true, drops.settings]);

	useEffect(() => {
		if (managing?.type === 'dropdown' && managedSup?.[0]?.typeErrorData?.length > 0) {
			setValues(managedSup?.[0]?.typeErrorData);
		}
	}, [managing.type, managedSup?.[0]?.typeErrorData]);

	useEffect(async () => {
		if (drops) {
			const addProperties = (array) =>
				array?.map((x) => ({
					needed: false,
					start: moment().format('YYYY-MM-DD'),
					priority: 'Low',
					...x,
				}));

			const updatedSettings = {
				scholars: addProperties(drops?.settings?.scholars),
				student: addProperties(drops?.settings?.student),
				teacher: addProperties(drops?.settings?.teacher),
				staff: addProperties(drops?.settings?.staff),
			};

			setList(updatedSettings);
		}
	}, [drops]);

	useEffect(() => {
		if (userType && drops?.settings?.[userType]) {
			const initialFilters = drops.settings[userType].filter((x) => x?.needed) || [];
			setFilterBox(initialFilters);
		} else {
			setFilterBox([]);
		}
	}, [userType, drops]);

	useEffect(() => {
		if (additionalFilters) {
			setAditionalFilter(additionalFilters);
		} else {
			setAditionalFilter([]);
		}
	}, [additionalFilters]);

	useEffect(() => {
		return () => {
			if (stages && typeof stages.destroy === 'function') {
				stages.destroy();
			}
		};
	}, [stages]);

	const postAccountsSettings = async (deleteData, _id) => {
		try {
			const instance = await getAxiosTokenInstance();

			let resp;
			resp = await instance.post('/college/action/postSettings', {
				collegeId,
				type: 'dataBank',
				userId: user?.uid,
				settings: currentList
					? {
							...list,
							[settingsModal]: currentList,
					  }
					: list,
				_id: drops?._id,
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				refetch();
				setStages('base');
			}
		} catch (error) {
		} finally {
		}
	};

	const handleRadioChange = (categoryKey) => {
		setUserType(categoryKey);
	};

	const handleChange = (keyId) => {
		setDropsFilter((prev) => {
			const updatedFilters = prev.includes(keyId) ? prev.filter((item) => item !== keyId) : [...prev, keyId];
			return updatedFilters;
		});
	};

	const handleChangeProjection = (keyId) => {
		setProjection((prev) => {
			const updatedFilters = prev.includes(keyId) ? prev.filter((item) => item !== keyId) : [...prev, keyId];
			return updatedFilters;
		});
	};

	const handleChangeValidation = (e, i) => {
		const { name, value } = e.target;
		const updatedValues = [...values];
		updatedValues[i] = { ...updatedValues[i], [name]: value };
		setValues(updatedValues);
	};

	const handleSave = async (changingValues) => {
		const instance = await getAxiosTokenInstance();
		instance
			.post('/college/statistics/executeCleanupProcess', { changingValues, collegeId, userType, managedSup, durationMode: baseSettings })
			.then((res) => {
				setChangeValues([]);
				checkNewStatus();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		return () => {
			if (stages && typeof stages.destroy === 'function') {
				stages.destroy();
			}
		};
	}, [stages]);

	const renderStage = () => {
		switch (stages) {
			case 'base':
				return (
					<Main title='Smart Analytics & Data Exporter' width={'100%'} height={'100%'}>
						<div className='flex justify-between items-center p-4'>
							<div></div>
							<div className='flex mt-[-40px]'>
								<Input state={baseSettings} setState={setBaseSettings} className='m-1 p-0' width='200px' fieldName='Filtering Type' returnKey='type' options={['Active Status', 'Between', 'Cut of Date', 'Admission Number']} type='drop' />
								{baseSettings?.type === 'Active Status' && <Input state={baseSettings} setState={setBaseSettings} className='m-1 p-0' width='200px' fieldName='Status' returnKey='status' options={['Total Active', 'Current Active', 'Current In Active', 'Total In Active']} type='drop' />}
								{baseSettings?.type === 'Cut of Date' && <Input state={baseSettings} setState={setBaseSettings} className='m-1 p-0' width='200px' fieldName='Cut Of Date' returnKey='cutOfDate' type='date' />}
								<Button type='close' onClick={onClose}>
									Close
								</Button>
							</div>
						</div>
						<div className='w-full  grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-1'>
							<div className=' p-12'>
								<PieChartComponent data={strength} />
							</div>
							<div className='w-full  grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1 p-12'>
								{strength?.map((categoryKey, index) => (
									<label key={index} className={`flex  items-center space-x-2 p-2 border border-[#3498db] rounded-lg shadow-md hover:border-[#2c3e50] hover:shadow-lg transition-transform duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer`} style={{ backgroundColor: categoryKey.color ?? 'red' }}>
										<div className='w-[20%]'>
											<input type='radio' name='category' className='form-radio  bg-slate-600 w-12 h-12 p-6 text-white rounded-full transition duration-200 ease-in-out transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:border-pink-400' checked={categoryKey?.keyType === userType} onChange={() => handleRadioChange(categoryKey.keyType)} />
										</div>
										<span className='text-2xl font-extrabold w-[50%]  text-white ml-2 '>{categoryKey.name}</span>
										<span className='text-2xl font-extrabold w-[15%] text-white ml-2'>{categoryKey.value}</span>
										<span className='text-xl font-bold w-[15%] text-white ml-2'>out of</span>
										<span className='text-2xl font-extrabold w-[15%] text-white ml-2'>{categoryKey.total}</span>
									</label>
								))}
								<div className='w-full  grid grid-cols-2 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-1'>
									<Button
										disabled={principal}
										color='#610600'
										width='100%'
										fontSize='40px'
										height='150px'
										onClick={() => {
											setSettingsModal('student');
											setCurrentList(list['student']);
											setStages('settings');
										}}>
										Settings
									</Button>
									<Button
										disabled={principal}
										color='#003b09'
										width='100%'
										fontSize='40px'
										height='150px'
										onClick={() => {
											setStages('dataValidation');
										}}>
										Validate
									</Button>
									<Button
										color='#003b09'
										width='100%'
										fontSize='40px'
										height='150px'
										onClick={() => {
											setStages('drops');
										}}>
										Next
									</Button>
								</div>
							</div>
						</div>
					</Main>
				);
			case 'settings':
				return (
					<Main width='100%' height='100%' title='Data Collection Settings'>
						<Header>
							<div className='w-full flex flex-col overflow-auto ' style={{ height: 'calc(100vh - 100px)' }}>
								<div className='flex justify-end'>
									<Button height='80%' type='save' color='green' onClick={postAccountsSettings}>
										Save
									</Button>
									<Button height='80%' type='close' color='red' onClick={() => setStages('base')}>
										Close
									</Button>
								</div>
								<div className='flex flex-row justify-between mb-3'>
									<div className='flex justify-start'>
										<Button
											color={settingsModal !== 'student' ? 'orange' : 'green'}
											onClick={() => {
												setSettingsModal('student');
												currentList &&
													setList({
														...list,
														[settingsModal]: currentList,
													});
												setCurrentList(list['student']);
											}}>
											Students
										</Button>{' '}
										<Button
											color={settingsModal !== 'teacher' ? 'orange' : 'green'}
											onClick={() => {
												setSettingsModal('teacher');
												currentList &&
													setList({
														...list,
														[settingsModal]: currentList,
													});
												setCurrentList(list['teacher']);
											}}>
											Teachers
										</Button>{' '}
										<Button
											color={settingsModal !== 'staff' ? 'orange' : 'green'}
											onClick={() => {
												setSettingsModal('staff');
												currentList &&
													setList({
														...list,
														[settingsModal]: currentList,
													});
												setCurrentList(list['staff']);
											}}>
											Staff
										</Button>
										<Button
											color={settingsModal !== 'scholars' ? 'orange' : 'green'}
											onClick={() => {
												setSettingsModal('scholars');
												setCurrentList(list['scholars']);
											}}>
											Scholars
										</Button>
									</div>
									<div className='flex justify-end '>
										<Button color='green' onClick={() => setStages('bucket')}>
											+ Bucket
										</Button>
									</div>
								</div>
								<CustomKey setCurrentList={setCurrentList} currentList={currentList} settingsModal={settingsModal} isBucket={false} dropdown={[]} />
							</div>
						</Header>
					</Main>
				);
			case 'bucket':
				return (
					<Main width='100%' height='100%' title='Necttos Provided Backet of Data'>
						<Header>
							<div className='w-full flex flex-col overflow-auto' style={{ height: 'calc(100vh - 100px)' }}>
								<div className='flex justify-end'>
									<Button height='80%' type='close' color='red' onClick={() => setStages('settings')}>
										Close
									</Button>
								</div>
								<div className='flex justify-start'>
									<Button
										height='80%'
										type='save'
										color='green'
										onClick={() => {
											setList({
												...list,
												[settingsModal]: currentList,
											});
										}}>
										Save
									</Button>
								</div>
								<CustomKey setCurrentList={setCurrentList} currentList={currentList} settingsModal={settingsModal} isBucket={true} />
							</div>
						</Header>
					</Main>
				);
			case 'drops':
				return (
					<Main title='Smart Analytics & Data Exporter' width='100vw' height='100vh'>
						<div className='flex justify-between items-center p-4'>
							<div className='flex'></div>
							<div className='flex'>
								<Button
									type='fetch'
									onClick={() => {
										setStages('base');
									}}>
									Back
								</Button>
								<Button
									type='refresh'
									onClick={() => {
										resetDropData();
									}}>
									Reset
								</Button>
								<Button type='close' onClick={onClose}>
									Close
								</Button>
							</div>
						</div>

						<div className='w-[100%] flex flex-wrap gap-6 justify-center overflow-scroll'>
							{category?.map((xxx) => (
								<div>
									<span className='text-sm font-bold text-gray-800'>{xxx}</span>

									{[...aditionalFilter, ...filterBoxs]
										?.filter((x) => x.category === xxx)
										?.map(({ name: label, _id: keyId }, indexOrg) => (
											<div
												key={keyId}
												className={`flex items-center space-x-2 p-2 m-1 border rounded-xl shadow-lg hover:shadow-xl transition-transform duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 cursor-pointer`}
												style={{
													borderColor: lightBackgroundColors[indexOrg],
													backgroundColor: lightBackgroundColors[indexOrg],
													color: mediumBackgroundColors[indexOrg],
												}}
												onMouseEnter={(e) => {
													e.currentTarget.style.borderColor = mediumBackgroundColors[indexOrg];
													e.currentTarget.style.backgroundColor = mediumBackgroundColors[indexOrg];
													e.currentTarget.querySelector('span').style.color = 'white';
												}}
												onClick={() => handleChange(keyId)}
												onMouseLeave={(e) => {
													e.currentTarget.style.borderColor = lightBackgroundColors[indexOrg];
													e.currentTarget.style.backgroundColor = lightBackgroundColors[indexOrg];
													e.currentTarget.querySelector('span').style.color = mediumBackgroundColors[indexOrg];
												}}>
												<div className='flex items-center'>
													<div className='flex bg-slate-600 h-6 w-6 mx-1 rounded-lg'>
														<input type='checkbox' className='form-checkbox h-6 w-6 text-teal-600 rounded-lg focus:ring-teal-500' checked={dropsFilter?.includes(keyId)} />
													</div>

													<span className='font-semibold'>{label}</span>
												</div>
											</div>
										))}
								</div>
							))}
						</div>
						<Button
							color='#003b09'
							width='100%'
							fontSize='40px'
							height='150px'
							onClick={() => {
								setStages('filter');
							}}>
							Next
						</Button>
					</Main>
				);
			case 'filter':
				return (
					<Main title='Smart Analytics & Data Exporter' width='100vw' height='100vh'>
						<div className='flex justify-between items-center p-4'>
							<div className='flex'></div>
							<div className='flex'>
								<Button
									type='fetch'
									onClick={() => {
										setStages('drops');
									}}>
									Back
								</Button>
								<Button
									type='refresh'
									onClick={() => {
										resetFilterData();
									}}>
									Reset
								</Button>
								<Button type='close' onClick={onClose}>
									Close
								</Button>
							</div>
						</div>
						<div className='relative overflow-x-auto flex space-x-4 py-4  scrollable-container'>
							{Array.isArray(filterBoxs) &&
								Array.isArray(dropsFilter) &&
								Array.isArray(aditionalFilter) &&
								[...filterBoxs, ...aditionalFilter]
									.filter((x) => dropsFilter.includes(x?._id))
									.map((categoryK, indexOrg) => (
										<div>
											{console.log({ categoryK })}
											<div className='flex justify-center h-[80px] item-center border-2 border-indigo-300 rounded-lg p-3 m-1 w-[250px] shadow-xl ' style={{ backgroundColor: mediumBackgroundColors[indexOrg], color: 'white' }}>
												<h5 className='font-bold text-center' style={{ color: lightBackgroundColors[indexOrg] }}>
													{categoryK.name}
												</h5>
											</div>
											<div className='border-2 border-indigo-300 rounded-lg p-3 m-1 w-[250px] shadow-xl' style={{ backgroundColor: darkBackgroundColors[indexOrg] }}>
												{(() => {
													switch (categoryK.type) {
														case 'date':
															return (
																<div className='w-full grid grid-cols-1 gap-1'>
																	<Input width='100%' fieldName='Start Date' state={filter} setState={setFilter} returnKey={categoryK?.prefix + 'st' + categoryK?._id} type='date' />
																	<Input width='100%' fieldName='End Date' state={filter} setState={setFilter} returnKey={categoryK?.prefix + 'en' + categoryK?._id} type='date' />
																</div>
															);

														case 'dropdown':
															return (
																<div className='w-full grid grid-cols-1 gap-2'>
																	{categoryK.options?.map((categoryKey, index) => (
																		<div key={index} className='flex items-center space-x-2 p-2 border border-[#3498db] rounded-lg shadow-md hover:border-[#2c3e50] hover:shadow-lg transition-transform duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer' style={{ backgroundColor: lightBackgroundColors[indexOrg] }}>
																			<div className='flex items-center'>
																				<div className='flex h-6 w-6 mx-1 rounded-lg'>
																					<input type='checkbox' className='form-checkbox h-6 w-6 text-pink-500 rounded-full transition duration-200 ease-in-out transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:border-pink-400' checked={filter[categoryK?.prefix + (categoryKey?._id || categoryKey)]} onChange={() => handleCheckbox(categoryK?.prefix, categoryKey)} />
																				</div>
																				<span className='font-semibold'>{categoryK.displayNameMap ? categoryK?.displayNameMap[categoryKey] : categoryKey}</span>
																			</div>
																		</div>
																	))}
																</div>
															);
														case 'text':
															return (
																<div className='w-full grid grid-cols-1 gap-1'>
																	<Input width='100%' fieldName='Search' state={filter} setState={setFilter} returnKey={categoryK?.prefix + categoryK?._id} type='text' />
																</div>
															);

														case 'email':
															return (
																<div className='w-full grid grid-cols-1 gap-1'>
																	<Input width='100%' fieldName='Search' state={filter} setState={setFilter} returnKey={categoryK?.prefix + categoryK?._id} type='text' />
																</div>
															);

														case 'number':
															return (
																<div className='w-full grid grid-cols-1 gap-1'>
																	<Input width='100%' fieldName='Start' state={filter} setState={setFilter} returnKey={categoryK?.prefix + 'st' + categoryK?._id} type='number' />
																	<Input width='100%' fieldName='End' state={filter} setState={setFilter} returnKey={categoryK?.prefix + 'en' + categoryK?._id} type='number' />
																</div>
															);
														default:
															return <div className='text-center text-gray-600'>Under Maintanance</div>;
													}
												})()}
											</div>
										</div>
									))}
						</div>
						<div className='flex justify-evenly w-[100%] items-center self-baseline'>
							<div className='text-[200px] w-fit font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-900 via-[#185f18] to-red-900'>{count}</div>
							<Button
								color='#003b09'
								width='200px'
								fontSize='40px'
								height='150px'
								onClick={() => {
									setStages('export');
								}}>
								Master Export
							</Button>
							<Button
								color='#003b09'
								width='200px'
								fontSize='40px'
								height='150px'
								onClick={() => {
									setStages('smartTable');
								}}>
								Smart Table
							</Button>
							<Button
								color='#003b09'
								width='200px'
								fontSize='40px'
								height='150px'
								onClick={() => {
									setStages('edit');
								}}>
								Master Edit
							</Button>
						</div>
					</Main>
				);
			case 'smartTable':
				return (
					<Main title='Smart Analytics & Data Exporter' width='100vw' height='100vh'>
						<div className='flex justify-between items-center p-4'>
							<div className='flex'></div>
							<div className='flex'>
								<Button
									width='100%'
									height='80%'
									type='save'
									onClick={() => {
										setStages('filter');
									}}>
									Back
								</Button>
								<Button width='100%' height='80%' type='save' onClick={resetStateData}>
									Reset
								</Button>
								<Button type='close' onClick={onClose}>
									Close
								</Button>
							</div>
						</div>
					</Main>
				);
			case 'edit':
				return (
					<Main title='Smart Analytics & Data Exporter' width='100vw' height='100vh'>
						<div className='flex justify-between items-center p-4'>
							<div className='flex'></div>
							<div className='flex'>
								<Button
									width='100%'
									height='80%'
									type='save'
									onClick={() => {
										setStages('filter');
									}}>
									Back
								</Button>
								<Button width='100%' height='80%' type='save' onClick={resetStateData}>
									Reset
								</Button>
								<Button type='close' onClick={onClose}>
									Close
								</Button>
							</div>
						</div>
					</Main>
				);
			case 'export':
				return (
					<Main title='Smart Analytics & Data Exporter' width='100vw' height='100vh'>
						<div className='flex justify-between items-center p-4'>
							<div className='flex'></div>
							<div className='flex'>
								<Button
									type='download'
									onClick={() => {
										refetch();
									}}>
									Revise Table
								</Button>
								<Button type='refresh' onClick={resetStateData}>
									Reset Table Heads
								</Button>
								<Button type='print' tableRef={ref.current}>
									Print Table
								</Button>
								<Button type='excel' tableRef={ref.current}>
									Export to Excel
								</Button>
							</div>
							<div className='flex'>
								<Button
									type='save'
									onClick={() => {
										setStages('filter');
									}}>
									Back
								</Button>
								<Button type='close' onClick={onClose}>
									Close
								</Button>
							</div>
						</div>
						<div className='w-full  h-[90%] flex '>
							<div className='w-[40%] flex flex-wrap gap-6 justify-center overflow-scroll'>
								{category?.map((xxx) => (
									<div>
										<span className='text-sm font-bold text-gray-800'>{xxx}</span>
										{[...aditionalFilter, ...filterBoxs]
											?.filter((x) => x.category === xxx)
											?.map(({ name: label, _id: keyId }, indexOrg) => (
												<div
													key={keyId}
													className={`flex items-center space-x-2 p-2 m-1 border rounded-xl shadow-lg hover:shadow-xl transition-transform duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 cursor-pointer`}
													style={{
														borderColor: lightBackgroundColors[indexOrg],
														backgroundColor: lightBackgroundColors[indexOrg],
														color: mediumBackgroundColors[indexOrg],
													}}
													onMouseEnter={(e) => {
														e.currentTarget.style.borderColor = mediumBackgroundColors[indexOrg];
														e.currentTarget.style.backgroundColor = mediumBackgroundColors[indexOrg];
														e.currentTarget.querySelector('span').style.color = 'white';
													}}
													onClick={() => handleChangeProjection(keyId)}
													onMouseLeave={(e) => {
														e.currentTarget.style.borderColor = lightBackgroundColors[indexOrg];
														e.currentTarget.style.backgroundColor = lightBackgroundColors[indexOrg];
														e.currentTarget.querySelector('span').style.color = mediumBackgroundColors[indexOrg];
													}}>
													<div className='flex items-center'>
														<div className='flex bg-slate-600 h-6 w-6 mx-1 rounded-lg'>
															<input type='checkbox' className='form-checkbox h-6 w-6 text-teal-600 rounded-lg focus:ring-teal-500' checked={projection?.includes(keyId)} />
														</div>
														<span className='font-semibold'>{label}</span>
													</div>
												</div>
											))}
									</div>
								))}
							</div>

							<div ref={ref} className='pt-2 printable   w-[60%] bg-white overflow-scroll'>
								<div className='flex justify-between items-center p-4'></div>
								<table className='min-w-full table-auto'>
									<tbody>
										<tr>
											<td className='p-1 text-center text-black bg-white text-lg' style={{ width: '70%', fontFamily: 'Times New Roman' }}>
												{collegeData?.collegeName}
											</td>
										</tr>
										<tr>
											<td className='p-1 text-center text-black bg-white text-xl font-bold' style={{ width: '70%', fontFamily: 'Times New Roman' }}>
												Seleted users count: {count}
											</td>
										</tr>
										<tr>
											<td className='p-1 text-center text-black bg-white text-lg' style={{ width: '70%', fontFamily: 'Times New Roman' }}>
												STATISTICS REPORT FROM NECTTOS @ {moment(new Date()).format('DD-MM-YYYY hh:mm A')}
											</td>
										</tr>
									</tbody>
								</table>
								<Table width={`${projection.length * 300 + 30}px`} innerWidth={['5%', '15%', '35%', '15%', '10%', '10%']}>
									<Thead>
										<Tr>
											<Th width='50px' position={0}>
												Sl. No.
											</Th>
											{projection?.map((headerKey) => {
												const headerName = [...aditionalFilter, ...filterBoxs].find((filter) => filter._id === headerKey)?.shortName;

												return (
													<Th key={headerKey} width='300px' position={1}>
														{headerName || 'Unnamed Header'}
													</Th>
												);
											})}
										</Tr>
									</Thead>

									<Tbody height='auto'>
										{getSelectedDetails?.map((data, i) => (
											<Tr>
												<Td index={i} width='50px' position={0}>
													{i + 1}
												</Td>
												{projection?.map((x) => (
													<Td index={i} width='300px' position={5}>
														{data[x]}
													</Td>
												))}
											</Tr>
										))}
									</Tbody>
								</Table>
							</div>
						</div>
					</Main>
				);
			case 'dataValidation':
				return (
					<Main title='Data Accuracy Control' width='100vw' height='100vh'>
						<div className='flex justify-between items-center p-4'>
							<div className='flex'>
								<div className='flex items-center w-[800px] bg-gray-200 p-2 rounded'>
									<div className='font-semibold w-[40%] text-left text-white bg-blue-600 p-2 rounded-l-lg'>Name</div>
									<div className='w-[15%] text-center text-white bg-green-600 font-bold p-2'>Total</div>
									<div className='w-[15%] text-center text-white bg-orange-600 font-bold p-2'>Corrected</div>
									<div className='w-[15%] text-center text-white bg-red-600 font-bold p-2'>Type Error</div>
									<div className='w-[15%] text-center text-white bg-gray-600 font-bold p-2 rounded-r-lg'>No Data</div>
								</div>
							</div>
							<div className='flex'>
								<Button
									width='100%'
									height='80%'
									type='save'
									onClick={() => {
										setStages('base');
									}}>
									Back
								</Button>
								<Button
									width='100%'
									height='80%'
									type='save'
									disabled={progress !== 100}
									onClick={() => {
										setProgress(0);
										setRecall(true);
										setGetDataValidationData([]);
									}}>
									Clear and Get New Report
								</Button>
								<Button type='close' onClick={onClose}>
									Close
								</Button>
							</div>
						</div>
						<div className='flex justify-between items-center p-4'>
							<div className='w-full bg-gray-200 rounded-full h-6 relative'>
								<div className='bg-green-500 h-6 rounded-full transition-all duration-500' style={{ width: `${progress}%` }}></div>
								<span className='absolute inset-0 flex justify-center items-center text-black font-bold text-sm'>{Math.round(progress)}%</span>
							</div>
						</div>

						<div className='w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 overflow-scroll'>
							{category?.map((xxx) => (
								<div>
									<span className='text-lg font-bold  text-gray-800'>{xxx}</span>
									{getDataValidationData
										?.filter((x) => x.category === xxx && x.needed)
										?.map(({ name, _id, total, corrected, type, typeError, noData }, indexOrg) => (
											<div
												className='flex items-center  w-[100%] bg-gray-200 p-2 rounded cursor-pointer'
												onClick={() => {
													setStages('manageData');
													setManaging({ name, _id, total, type, corrected, typeError, noData });
												}}>
												<div className='font-semibold w-[40%] text-left text-white bg-blue-600 p-2 rounded-l-lg'>{name}</div>
												<div className='w-[15%] text-center text-white bg-green-600 font-bold p-2'>{total}</div>
												<div className='w-[15%] text-center text-white bg-orange-600 font-bold p-2'>{corrected}</div>
												<div className='w-[15%] text-center text-white bg-red-600 font-bold p-2'>{typeError}</div>
												<div className='w-[15%] text-center text-white bg-gray-600 font-bold p-2 rounded-r-lg'>{noData}</div>
											</div>
										))}
								</div>
							))}
						</div>
					</Main>
				);
			case 'manageData':
				return (
					<Main title='Data Accuracy Control' width='100vw' height='100vh'>
						<div className='flex justify-between items-center p-4'>
							<div className='flex'>
								{managedSup?.map(({ name, _id, total, corrected, typeError, noData }, indexOrg) => (
									<div>
										<div
											className='flex items-center  w-[800px] bg-gray-200  rounded cursor-pointer'
											onClick={() => {
												setStages('manageData');
												setManaging({ name, _id, total, corrected, typeError, noData });
											}}>
											<div className='font-semibold w-[40%] text-left text-white bg-blue-600 p-2 rounded-tl-lg'>Name</div>
											<div className='w-[15%] text-center text-white bg-green-600 font-bold p-2'>Total</div>
											<div className='w-[15%] text-center text-white bg-orange-600 font-bold p-2'>Corrected</div>
											<div className='w-[15%] text-center text-white bg-red-600 font-bold p-2'>Type Error</div>
											<div className='w-[15%] text-center text-white bg-gray-600 font-bold p-2 rounded-tr-lg'>No Data</div>
										</div>
										<div
											className='flex items-center  w-[800px] bg-gray-200 rounded cursor-pointer'
											onClick={() => {
												setStages('manageData');
												setManaging({ name, _id, total, corrected, typeError, noData });
											}}>
											<div className='font-semibold w-[40%] text-left text-white bg-blue-600 p-2 rounded-bl-lg'>{name}</div>
											<div className='w-[15%] text-center text-white bg-green-600 font-bold p-2'>{total}</div>
											<div className='w-[15%] text-center text-white bg-orange-600 font-bold p-2'>{corrected}</div>
											<div className='w-[15%] text-center text-white bg-red-600 font-bold p-2'>{typeError}</div>
											<div className='w-[15%] text-center text-white bg-gray-600 font-bold p-2 rounded-br-lg'>{noData}</div>
										</div>
									</div>
								))}
								<Button
									width='100%'
									height='80%'
									type='save'
									onClick={() => {
										checkNewStatus();
									}}>
									Check New Status
								</Button>
							</div>

							<div className='flex'>
								<Button
									width='100%'
									height='80%'
									type='save'
									onClick={() => {
										setStages('dataValidation');
									}}>
									Back
								</Button>
								<Button width='100%' height='80%' type='save' onClick={resetStateData}>
									Reset
								</Button>
								<Button type='close' onClick={onClose}>
									Close
								</Button>
							</div>
						</div>

						<div className='w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-2 overflow-scroll'>
							<div className='w-[40vw]'>
								{managedSup?.[0]?.type === 'dropdown' ? (
									<div className='bg-white rounded-lg w-[40vw] h-[80vh] p-6 overflow-scroll'>
										<h1 className='text-2xl font-semibold text-center mb-4'>Data Correction Table</h1>
										<p className='text-center text-gray-600 mb-8'>Review and modify the incorrect entries below carefully. Once you have made changes, click the save button to update the data.</p>

										<Table width={`100%`} innerWidth={['5%', '15%', '35%', '15%', '10%', '10%']}>
											<Thead>
												<Tr>
													<Th width='50px' position={0}>
														Sl.No.
													</Th>
													<Th width='300px' position={0}>
														In Data Base
													</Th>
													<Th width='300px' position={0}>
														Mistake Count
													</Th>
													<Th width='250px' position={1}>
														Change To
													</Th>
												</Tr>
											</Thead>
											<Tbody height='auto'>
												{values?.map((data, i) => (
													<Tr>
														<Td index={i} width='50px' position={0}>
															{i + 1}
														</Td>
														<Td index={i} width='300px' position={0}>
															{data?.oldItem}
														</Td>
														<Td index={i} width='300px' position={0}>
															{data?.count}
														</Td>
														<Td position={5} width='250px' index={i}>
															<select value={data?.newItem || ''} name='newItem' onChange={(e) => handleChangeValidation(e, i)}>
																<option value={null}>....</option>
																{managedSup?.[0]?.options?.map((z) => (
																	<option value={z}>{z}</option>
																))}
															</select>
														</Td>
													</Tr>
												))}
											</Tbody>
										</Table>

										<Button
											width='100%'
											height='80%'
											type='save'
											onClick={() => {
												setChangeValues(values.filter((item) => ![null, '', '....'].includes(item.newItem)));
											}}>
											Validate What you are going to do ?
										</Button>

										{changingValues.length > 0 && (
											<>
												<Table width={`100%`} innerWidth={['5%', '15%', '35%', '15%', '10%', '10%']}>
													<Thead>
														<Tr>
															<Th width='50px' position={0}>
																Sl.No.
															</Th>
															<Th width='300px' position={0}>
																In Data Base
															</Th>
															<Th width='300px' position={0}>
																Mistake Count
															</Th>
															<Th width='250px' position={1}>
																Change To
															</Th>
														</Tr>
													</Thead>
													<Tbody height='auto'>
														{changingValues?.map((data, i) => (
															<Tr>
																<Td index={i} width='50px' position={0}>
																	{i + 1}
																</Td>
																<Td index={i} width='300px' position={0}>
																	{data?.oldItem}
																</Td>
																<Td index={i} width='300px' position={0}>
																	{data?.count}
																</Td>
																<Td index={i} width='300px' position={0}>
																	{data?.newItem}
																</Td>
															</Tr>
														))}
													</Tbody>
												</Table>
												<Button
													width='100%'
													height='80%'
													type='save'
													onClick={() => {
														handleSave(changingValues);
													}}>
													Job Registering
												</Button>
											</>
										)}
									</div>
								) : (
									<div className='bg-white rounded-lg w-[40vw] h-[80vh] p-6 overflow-scroll'>
										<h1 className='text-2xl font-semibold text-center mb-4'>Data Correction Table</h1>
										<p className='text-center text-gray-600 mb-8'>Review and modify the incorrect entries below carefully. Once you have made changes, click the save button to update the data.</p>

										<Table width={`100%`} innerWidth={['5%', '15%', '35%', '15%', '10%', '10%']}>
											<Thead>
												<Tr>
													<Th width='50px' position={0}>
														Sl.No.
													</Th>
													<Th width='300px' position={0}>
														Name
													</Th>

													<Th width='250px' position={1}>
														{managedSup?.[0]?.name}
													</Th>
												</Tr>
											</Thead>
											<Tbody height='auto'>
												{managedSup?.[0]?.typeErrorData?.map((data, i) => (
													<Tr>
														<Td index={i} width='50px' position={0}>
															{i + 1}
														</Td>
														<Td index={i} width='300px' position={0}>
															{data?.name || data}
														</Td>
														<Td index={i} width='250px' position={5}>
															{data?.[managedSup?.[0]?._id]}
														</Td>
													</Tr>
												))}
											</Tbody>
										</Table>

										<Button
											width='100%'
											height='80%'
											type='save'
											onClick={() => {
												handleSave();
											}}>
											Cleaning Start
										</Button>
									</div>
								)}
							</div>
							<div className='w-[40vw]'>
								<Table width={`500px`} innerWidth={['5%', '15%', '35%', '15%', '10%', '10%']}>
									<Thead>
										<Tr>
											<Th width='50px' position={0}>
												Sl.No.
											</Th>
											<Th width='300px' position={0}>
												Name
											</Th>

											<Th width='250px' position={1}>
												{managedSup?.[0]?.name}
											</Th>
										</Tr>
									</Thead>
									<Tbody height='auto'>
										{managedSup?.[0]?.noDataItems?.map((data, i) => (
											<Tr>
												<Td index={i} width='50px' position={0}>
													{i + 1}
												</Td>
												<Td index={i} width='300px' position={0}>
													{data?.name}
												</Td>

												<Td index={i} width='250px' position={5}>
													{data?.[managedSup?.[0]?._id]}
												</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
							</div>
						</div>
					</Main>
				);
		}
	};

	return <>{renderStage()}</>;
};
