import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '../../NecttosComp/Button/Button';
import { Main } from '../../NecttosComp/Layout/Layout';
import { getKeys, getProfileData } from '../../NecttosComp/APICaller/ApiServices';
import { FirebaseContext } from '../../context/FirebaseContext';
import { useQuery } from 'react-query';
import { uploadPhotoToFirebase } from '../../NecttosComp/APICaller/APICaller';
import toast, { Toaster } from 'react-hot-toast';
import { getAxiosInstance } from '../../utils/axiosInstance';
import { getCollegeDetailsee } from '../Admission/RemoveAction';
import moment from 'moment';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { GrFormEdit } from 'react-icons/gr';
import { MdAddBox, MdDelete } from 'react-icons/md';
import Select from 'react-select';

function TCView2({ tcView, setTcView, userId }) {
	const [tcSettingId, setTcSettingId] = useState('');
	const { collegeId, user } = useContext(FirebaseContext);
	const initialSections = [
		{ keyName: 'name', title: 'Name of the Student', longTitle: 'Full Name of the Student as per Admission Records', value: '', type: 'string', width: '200px' },
		{ keyName: 'dob', title: 'Date of Birth as per Admission Register', longTitle: 'Date of Birth as Recorded in Admission Register', value: '', type: 'date', width: '150px' },
		{ keyName: 'admisionNo', title: 'Admission No.', longTitle: 'Admission Number of the Student', value: '', type: 'string', width: '100px' },
		{ keyName: 'dateOfAdmission', title: 'Date of Admission', longTitle: 'Date when the Student was Admitted', value: '', type: 'date', width: '150px' },
		{ keyName: 'dateOfLeaving', title: 'Date of Leaving', longTitle: 'Date when the Student Left the Institution', value: '', type: 'date', width: '150px' },
		{ keyName: 'longName', title: 'Programme Studied', longTitle: 'List of Subjects Studied by the Student', value: '', type: 'array', width: '300px' },
		{ keyName: 'reasonForLeaving', title: 'Course Completed', longTitle: 'Reason for Leaving the Institution (e.g., Course Completed)', value: '', type: 'string', width: '250px' },
		{ keyName: 'promotedToHigherClass', title: 'Promoted to Higher Class', longTitle: 'Details of Promotion to a Higher Class', value: 'Vide the Certificate', type: 'string', width: '200px' },
		{ keyName: 'duesCleared', title: 'Dues Cleared', longTitle: 'Confirmation if Dues Have Been Cleared', value: 'Yes', type: 'string', width: '100px' },
		{ keyName: 'exam', title: 'Examination last appeared', longTitle: 'Name of the Last Examination the Student Appeared For', value: '', type: 'string', width: '200px' },
		{ keyName: 'appliedOn', title: 'Date of Apply for TC', longTitle: 'Date When the Student Applied for Transfer Certificate', value: '', type: 'date', width: '150px' },
		{ keyName: 'aprovedOn', title: 'Date of Issue of TC', longTitle: 'Date When the Transfer Certificate was Issued', value: '', type: 'date', width: '150px' },
		{ keyName: 'characterAndConduct', title: 'Character and Conduct', longTitle: "Remarks on the Student's Character and Conduct", value: 'Good', type: 'string', width: '100px' },
	];

	const tcDateKeys = ['tcDate', 'appliedOn', 'aprovedOn', 'dateOfLeaving'];

	const [sections, setSections] = useState(initialSections);

	const [logo, setLogo] = useState(null);
	const [seal, setSeal] = useState(null);
	const [addNew, setAddNew] = useState(false);
	const ref = useRef(null);
	const [signature, setSignature] = useState(null);
	const [signatureText, setSignatureText] = useState(`Principal's Signature`);
	const [signatureTextEdit, setSignatureTextEdit] = useState(false);
	const [tcNumberText, setTcNumberText] = useState(`TC Number`);
	const [tcNumberTextEdit, setTcNumberTextedit] = useState(false);
	const [headerLogo, setHeaderLogo] = useState(false);
	const [watermarkLogo, setWatermarkLogo] = useState(false);
	const [settings, setSettings] = useState(false);
	const [editingField, setEditingField] = useState(null);
	const [editedTitle, setEditedTitle] = useState('');
	const [addHeader, setAddHeader] = useState(false);
	const [addCfield, setAddCField] = useState(false);
	const [customFields, setCustomFields] = useState([]);
	const [customTcDateKey, setCustomTcDateKey] = useState('tcDate');
	const [customTcDateKeyEdit, setCustomTcDateKeyEdit] = useState(false);

	const [selectedFields, setSelectedFields] = useState({});

	const allFieldKeys = initialSections?.map((field) => field.keyName);
	let initialSelectedFields = allFieldKeys?.reduce((acc, keyName) => ({ ...acc, [keyName]: true }), {});

	const [newSection, setNewSection] = useState({
		keyName: '',
		title: '',
		longTitle: '',
		type: 'string',
	});
	const [customStyles, setCustomStyles] = useState({
		padding: '16px',
		margin: '0px',
		marginTop: '16px',
		fontSize: '14px',
		lineHeight: '14px',
		color: 'red',
		headerLineHeight: '14px',
		gap: '4px',
		headerFontSize: '28px',
		titleFontSize: '20px',
		orientation: 'landscape',
		printWidth: '147mm',
		printHeight: '210mm',
		watermarkOpacity: '0.1',
		textColor: '#333',
		borderColor: '#ccc',
		signatureWidth: '100px',
		signatureHeight: '50px',
		paperSize: 'custom',
		sealWidth: '80px',
		sealHeight: '80px',
		copies: true,
		width: '147mm',
		height: '200mm',
		left: '10px',
		top: '10px',
		logoSize: '20px',
		watermarkLeft: '30%',
		watermarkTop: '30%',
		watermarkSize: '100px',
		watermarkOpacity: '0.1',
		bodyFieldFontWeight: 'normal',
		bodyValueFontWeight: 'bold',
		tcNoDateFontSize: '20px',
		tcNoDateLineHeight: '14px',
		tcNoDateFontWeight: 'bold',
		fontFamily: 'sans-serif',
	});

	const { data: profile } = useQuery('getProfileDataTc2', getProfileData({ studentId: userId, collegeId }));
	const { data: fieldsData } = useQuery('getKeys', getKeys());

	const getDetails = async () => {
		try {
			const instance = await getAxiosInstance();
			const { data } = await instance.get('/college/action/getTcSettings', {
				params: { collegeId, tcSettings: 'tcSettings', tcSections: 'tcSections' },
			});
			if (data?.statusCode === 200) {
				console.log('dataa', data);
				setTcSettingId(data?.tcSettings?._id);
				if (data?.tcSettings?.selectedFields) {
					setSelectedFields(data?.tcSettings?.selectedFields);
				} else {
					setSelectedFields(initialSelectedFields);
				}
				if (data?.tcSettings?.sections) {
					setSections(data.tcSettings?.sections);
				}

				if (data?.tcSettings?.customStyles) {
					setCustomStyles(data?.tcSettings?.customStyles);
				}
				if (data?.tcSettings?.customHeaders) {
					setCustomHeaders(data?.tcSettings?.customHeaders);
				}
				if (data?.tcSettings?.selectedDateFormat) {
					setSelectedDateFormat(data?.tcSettings?.selectedDateFormat);
				}
				if (data?.tcSettings?.signatureText) {
					setSignatureText(data?.tcSettings?.signatureText);
				}
				if (data?.tcSettings?.tcNumberText) {
					setTcNumberText(data?.tcSettings?.tcNumberText);
				}
				if (data?.tcSettings?.customFields) {
					setCustomFields(data?.tcSettings?.customFields);
				}
				if (data?.tcSettings?.customTcDateKey) {
					setCustomTcDateKey(data?.tcSettings?.customTcDateKey);
				}
			} else {
				throw new Error(data?.message || 'Failed to fetch tieDetails details.');
			}
		} catch (error) {
			console.error('Error in getDetails:', error);
			throw error;
		}
	};

	useEffect(() => {
		getDetails();
	}, [settings]);

	const saveSettings = async () => {
		const instance = getAxiosInstance();
		try {
			const resp = await instance.post('/college/action/updateTcSettings', { _id: tcSettingId, collegeId, updatedBy: user._id, selectedFields, sections, customStyles, customHeaders, selectedDateFormat, signatureText, customFields, tcNumberText, customTcDateKey });
			console.log(resp);
			toast.success('submitted succesfully');
			setSettings(false);
		} catch (error) {
			toast.error('not saved');
			console.error('Error uploading file:', error);
		}
	};

	const handleLogoUpload = async (e) => {
		const { name, value } = e.target;
		const files = e.target.files;
		const instance = await getAxiosInstance();
		if (e.target.files && e.target.files[0]) {
			const { url } = await uploadPhotoToFirebase(files[0], `college/${collegeId}/${name}.jpg`, (progress) => {});
			setLogo(url);
			const { data } = await instance.post('/college/action/updateTcLogo', { collegeId, name, url });
			toast.success('logo upload success');
		}
	};
	const handleSealUpload = async (e) => {
		const { name, value } = e.target;
		const files = e.target.files;
		const instance = await getAxiosInstance();
		if (e.target.files && e.target.files[0]) {
			const { url } = await uploadPhotoToFirebase(files[0], `college/${collegeId}/${name}.jpg`, (progress) => {});
			setSeal(url);
			setHeaderLogo(url);
			const { data } = await instance.post('/college/action/updateTcLogo', { collegeId, name, url });
			toast.success('seal upload success');
		}
	};
	const handleSignatureUpload = async (e) => {
		const { name, value } = e.target;
		const files = e.target.files;
		const instance = await getAxiosInstance();
		if (e.target.files && e.target.files[0]) {
			const { url } = await uploadPhotoToFirebase(files[0], `college/${collegeId}/${name}.jpg`, (progress) => {});
			setSignature(url);
			const { data } = await instance.post('/college/action/updateTcLogo', { collegeId, name, url });
			toast.success('signature upload success');
		}
	};

	const { data: college } = useQuery([`collegddeData`, collegeId, headerLogo, seal, signature], getCollegeDetailsee);

	useEffect(() => {
		if (college?.headerLogo) {
			setLogo(college.headerLogo);
			setHeaderLogo(college.headerLogo);
			setWatermarkLogo(college.headerLogo);

			setCustomHeaders((prev) =>
				prev.map((header) => {
					if (header.key === 'collegeName' && header.value === null) {
						return { ...header, value: college.collegeName };
					}
					if (header.key === 'affiliated' && header.value === null) {
						return { ...header, value: college.affiliated };
					}
					if (header.key === 'address' && header.value === null) {
						return { ...header, value: college.address };
					}
					return header;
				}),
			);
		}

		if (college?.seal) {
			setSeal(college.seal);
		}

		if (college?.signature) {
			setSignature(college.signature);
		}
	}, [college]);

	const handleFieldChange = (event) => {
		const { name, checked } = event.target;
		setSelectedFields((prevFields) => ({
			...prevFields,
			[name]: checked,
		}));
	};

	const handleTitleEdit = (field) => {
		setEditingField(field?.keyName);
		setEditedTitle(field?.title);
	};

	const saveTitleEdit = (keyName) => {
		setSections((prevSections) => prevSections?.map((field) => (field.keyName === keyName ? { ...field, title: editedTitle } : field)));
		setEditingField(null);
	};

	const handleDragEnd = (result) => {
		if (!result.destination) return;
		const { source, destination } = result;
		const updatedFields = Array.from(sections);
		const [movedItem] = updatedFields.splice(source.index, 1);
		updatedFields.splice(destination.index, 0, movedItem);
		setSections(updatedFields);
	};

	const customHeadersData = [
		{
			key: 'collegeName',
			value: null,
			style: [
				{ key: 'fontSize', value: 24, title: 'CollegeName Font Size', type: 'string' },
				{ key: 'lineHeight', value: 14, title: 'Line Height', type: 'string' },
				{ key: 'fontWeight', value: 'normal', title: 'Font Weight', type: 'string' },
				{ key: 'textDecoration', value: 'none', title: 'Underline', type: 'string' },
				{ key: 'color', value: 'none', title: 'Color', type: 'string' },
			],
			checked: true,
		},
		{
			key: 'affiliated',
			value: null,
			style: [
				{ key: 'fontSize', value: 14, title: 'Affiliated Font Size', type: 'string' },
				{ key: 'lineHeight', value: 14, title: 'Line Height', type: 'string' },
				{ key: 'fontWeight', value: 'normal', title: 'Font Weight', type: 'string' },
				{ key: 'textDecoration', value: 'none', title: 'Underline', type: 'string' },
				{ key: 'color', value: 'black', title: 'Color', type: 'string' },
			],
			checked: true,
		},
		{
			key: 'address',
			value: null,
			style: [
				{ key: 'fontSize', value: 14, title: 'Address Font Size', type: 'string' },
				{ key: 'lineHeight', value: 14, title: 'Line Height', type: 'string' },
				{ key: 'fontWeight', value: 'normal', title: 'Font Weight', type: 'string' },
				{ key: 'textDecoration', value: 'none', title: 'Underline', type: 'string' },
				{ key: 'color', value: 'black', title: 'Color', type: 'string' },
			],
			checked: true,
		},
		{
			key: 'transferCertificate',
			value: 'TRANSFER CERTIFICATE',
			style: [
				{ key: 'fontSize', value: 14, title: 'TC Font Size', type: 'string' },
				{ key: 'lineHeight', value: 14, title: 'Line Height', type: 'string' },
				{ key: 'fontWeight', value: 'normal', title: 'Font Weight', type: 'string' },
				{ key: 'textDecoration', value: 'underline', title: 'Underline', type: 'string' },
				{ key: 'color', value: 'black', title: 'Color', type: 'string' },
			],
			checked: true,
		},
	];

	const [customHeaders, setCustomHeaders] = useState(customHeadersData);

	const handleStyleChange = (headerKey, styleKey, newValue) => {
		setCustomHeaders((prevHeaders) =>
			prevHeaders.map((header) =>
				header.key === headerKey
					? {
							...header,
							style: header.style.map((st) => (st.key === styleKey ? { ...st, value: newValue } : st)),
					  }
					: header,
			),
		);
	};

	const [editingKey, setEditingKey] = useState(null);
	const [editedValue, setEditedValue] = useState('');
	const [newHeaderTitle, setNewHeaderTitle] = useState('');
	const [editingFieldKey, setEditingFieldKey] = useState(null);
	const handleEdit = (key, currentValue) => {
		setEditingKey(key);
		setEditedValue(currentValue);
	};

	const saveEdit = (key) => {
		setCustomHeaders((prevHeaders) => prevHeaders.map((header) => (header.key === key ? { ...header, value: editedValue } : header)));
		setEditingKey(null);
	};

	const handleDragEndHeader = (result) => {
		if (!result.destination) return;

		const reorderedHeaders = Array.from(customHeaders);
		const [removed] = reorderedHeaders.splice(result.source.index, 1);
		reorderedHeaders.splice(result.destination.index, 0, removed);

		setCustomHeaders(reorderedHeaders);
	};

	const addNewHeader = () => {
		if (newHeaderTitle.trim() === '') return;

		const newHeader = {
			key: `header${Date.now()}}`,
			value: newHeaderTitle,
			checked: false,
			style: [
				{ key: 'fontSize', value: 16, title: 'Font Size', type: 'number' },
				{ key: 'lineHeight', value: 12, title: 'Line Height', type: 'number' },
				{ key: 'fontWeight', value: 'normal', title: 'Font Weight', type: 'string' },
				{ key: 'textDecoration', value: 'normal', title: 'Underline', type: 'string' },
				{ key: 'color', value: 'black', title: 'Color', type: 'string' },
			],
		};

		setCustomHeaders((prevHeaders) => [...prevHeaders, newHeader]);
		setNewHeaderTitle('');
		setAddHeader(false);
	};

	const [newCustomField, setNewCustomField] = useState({});

	const addCustomFields = () => {
		if (newCustomField?.title?.trim() === '' || newCustomField?.value?.trim() === '') return;

		const newField = {
			key: `header${Date.now()}`,
			title: newCustomField.title,
			value: newCustomField?.value,
			checked: true,
		};

		setCustomFields((prv) => [...prv, newField]);
		setNewCustomField({});
		setAddCField(false);
	};

	const handleDragEndCustomField = (result) => {
		if (!result.destination) return;

		const reorderedHeaders = Array.from(customFields);
		const [removed] = reorderedHeaders.splice(result.source.index, 1);
		reorderedHeaders.splice(result.destination.index, 0, removed);

		setCustomFields(reorderedHeaders);
	};

	const deleteCustomField = (field) => {
		setCustomFields((prv) => prv.filter((x) => x.key !== field));
	};
	const deleteHeaders = (header) => {
		setCustomHeaders((prvHeaders) => prvHeaders.filter((x) => x.key !== header));
	};
	const deleteSections = (section) => {
		setSections((prv) => prv.filter((x) => x.keyName !== section));
	};

	const handleValueEdit = (keyName, newValue) => {
		setSections((prevSections) => prevSections.map((section) => (section.keyName === keyName ? { ...section, value: newValue } : section)));
	};
	const [selectedDateFormat, setSelectedDateFormat] = useState('DD/MM/YYYY');
	const [currentDate] = useState(new Date());

	const dateFormats = ['DD/MM/YYYY', 'DD-MM-YYYY', 'DD.MM.YYYY', 'Do MMMM YYYY', 'Do MMM YYYY', 'DD MMM YYYY', 'DD/MM/YY', 'D/M/YY', 'D-M-YY', 'D.M.YY', 'DD-MM-YY', 'DD.MM.YY', 'MM/DD/YYYY', 'MM/DD/YY', 'YYYY-MM-DD', 'dddd, MMMM Do YYYY', 'DD-MM-YYYY HH:mm:ss', 'DD MMMM YYYY', 'D MMM YYYY, h:mm A', 'D MMM YYYY, HH:mm'];

	const handleChange = (e) => {
		setSelectedDateFormat(e.target.value);
	};

	const formatCurrentDate = (date, format) => {
		if (!moment(date, moment.ISO_8601, true).isValid()) {
			return 'Invalid Date';
		}
		if (typeof format !== 'string') {
			return 'Invalid Format';
		}
		return moment(date).format(format);
	};
	const formatCurrentDateToWords = (date, format) => {
		if (!moment(date, moment.ISO_8601, true).isValid()) {
			return 'Invalid Date';
		}
		if (typeof format !== 'string') {
			return 'Invalid Format';
		}
		return moment(date).format();
	};

	const convertDateToWords = (dateString) => {
		console.log({ dateString });

		const units = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
		const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
		const months = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

		function numberToWords(number) {
			if (number === 0) return 'zero';
			if (number < 20) return units[number];
			if (number < 100) return tens[Math.floor(number / 10)] + ' ' + units[number % 10];
			if (number < 1000) return units[Math.floor(number / 100)] + ' hundred ' + numberToWords(number % 100);
			if (number < 1000000) return numberToWords(Math.floor(number / 1000)) + ' thousand ' + numberToWords(number % 1000);
			if (number < 1000000000) return numberToWords(Math.floor(number / 1000000)) + ' million ' + numberToWords(number % 1000000);
			return 'Number is too large to convert to words.';
		}

		const dateParts = dateString?.split('-');
		const day = parseInt(dateParts?.[2]);
		const month = parseInt(dateParts?.[1]);
		const year = parseInt(dateParts?.[0]);

		const dayWords = numberToWords(day);
		const monthWords = months[month];
		const yearWords = numberToWords(year);

		const capitalize = (str) => str?.replace(/\b\w/g, (char) => char.toUpperCase());

		return `${capitalize(dayWords)} ${capitalize(monthWords)} ${capitalize(yearWords)}`;
	};

	function getFormattedFieldValue(field, profile, selectedDateFormat) {
		const fieldValue = profile?.[field?.keyName];

		if (fieldValue) {
			if (field?.type === 'date') {
				return formatCurrentDate(fieldValue, selectedDateFormat);
			}
			if (field?.type === 'dateToWords') {
				return convertDateToWords(profile?.[field?.keyName.slice(1)]);
			}
			return fieldValue;
		}

		return field?.value;
	}

	const handleOptionChange = (selectedOption) => {
		setNewSection({ ...newSection, keyName: selectedOption.value });
	};

	const options = fieldsData?.map((option) => ({
		value: option?.keyName,
		label: option?.title,
	}));

	return (
		<Main width='100vw' height='100vh' className='relative' title='Print Transfer Certificate'>
			<Toaster />
			<div className='absolute left-1 top-1 flex'></div>
			<div className='absolute right-0 top-1 flex gap-2'>
				{settings ? (
					<div className='flex'>
						<Button
							type='save'
							onClick={() => {
								saveSettings();
							}}>
							Save Settings
						</Button>
						<Button
							type='close'
							onClick={() => {
								setSettings(false);
							}}>
							Close Settings
						</Button>
					</div>
				) : (
					<Button
						type='save'
						onClick={() => {
							setSettings(true);
						}}>
						Settings
					</Button>
				)}

				<Button type='print' tableRef={ref.current} className='text-xs font-semibold'>
					Print
				</Button>
				<Button type='pdf' tableRef={ref.current} className='text-xs font-semibold' pdfId='123' orientation={'l'}>
					PDF
				</Button>

				<Button type='close' onClick={() => setTcView(false)}>
					Close
				</Button>
			</div>
			<div className='p-0 flex justify-between h-full w-full'>
				<section ref={ref} className='p-0 m-0 flex justify-center print:flex print:justify-start items-center w-auto printable relative' id='123'>
					<div
						style={{
							padding: customStyles?.padding,
							margin: customStyles?.margin,
							width: customStyles?.width,
							height: customStyles?.height,
							fontFamily: customStyles?.fontFamily,
							zIndex: 1000,
						}}
						className={` screen:shadow-lg relative  overflow-y-scroll  print:w-full print:h-full bg-white`}>
						<style>
							{`
							  .printable {
								box-sizing: border-box;
							  }
							  @media print {
								@page {
								  size: ${'A4'} ${'landscape'};
								  margin: 0;
								}
								.printable {
								  width: 100%
								  height: 98%
								  padding: ${customStyles?.padding};
								  margin: ${customStyles?.margin};
								}
							  }
							`}
						</style>
						<div style={{ marginTop: customStyles?.marginTop }}></div>
						{selectedFields?.headerLogo && <img src={logo} alt='Logo' style={{ position: 'absolute', top: customStyles?.top, left: customStyles?.left, width: customStyles?.logoSize, height: customStyles?.logoSize, zIndex: 2 }} className=' opacity-90' />}
						{selectedFields?.watermarkLogo && <img src={logo} alt='Logo' style={{ position: 'absolute', top: customStyles?.watermarkTop, left: customStyles?.watermarkLeft, width: customStyles?.watermarkSize, height: customStyles?.watermarkSize, opacity: customStyles?.watermarkOpacity, zIndex: 1 }} />}
						<div className='text-center mb-6 relative z-30'>
							{customHeaders?.map((head) => (
								<React.Fragment key={head.key}>
									{head.checked === true && (
										<p
											style={{
												fontSize: `${head?.style?.find((s) => s.key === 'fontSize')?.value}px`,
												lineHeight: `${head?.style?.find((s) => s.key === 'lineHeight')?.value}px`,
												fontWeight: head?.style?.find((s) => s.key === 'fontWeight')?.value || 'normal',
												textDecoration: head?.style?.find((s) => s.key === 'textDecoration')?.value || 'none',
												color: head?.style?.find((s) => s.key === 'color')?.value || 'none',
											}}
											className=''>
											{head?.value}
										</p>
									)}
								</React.Fragment>
							))}
						</div>

						<div style={{ fontSize: customStyles?.fontSize, gap: customStyles?.gap, zIndex: 1000, position: 'relative' }}>
							<div className='flex justify-between mb-2 ' style={{ gap: customStyles?.gap, fontSize: customStyles?.fontSize }}>
								<p className='font-semibold'>
									<span style={{ fontSize: customStyles?.tcNoDateFontSize, lineHeight: customStyles?.tcNoDateLineHeight, fontWeight: customStyles?.tcNoDateFontWeight }}>{tcNumberText ?? 'TC Number'}: </span>
									{profile?.tcBatchNumber && <span style={{ fontSize: customStyles?.tcNoDateFontSize, lineHeight: customStyles?.tcNoDateLineHeight, fontWeight: customStyles?.tcNoDateFontWeight }}>{profile?.tcBatchNumber + '/'}</span>} <span style={{ fontSize: customStyles?.tcNoDateFontSize, lineHeight: customStyles?.tcNoDateLineHeight, fontWeight: customStyles?.tcNoDateFontWeight }}>{profile?.tcNumber}</span>
								</p>
								<p className='font-semibold'>
									<span style={{ fontSize: customStyles?.tcNoDateFontSize, lineHeight: customStyles?.tcNoDateLineHeight, fontWeight: customStyles?.tcNoDateFontWeight }}>Date: </span> {profile?.tcDate ? <span style={{ fontSize: customStyles?.tcNoDateFontSize, lineHeight: customStyles?.tcNoDateLineHeight, fontWeight: customStyles?.tcNoDateFontWeight }}>{profile?.tcDate && formatCurrentDate(profile[customTcDateKey] ?? profile?.tcDate, selectedDateFormat)}</span> : <span style={{ fontSize: customStyles?.fontSize, fontWeight: 'lighter' }}>.............................</span>}
								</p>
							</div>
							{sections
								?.filter((field) => field && selectedFields?.[field?.keyName])
								.map((field, index) => (
									<div key={field?.keyName} style={{ display: 'grid', gridTemplateColumns: '1fr 0 1fr', padding: '0', margin: '0' }}>
										<div className='flex items-start p-0 m-0'>
											<span className='pr-1 ' style={{ fontWeight: customStyles.bodyFieldFontWeight, fontSize: customStyles?.fontSize, lineHeight: customStyles?.lineHeight }}>
												{index + 1 + '.'}
											</span>
											<span className='' style={{ fontWeight: customStyles.bodyFieldFontWeight, fontSize: customStyles?.fontSize, lineHeight: customStyles?.lineHeight }}>
												{field?.title}
											</span>
										</div>
										<p style={{ fontSize: customStyles?.fontSize, fontWeight: customStyles.bodyFieldFontWeight, lineHeight: customStyles?.lineHeight }}>:</p>
										<p style={{ fontSize: customStyles?.fontSize, fontWeight: customStyles.bodyValueFontWeight, lineHeight: customStyles?.lineHeight, paddingLeft: '14px' }}>{profile && (profile[field?.keyName] || profile[field?.keyName.slice(1)]) ? (field?.type === 'date' ? formatCurrentDate(profile[field?.keyName], selectedDateFormat) : field?.type === 'dateToWords' ? convertDateToWords(profile[field.keyName.slice(1)]) : profile[field?.keyName]) : field?.value}</p>
									</div>
								))}
							{customFields
								?.filter((field) => field && field.checked)
								.map((field, index) => (
									<div key={field?.key} style={{ display: 'grid', gridTemplateColumns: '1fr 0 1fr', padding: '0', margin: '0' }}>
										<div className='flex items-start p-0 m-0'>
											<span className='pr-1 ' style={{ fontWeight: customStyles.bodyFieldFontWeight, fontSize: customStyles?.fontSize, lineHeight: customStyles?.lineHeight }}>
												{sections.length + index + 1 + '.'}
											</span>
											<span className='' style={{ fontWeight: customStyles.bodyFieldFontWeight, fontSize: customStyles?.fontSize, lineHeight: customStyles?.lineHeight }}>
												{field?.title}
											</span>
										</div>
										<p style={{ fontSize: customStyles?.fontSize, fontWeight: customStyles.bodyFieldFontWeight, lineHeight: customStyles?.lineHeight }}>:</p>
										<p style={{ fontSize: customStyles?.fontSize, fontWeight: customStyles.bodyValueFontWeight, lineHeight: customStyles?.lineHeight, paddingLeft: '14px' }}>{profile && profile[field?.keyName] ? (field?.type === 'date' ? formatCurrentDate(profile[field?.keyName], selectedDateFormat) : profile[field?.keyName]) : field?.value}</p>
									</div>
								))}
						</div>

						{selectedFields?.signatureText && (
							<div className='text-right mt-2'>
								<div className='flex justify-end'>
									<p style={{ fontSize: customStyles?.fontSize, fontWeight: 'bold' }}>{signatureText}</p>
								</div>
							</div>
						)}
						{selectedFields?.signature && (
							<div className='text-right mt-2'>
								<div className='text-right'>
									<div className='border-t border-gray-800 mt-2 w-40 h-12 ml-auto'>{signature && <img src={signature} alt='Signature' className='h-full w-full object-contain' />}</div>
								</div>
							</div>
						)}
						{selectedFields?.seal && (
							<div className='text-right mt-2'>
								<div className='w-40 h-28 border border-gray-400 mt-2 ml-auto'>{seal && <img src={seal} alt='Seal' className='h-full w-full object-contain' />}</div>
							</div>
						)}
					</div>
					{customStyles?.copies && (
						<div
							style={{
								padding: customStyles?.padding,
								margin: customStyles?.margin,
								width: customStyles?.width,
								height: customStyles?.height,
								fontFamily: customStyles?.fontFamily,
								zIndex: 1000,
								position: 'relative',
							}}
							className={` screen:shadow-lg relative  overflow-y-scroll  print:w-full print:h-full bg-white`}>
							<style>
								{`
			  .printable {
				box-sizing: border-box;
			  }
			  @media print {
				@page {
					size: ${'A4'} ${'landscape'};
					margin: 0;
				}
				.printable {
				  width: 100%
				  height: 98%
				  padding: ${customStyles?.padding};
				  margin: ${customStyles?.margin};
				}
			  }
			`}
							</style>
							<div style={{ marginTop: customStyles?.marginTop }}></div>
							{selectedFields?.headerLogo && <img src={logo} alt='Logo' style={{ position: 'absolute', top: customStyles?.top, left: customStyles?.left, width: customStyles?.logoSize, height: customStyles?.logoSize, zIndex: 2 }} className=' opacity-90' />}
							{selectedFields?.watermarkLogo && <img src={logo} alt='Logo' style={{ position: 'absolute', top: customStyles?.watermarkTop, left: customStyles?.watermarkLeft, width: customStyles?.watermarkSize, height: customStyles?.watermarkSize, opacity: customStyles?.watermarkOpacity, zIndex: 1 }} />}
							<div className='text-center mb-6 relative z-30'>
								{customHeaders.map((head) => (
									<React.Fragment key={head.key}>
										{head.checked === true && (
											<p
												style={{
													fontSize: `${head?.style?.find((s) => s.key === 'fontSize')?.value}px`,
													lineHeight: `${head?.style?.find((s) => s.key === 'lineHeight')?.value}px`,
													fontWeight: head?.style?.find((s) => s.key === 'fontWeight')?.value || 'normal',
													textDecoration: head?.style?.find((s) => s.key === 'textDecoration')?.value || 'none',
													color: head?.style?.find((s) => s.key === 'color')?.value || 'none',
												}}
												className=''>
												{head?.value}
											</p>
										)}
									</React.Fragment>
								))}
							</div>
							<div style={{ fontSize: customStyles?.fontSize, gap: customStyles?.gap, zIndex: 1000, position: 'relative' }}>
								<div className='flex justify-between mb-2 ' style={{ gap: customStyles?.gap, fontSize: customStyles?.fontSize }}>
									<p className='font-semibold'>
										<span style={{ fontSize: customStyles?.tcNoDateFontSize, lineHeight: customStyles?.tcNoDateLineHeight, fontWeight: customStyles?.tcNoDateFontWeight }}>{tcNumberText ?? 'TC Number'}: </span>
										{profile?.tcBatchNumber && <span style={{ fontSize: customStyles?.tcNoDateFontSize, lineHeight: customStyles?.tcNoDateLineHeight, fontWeight: customStyles?.tcNoDateFontWeight }}>{profile?.tcBatchNumber + '/'}</span>} <span style={{ fontSize: customStyles?.tcNoDateFontSize, lineHeight: customStyles?.tcNoDateLineHeight, fontWeight: customStyles?.tcNoDateFontWeight }}>{profile?.tcNumber}</span>
									</p>
									<p className='font-semibold'>
										<span style={{ fontSize: customStyles?.tcNoDateFontSize, lineHeight: customStyles?.tcNoDateLineHeight, fontWeight: customStyles?.tcNoDateFontWeight }}>Date: </span> {profile?.tcDate ? <span style={{ fontSize: customStyles?.tcNoDateFontSize, lineHeight: customStyles?.tcNoDateLineHeight, fontWeight: customStyles?.tcNoDateFontWeight }}>{profile?.tcDate && formatCurrentDate(profile[customTcDateKey] ?? profile?.tcDate, selectedDateFormat)}</span> : <span style={{ fontSize: customStyles?.fontSize, fontWeight: 'lighter' }}>.............................</span>}
									</p>
								</div>
								{sections
									?.filter((field) => field && selectedFields?.[field?.keyName])
									.map((field, index) => (
										<div key={field?.keyName} style={{ display: 'grid', gridTemplateColumns: '1fr 0 1fr', padding: '0', margin: '0' }}>
											<div className='flex items-start p-0 m-0'>
												<span className='pr-1 ' style={{ fontWeight: customStyles.bodyFieldFontWeight, fontSize: customStyles?.fontSize, lineHeight: customStyles?.lineHeight }}>
													{index + 1 + '.'}
												</span>
												<span className='' style={{ fontWeight: customStyles.bodyFieldFontWeight, fontSize: customStyles?.fontSize, lineHeight: customStyles?.lineHeight }}>
													{field?.title}
												</span>
											</div>
											<p style={{ fontSize: customStyles?.fontSize, fontWeight: customStyles.bodyFieldFontWeight, lineHeight: customStyles?.lineHeight }}>:</p>
											<p style={{ fontSize: customStyles?.fontSize, fontWeight: customStyles.bodyValueFontWeight, lineHeight: customStyles?.lineHeight, paddingLeft: '14px' }}>{profile && (profile[field?.keyName] || profile[field?.keyName.slice(1)]) ? (field?.type === 'date' ? formatCurrentDate(profile[field?.keyName], selectedDateFormat) : field?.type === 'dateToWords' ? convertDateToWords(profile[field.keyName.slice(1)]) : profile[field?.keyName]) : field?.value}</p>
										</div>
									))}
								{customFields
									?.filter((field) => field && field.checked)
									.map((field, index) => (
										<div key={field?.key} style={{ display: 'grid', gridTemplateColumns: '1fr 0 1fr', padding: '0', margin: '0' }}>
											<div className='flex items-start p-0 m-0'>
												<span className='pr-1 ' style={{ fontWeight: customStyles.bodyFieldFontWeight, fontSize: customStyles?.fontSize, lineHeight: customStyles?.lineHeight }}>
													{sections.length + index + 1 + '.'}
												</span>
												<span className='' style={{ fontWeight: customStyles.bodyFieldFontWeight, fontSize: customStyles?.fontSize, lineHeight: customStyles?.lineHeight }}>
													{field?.title}
												</span>
											</div>
											<p style={{ fontSize: customStyles?.fontSize, fontWeight: customStyles.bodyFieldFontWeight, lineHeight: customStyles?.lineHeight }}>:</p>
											<p style={{ fontSize: customStyles?.fontSize, fontWeight: customStyles.bodyValueFontWeight, lineHeight: customStyles?.lineHeight, paddingLeft: '14px' }}>{profile && profile[field?.keyName] ? (field?.type === 'date' ? formatCurrentDate(profile[field?.keyName], selectedDateFormat) : profile[field?.keyName]) : field?.value}</p>
										</div>
									))}
							</div>
							{selectedFields?.signatureText && (
								<div className='text-right mt-2'>
									<div className='text-right'>
										<p style={{ fontSize: customStyles?.fontSize, fontWeight: 'bold' }}>{signatureText}</p>
									</div>
								</div>
							)}
							{selectedFields?.signature && (
								<div className='text-right mt-2'>
									<div className='text-right'>
										<div className='border-t border-gray-800 mt-2 w-40 h-12 ml-auto'>{signature && <img src={signature} alt='Signature' className='h-full w-full object-contain' />}</div>
									</div>
								</div>
							)}
							{selectedFields?.seal && (
								<div className='text-right mt-2'>
									<div className='w-40 h-28 border border-gray-400 mt-2 ml-auto'>{seal && <img src={seal} alt='Seal' className='h-full w-full object-contain' />}</div>
								</div>
							)}
						</div>
					)}
				</section>
				{settings && (
					<section className='mb-8  min-w-[30%] h-full overflow-y-scroll bg-gray-200 p-3 rounded-md w-[40%] shadow-md'>
						<div className='bg-gray-600 text-white py-4 px-6 mb-8 shadow-md rounded-t-md'>
							<h2 className='text-2xl font-bold text-white'>Customize Transfer Certificate</h2>
						</div>

						<div className='grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 gap-2'>
							<div className='bg-white border border-gray-300 p-2 rounded-lg shadow flex flex-col justify-between'>
								<label className='block text-left text-sm mb-2'>Upload Logo/Watermark:</label>
								<input type='file' name='headerLogo' onChange={handleLogoUpload} className='block w-full text-sm' />
								{/* {logo ? <p className='text-green-600 text-xs mt-1'>Logo uploaded successfully.</p> : <p>{''}</p>} */}
							</div>

							<div className='bg-white border border-gray-300 p-2 rounded-lg shadow flex flex-col justify-between'>
								<label className='block text-left text-sm mb-2'>Upload Seal:</label>
								<input type='file' name='seal' onChange={handleSealUpload} className='block w-full text-sm' />
								{/* {seal ? <p className='text-green-600 text-xs mt-1'>Seal uploaded successfully.</p> : <p>{''}</p>} */}
							</div>

							<div className='bg-white border border-gray-300 p-2 rounded-lg shadow flex flex-col justify-between'>
								<label className='block text-left text-sm mb-2'>Upload Signature:</label>
								<input type='file' name='signature' onChange={handleSignatureUpload} className='block w-full text-sm' />
								{/* {signature ? <p className='text-green-600 text-xs mt-1'>Signature uploaded successfully.</p> : <p>{''}</p>} */}
							</div>
						</div>

						<div className='mt-6 bg-white border border-gray-300 p-6 rounded-lg shadow'>
							<h3 className='text-lg font-semibold mb-4'>Customize Header Layout</h3>
							<div className='grid grid-cols-2 gap-2'>
								{customHeaders?.map((header) => (
									<React.Fragment key={header.key}>
										<div className='bg-gray-200 rounded-md p-2 shadow-md'>
											<span className='font-semibold'>{header.value}</span>
											{header?.style?.map((st, index) => (
												<div key={index} className='mt-2'>
													<label className='block text-sm mb-2'>
														{st?.title}: {st?.value}
													</label>

													{st.key === 'fontWeight' ? (
														<input type='range' min='100' max='900' step='100' value={st?.value} onChange={(e) => handleStyleChange(header.key, st.key, parseInt(e.target.value, 10))} className='w-full' />
													) : st.key === 'textDecoration' ? (
														<select value={st?.value} onChange={(e) => handleStyleChange(header.key, st.key, e.target.value)} className='w-full p-2 rounded-md border'>
															<option value='none'>None</option>
															<option value='underline'>Underline</option>
															<option value='overline'>Overline</option>
															<option value='line-through'>Line-through</option>
														</select>
													) : st.key === 'color' ? (
														<input type='color' value={st?.value} onChange={(e) => handleStyleChange(header.key, st.key, e.target.value)} className='w-full rounded' />
													) : (
														<input type='range' min='0' max='100' value={st?.value} onChange={(e) => handleStyleChange(header.key, st.key, parseInt(e.target.value, 10))} className='w-full' />
													)}
												</div>
											))}
										</div>
									</React.Fragment>
								))}
							</div>

							<h3 className='text-lg font-semibold mb-2 mt-2'>TC Body Layout</h3>
							<div className='grid grid-cols-2 gap-2'>
								<div className='bg-gray-200 rounded-md p-2 shadow-md'>
									<span className='font-semibold'>Logo</span>

									<div>
										<label className='block text-sm mb-2'>Logo Size: {customStyles?.logoSize}</label>
										<input type='range' min='0' max='200' value={parseInt(customStyles?.logoSize, 10)} onChange={(e) => setCustomStyles({ ...customStyles, logoSize: `${e.target.value}px` })} className='w-full' />
									</div>
									<div>
										<label className='block text-sm mb-2'>Logo Top: {customStyles?.top}</label>
										<input type='range' min='0' max='100' value={parseInt(customStyles?.top, 10)} onChange={(e) => setCustomStyles({ ...customStyles, top: `${e.target.value}px` })} className='w-full' />
									</div>
									<div>
										<label className='block text-sm mb-2'>Logo Left: {customStyles?.left}</label>
										<input type='range' min='0' max='100' value={parseInt(customStyles?.left, 10)} onChange={(e) => setCustomStyles({ ...customStyles, left: `${e.target.value}px` })} className='w-full' />
									</div>
								</div>
								<div className='bg-gray-200 rounded-md p-2'>
									<span className='font-semibold'>WaterMark</span>

									<div>
										<label className='block text-sm mb-2'>WaterMark Size: {customStyles?.watermarkSize}</label>
										<input type='range' min='100' max='800' value={parseInt(customStyles?.watermarkSize, 10)} onChange={(e) => setCustomStyles({ ...customStyles, watermarkSize: `${e.target.value}px` })} className='w-full' />
									</div>
									<div>
										<label className='block text-sm mb-2'>WaterMark Top: {customStyles?.watermarkTop}</label>
										<input type='range' min='-100' max='100' value={parseInt(customStyles?.watermarkTop, 10)} onChange={(e) => setCustomStyles({ ...customStyles, watermarkTop: `${e.target.value}%` })} className='w-full' />
									</div>
									<div>
										<label className='block text-sm mb-2'>WaterMark Left: {customStyles?.watermarkLeft}</label>
										<input type='range' min='-100' max='100' value={parseInt(customStyles?.watermarkLeft, 10)} onChange={(e) => setCustomStyles({ ...customStyles, watermarkLeft: `${e.target.value}%` })} className='w-full' />
									</div>
									<div>
										<label className='block text-sm mb-2'>WaterMark Opacity: {customStyles?.watermarkOpacity}</label>
										<input type='range' min='0' max='1' step='0.1' value={parseFloat(customStyles?.watermarkOpacity, 0.1)} onChange={(e) => setCustomStyles({ ...customStyles, watermarkOpacity: `${e.target.value}` })} className='w-full' />
									</div>
								</div>
								<div className='bg-gray-200 rounded-md p-2'>
									<span className='font-semibold'>Tc Number & Date</span>

									<div>
										<label className='block text-sm mb-2'>Font Size: {customStyles?.tcNoDateFontSize}</label>
										<input type='range' min='8' max='36' value={parseInt(customStyles?.tcNoDateFontSize, 10)} onChange={(e) => setCustomStyles({ ...customStyles, tcNoDateFontSize: `${e.target.value}px` })} className='w-full' />
									</div>
									<div>
										<label className='block text-sm mb-2'>Line Height: {customStyles?.tcNoDateLineHeight}</label>
										<input type='range' min='0' max='50' value={parseInt(customStyles?.tcNoDateLineHeight, 10)} onChange={(e) => setCustomStyles({ ...customStyles, tcNoDateLineHeight: `${e.target.value}px` })} className='w-full' />
									</div>
									<div>
										<label className='block text-sm mb-2'>Font Weight: {customStyles?.tcNoDateFontWeight}</label>
										<input type='range' min='100' max='900' step='100' value={parseInt(customStyles?.tcNoDateFontWeight, 10)} onChange={(e) => setCustomStyles({ ...customStyles, tcNoDateFontWeight: `${e.target.value}` })} className='w-full' />
									</div>
								</div>
								<div className='bg-gray-200 rounded-md p-2'>
									<span className='font-semibold'>Tc Body</span>

									<div>
										<label className='block text-sm mb-2'>Padding: {customStyles?.padding}</label>
										<input type='range' min='0' max='50' value={parseInt(customStyles?.padding, 10)} onChange={(e) => setCustomStyles({ ...customStyles, padding: `${e.target.value}px` })} className='w-full' />
									</div>
									<div>
										<label className='block text-sm mb-2'>Margin Top: {customStyles?.marginTop}</label>
										<input type='range' min='0' max='50' value={parseInt(customStyles?.marginTop, 10)} onChange={(e) => setCustomStyles({ ...customStyles, marginTop: `${e.target.value}px` })} className='w-full' />
									</div>

									<div>
										<label className='block text-sm mb-2'>Margin: {customStyles?.margin}</label>
										<input type='range' min='0' max='50' value={parseInt(customStyles?.margin, 10)} onChange={(e) => setCustomStyles({ ...customStyles, margin: `${e.target.value}px` })} className='w-full' />
									</div>

									<div>
										<label className='block text-sm mb-2'>Font Size: {customStyles?.fontSize}</label>
										<input type='range' min='8' max='36' value={parseInt(customStyles?.fontSize, 10)} onChange={(e) => setCustomStyles({ ...customStyles, fontSize: `${e.target.value}px` })} className='w-full' />
									</div>
									<div>
										<label className='block text-sm mb-2'>Line Height: {customStyles?.lineHeight}</label>
										<input type='range' min='0' max='50' value={parseInt(customStyles?.lineHeight, 10)} onChange={(e) => setCustomStyles({ ...customStyles, lineHeight: `${e.target.value}px` })} className='w-full' />
									</div>
									<div>
										<label className='block text-sm mb-2'>Title Font Weight: {customStyles?.bodyFieldFontWeight}</label>
										<input type='range' min='100' max='900' step='100' value={parseInt(customStyles?.bodyFieldFontWeight, 10)} onChange={(e) => setCustomStyles({ ...customStyles, bodyFieldFontWeight: `${e.target.value}` })} className='w-full' />
									</div>
									<div>
										<label className='block text-sm mb-2'>Value Font Weight: {customStyles?.bodyValueFontWeight}</label>
										<input type='range' min='100' max='900' step='100' value={parseInt(customStyles?.bodyValueFontWeight, 10)} onChange={(e) => setCustomStyles({ ...customStyles, bodyValueFontWeight: `${e.target.value}` })} className='w-full' />
									</div>
								</div>
								<div>
									<div className='flex items-center space-x-3 p-2 bg-gray-100 rounded-lg shadow-sm'>
										<label className='text-sm font-medium text-gray-700 pt-1'>Extra Copy:</label>
										<div className='relative'>
											<input
												type='checkbox'
												checked={customStyles?.copies}
												onChange={(e) =>
													setCustomStyles({
														...customStyles,
														copies: e.target.checked,
													})
												}
												className='h-5 w-5 border border-gray-300 rounded-md bg-white text-blue-600 focus:ring-2 focus:ring-blue-500 checked:bg-blue-600 checked:border-transparent transition-all duration-200'
											/>
										</div>
									</div>
								</div>
								<div>
									<label className='text-sm font-medium text-gray-700 pt-1'>Date Format:</label>
									<select value={selectedDateFormat} onChange={handleChange} className='w-full p-2 rounded-md border'>
										{dateFormats.map((format, index) => (
											<option key={index} value={format}>
												{formatCurrentDate(currentDate, format)}
											</option>
										))}
									</select>
								</div>
								<div>
									<label className='text-sm font-medium text-gray-700 pt-1'>Font Family:</label>

									<select
										value={customStyles?.fontFamily || 'Times New Roman'}
										onChange={(e) =>
											setCustomStyles((prevStyles) => ({
												...prevStyles,
												fontFamily: e.target.value,
											}))
										}
										className='w-full p-2 rounded-md border'>
										<option value='Times New Roman'>Times New Roman</option>
										<option value='Arial'>Arial</option>
										<option value='Courier New'>Courier New</option>
										<option value='Georgia'>Georgia</option>
										<option value='Verdana'>Verdana</option>
										<option value='Sans-Serif'>Sans-Serif</option>
									</select>
								</div>
								<div>
									<label className='text-sm font-medium text-gray-700 pt-1'>Tc Date:</label>

									<select value={customTcDateKey} onChange={(e) => setCustomTcDateKey(e.target.value)} className='w-full p-2 rounded-md border'>
										{tcDateKeys.map((x, index) => (
											<option key={index} value={x}>
												{x}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>

						<div className='mt-6 space-y-4'>
							<div className='space-y-2'>
								<div className='mt-6 space-y-2'>
									<div className='bg-white border border-gray-200 px-4 py-3 rounded-lg shadow-md flex justify-between items-center hover:bg-gray-50 transition-colors duration-200'>
										<span className='text-sm font-semibold text-gray-700'>Header Logo</span>
										<input type='checkbox' name='headerLogo' checked={selectedFields?.headerLogo} onChange={handleFieldChange} className='form-checkbox h-4 w-4 text-blue-600 border-2 border-gray-400 rounded focus:ring-blue-500' />
									</div>

									<div className='bg-white border border-gray-200 px-4 py-3 rounded-lg shadow-md flex justify-between items-center hover:bg-gray-50 transition-colors duration-200'>
										<span className='text-sm font-semibold text-gray-700'>WaterMark Logo</span>
										<input type='checkbox' name='watermarkLogo' checked={selectedFields?.watermarkLogo} onChange={handleFieldChange} className='form-checkbox h-4 w-4 text-blue-600 border-2 border-gray-400 rounded focus:ring-blue-500' />
									</div>
									<DragDropContext onDragEnd={handleDragEndHeader}>
										<Droppable droppableId='droppable'>
											{(provided) => (
												<div {...provided.droppableProps} ref={provided.innerRef}>
													{customHeaders.map((item, index) => (
														<Draggable key={item.key} draggableId={item.key} index={index}>
															{(provided) => (
																<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className='bg-white border border-gray-200 px-4 py-3 rounded-lg shadow-md flex justify-between items-center hover:bg-gray-50 transition-colors duration-200 my-1'>
																	{editingKey === item.key ? (
																		<input type='text' value={editedValue} onChange={(e) => setEditedValue(e.target.value)} onBlur={() => saveEdit(item.key)} className='border rounded p-2 w-full text-sm text-gray-700' />
																	) : (
																		<div className='flex justify-between items-center w-full'>
																			<span className='text-sm font-semibold text-gray-700' onClick={() => handleEdit(item.key, item.value)}>
																				{item.value ?? `Add ${item.key} details`}
																			</span>
																			<span className='px-2 cursor-pointer' onClick={() => deleteHeaders(item.key)}>
																				<MdDelete />
																			</span>
																		</div>
																	)}

																	<input type='checkbox' name='address' checked={item?.checked || false} onChange={() => setCustomHeaders((prevHeaders) => prevHeaders.map((header, i) => (i === index ? { ...header, checked: !header.checked } : header)))} className='form-checkbox h-4 w-4 text-blue-600 border-2 border-gray-400 rounded focus:ring-blue-500 mt-3' />
																</div>
															)}
														</Draggable>
													))}
													{provided.placeholder}
												</div>
											)}
										</Droppable>
									</DragDropContext>
									<div className='mb-2'>
										{addHeader ? (
											<>
												<input type='text' value={newHeaderTitle} onChange={(e) => setNewHeaderTitle(e.target.value)} placeholder='Enter new header title' className='border rounded p-2 mr-2 w-full text-sm text-gray-700' />
												<div className='flex justify-around p-2'>
													<button onClick={addNewHeader} className='bg-blue-500 text-white px-2 py-2 rounded'>
														Add New Header
													</button>
													<button onClick={() => setAddHeader(false)} className='bg-red-400 text-white px-2 py-2 rounded'>
														Cancel
													</button>
												</div>
											</>
										) : (
											<div className='text-center'>
												<span className='text-lg' onClick={() => setAddHeader(true)}>
													<MdAddBox />
												</span>
											</div>
										)}
									</div>

									<div className='bg-white border border-gray-200 px-4 py-3 rounded-lg shadow-md flex justify-between items-center hover:bg-gray-50 transition-colors duration-200'>
										{tcNumberTextEdit ? (
											<input type='text' name='signatureText' value={tcNumberText} onChange={(e) => setTcNumberText(e.target.value)} onBlur={() => setTcNumberTextedit(false)} className='border rounded p-2 text-sm text-gray-700 w-full' />
										) : (
											<span className='text-sm font-semibold text-gray-700' onClick={() => setTcNumberTextedit(true)}>
												{tcNumberText}
											</span>
										)}
									</div>

									<DragDropContext onDragEnd={handleDragEnd}>
										<Droppable droppableId='droppable'>
											{(provided) => (
												<div {...provided.droppableProps} ref={provided.innerRef} className='mt-4 pt-1'>
													{sections?.map((field, index) => (
														<Draggable key={field?.keyName || `default-${index}`} draggableId={field?.keyName || `default-${index}`} index={index}>
															{(provided) => (
																<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className='bg-white border border-gray-200 px-4 py-3 my-1 rounded-lg shadow-md flex justify-between items-center hover:bg-gray-50 transition-colors duration-200'>
																	{editingField === field?.keyName ? (
																		<input
																			type='text'
																			value={editedTitle}
																			onChange={(e) => {
																				setEditedTitle(e.target.value);
																			}}
																			onBlur={() => saveTitleEdit(field?.keyName)}
																			className='border rounded p-2 text-sm text-gray-700 w-full'
																		/>
																	) : (
																		<span className='text-sm font-semibold text-gray-700  cursor-pointer' onClick={(e) => handleTitleEdit(field)}>
																			{field?.title || 'Default Title'}
																		</span>
																	)}

																	<div className='flex justify-between items-center gap-1'>
																		<div className='ml-4 '>
																			{editingFieldKey === field.keyName ? (
																				<>
																					<input
																						type='text'
																						value={field.value || ''}
																						onChange={(e) => {
																							handleValueEdit(field.keyName, e.target.value);
																						}}
																						onBlur={() => setEditingFieldKey(null)}
																						className='border rounded p-2 text-sm text-gray-700 w-24'
																					/>
																				</>
																			) : (
																				<div className='flex justify-between items-center'>
																					<span className='text-md font-semibold text-gray-700 cursor-pointer' onClick={() => setEditingFieldKey(field.keyName)}>
																						<GrFormEdit />
																					</span>
																					<span className='px-2 cursor-pointer' onClick={() => deleteSections(field.keyName)}>
																						<MdDelete />
																					</span>
																				</div>
																			)}
																		</div>
																		<input type='checkbox' name={field?.keyName || `default-${index}`} checked={selectedFields[field?.keyName] ?? false} onChange={handleFieldChange} className='form-checkbox h-4 w-4 text-blue-600 border-2 border-gray-400 rounded focus:ring-blue-500 mt-3' />
																	</div>
																</div>
															)}
														</Draggable>
													))}
													{provided.placeholder}
												</div>
											)}
										</Droppable>
									</DragDropContext>

									<DragDropContext onDragEnd={handleDragEndCustomField}>
										<Droppable droppableId='droppable'>
											{(provided) => (
												<div {...provided.droppableProps} ref={provided.innerRef}>
													{customFields.map((item, index) => (
														<Draggable key={item.key} draggableId={item.key} index={index}>
															{(provided) => (
																<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className='bg-white border border-gray-200 px-4 py-3 rounded-lg shadow-md flex justify-between items-center hover:bg-gray-50 transition-colors duration-200 my-1'>
																	{
																		<div className='flex justify-between items-center w-full'>
																			<span className='text-sm font-semibold text-gray-700'>{item.title}</span>
																			<span className='px-2 cursor-pointer' onClick={() => deleteCustomField(item.key)}>
																				<MdDelete />
																			</span>
																		</div>
																	}

																	<input type='checkbox' name='address' checked={item?.checked || false} onChange={() => setCustomFields((prv) => prv.map((field, i) => (i === index ? { ...field, checked: !field.checked } : field)))} className='form-checkbox h-4 w-4 text-blue-600 border-2 border-gray-400 rounded focus:ring-blue-500' />
																</div>
															)}
														</Draggable>
													))}
													{provided.placeholder}
												</div>
											)}
										</Droppable>
									</DragDropContext>

									{addNew ? (
										<div className='mt-4 p-4 bg-white border border-gray-300 rounded-lg shadow'>
											<div className='grid grid-cols-2 gap-4'>
												<label className='block'>
													Key Name:
													<select name='keyName' value={newSection?.keyName} onChange={(e) => setNewSection({ ...newSection, keyName: e.target.value })} className='border rounded p-2 w-full'>
														<option value='' disabled>
															Select a key name
														</option>
														{fieldsData?.map((option) => (
															<option key={option?.keyName} value={option?.keyName}>
																{option?.title}
															</option>
														))}
													</select>
												</label>
												<label className='block'>
													Data Type:
													<Select options={options} onChange={handleOptionChange} placeholder='Select a key name' isSearchable value={options.find((option) => option.value === newSection?.keyName)} />
												</label>

												<label className='block'>
													Title:
													<input type='text' name='title' value={newSection?.title} onChange={(e) => setNewSection({ ...newSection, title: e.target.value })} className='border rounded p-2 w-full' placeholder='Enter title' />
												</label>
												<label className='block'>
													Default value, if:
													<input type='text' name='title' value={newSection?.value} onChange={(e) => setNewSection({ ...newSection, value: e.target.value })} className='border rounded p-2 w-full' placeholder='Enter Value' />
												</label>
											</div>

											<div className='flex gap-4'>
												<button
													type='button'
													className='mt-4 bg-blue-500 text-white p-2 rounded hover:bg-blue-600'
													onClick={() => {
														setSections((prevSections) => [...prevSections, { ...newSection, value: '' }]);
														setNewSection({ keyName: '', title: '', longTitle: '', type: '' });
														setAddNew(false);
													}}>
													Add Field
												</button>
												<button
													type='button'
													className='mt-4 bg-red-500 text-white p-2 rounded hover:bg-red-600'
													onClick={() => {
														setNewSection({ keyName: '', title: '', longTitle: '', type: '' });
														setAddNew(false);
													}}>
													Cancel
												</button>
											</div>
										</div>
									) : (
										<div className='flex justify-center items-center'>
											<Button type='submit' onClick={() => setAddNew(true)} className='mt-4 bg-blue-500 text-white p-2 rounded hover:bg-blue-600'>
												Add Dynamic Field
											</Button>
										</div>
									)}

									{addCfield ? (
										<div>
											<label className='block'>
												Title:
												<input type='text' name='title' value={newCustomField?.title} onChange={(e) => setNewCustomField({ ...newCustomField, title: e.target.value })} className='border rounded p-2 w-full' placeholder='Enter title' />
											</label>
											<label className='block'>
												Value
												<input type='text' name='title' value={newCustomField?.value} onChange={(e) => setNewCustomField({ ...newCustomField, value: e.target.value })} className='border rounded p-2 w-full' placeholder='Enter Value' />
											</label>
											<div className='flex justify-center items-center gap-4'>
												<button
													type='button'
													className='mt-4 bg-blue-500 text-white p-2 rounded hover:bg-blue-600'
													onClick={() => {
														addCustomFields();
													}}>
													Add Custom Field
												</button>
												<button
													type='button'
													className='mt-4 bg-red-500 text-white p-2 rounded hover:bg-red-600'
													onClick={() => {
														setAddCField(false);
													}}>
													Cancel
												</button>
												{/* <Button type='submit' onClick={() => addCustomFields()} className='mt-4 bg-blue-500 text-white p-2 rounded hover:bg-blue-600'>
											Add Custom Field
										</Button> */}
											</div>
										</div>
									) : (
										<div className='flex justify-center items-center'>
											<Button type='submit' onClick={() => setAddCField(true)} className='mt-4 bg-blue-500 text-white p-2 rounded hover:bg-blue-600'>
												Add Custom Field
											</Button>
										</div>
									)}

									<div className='bg-white border border-gray-200 px-4 py-3 rounded-lg shadow-md flex justify-between items-center hover:bg-gray-50 transition-colors duration-200'>
										{signatureTextEdit ? (
											<input type='text' name='signatureText' value={signatureText} onChange={(e) => setSignatureText(e.target.value)} onBlur={() => setSignatureTextEdit(false)} className='border rounded p-2 text-sm text-gray-700 w-full' />
										) : (
											<span className='text-sm font-semibold text-gray-700' onClick={() => setSignatureTextEdit(true)}>
												{signatureText}
											</span>
										)}
										<input type='checkbox' name='signatureText' checked={selectedFields?.signatureText} onChange={handleFieldChange} className='form-checkbox h-4 w-4 text-blue-600 border-2 border-gray-400 rounded focus:ring-blue-500' />
									</div>
									<div className='bg-white border border-gray-200 px-4 py-3 rounded-lg shadow-md flex justify-between items-center hover:bg-gray-50 transition-colors duration-200'>
										<span className='text-sm font-semibold text-gray-700'>Signature</span>
										<input type='checkbox' name='signature' checked={selectedFields?.signature} onChange={handleFieldChange} className='form-checkbox h-4 w-4 text-blue-600 border-2 border-gray-400 rounded focus:ring-blue-500' />
									</div>

									<div className='bg-white border border-gray-200 px-4 py-3 rounded-lg shadow-md flex justify-between items-center hover:bg-gray-50 transition-colors duration-200'>
										<span className='text-sm font-semibold text-gray-700'>College Seal</span>
										<input type='checkbox' name='seal' checked={selectedFields?.seal} onChange={handleFieldChange} className='form-checkbox h-4 w-4 text-blue-600 border-2 border-gray-400 rounded focus:ring-blue-500' />
									</div>
								</div>
							</div>
						</div>
					</section>
				)}
			</div>
		</Main>
	);
}

export default TCView2;
