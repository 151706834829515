// import React, { useContext, useLayoutEffect, useState } from "react";
// import styles from "./Login.module.scss";
// import SignIn from "../../components/SignIn";
// import SignUpCollege from "../../components/SignUpCollege";
// import Home from '../Website/home';
// import { useHistory } from 'react-router-dom';
// import Loader from "./Loader";
// import { FirebaseContext } from "../../context/FirebaseContext";

// function Login() {
//   const [signIn, setSignIn] = useState(true);
//   const [] = useState(false);
//   const history = useHistory();

//   const { userLoading } = useContext(FirebaseContext);

//   useLayoutEffect(() => {
//     document.body.style.overflow = "hidden"
//     return (() => {
//       document.body.style.overflow = "auto"
//     })
//   }, [])
//   return (
//     <>

//       {userLoading &&

//         <Loader />
//       }

//       <Home />

//       <div className={styles.loginPage}>

//         <span className={styles.heading}>Welcome Back</span>

//         <div className={styles.signInSignupWrap}>

//           {signIn ? (
//             <SignIn setSignIn={setSignIn} signIn={signIn} />
//           ) : (
//             <SignUpCollege onSuccess={() => setSignIn(true)} />
//           )}

//         </div>
//         <div><button onClick={() => history.push("/home")} className={styles.btnSendHome} >Home</button></div>
//       </div>

//     </>

//   );
// }

// export default Login;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getAxiosInstance } from '../../utils/axiosInstance';
import Loader from '../../NecttosComp/Loaders/Loader';
import QRCode from 'react-qr-code';
import { v4 as uuidv4 } from 'uuid';
import { socket } from '../../config';
import Cookies from 'js-cookie';

interface UserDetails {
	phoneNumber?: string;
	username?: string;
	uid?: string;
	displayName?: string;
}

interface QrCodeData {
	userDetails: UserDetails;
	token: string;
	collegeId: string;
}

function Login() {
	const [isSendOtp, setSendOtp] = useState<number>(0);
	const [isLoading, setIsLoading] = useState(false);
	const [otp, setOtp] = useState(['', '', '', '', '', '']);
	const [confirm, setConfirm] = useState<any>();
	const [filledData, setFilledData] = useState({ mobile: '' });
	const [collegeData, setCollegeData] = useState<any>();
	const { collegeList, setCollegeId } = useContext(FirebaseContext);
	const history = useHistory();
	const [qrCode, setQRCode] = useState(`NECTTOS_QR_${uuidv4()}`);

	const inputRefs: any = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

	const handleInputChange = (index: any, e: any) => {
		if (e.target.value && index < inputRefs.length - 1) {
			inputRefs[index + 1]?.current?.focus();
		}
		let temp = [...otp];
		temp[index] = e.target.value;
		setOtp([...temp]);
		if (index === 5) {
			verfiyOtp(temp.join(''));
		}
	};

	useEffect(() => {
		const token = Cookies.get('userToken');
		if (token) {
			const user = localStorage.getItem('userDetails');
			if (user) {
				if (collegeList?.length === 1) history.push('/app');
				else history.push('/college-list');
			}
		}
	}, []);

	useEffect(() => {
		const handleQrCode = ({ userDetails, token, collegeId }: QrCodeData) => {
			Cookies.set('userToken', token, { expires: 365 * 10, sameSite: 'strict', secure: true });
			localStorage.setItem(
				'userDetails',
				JSON.stringify({
					phoneNumber: userDetails?.phoneNumber,
					username: userDetails?.username,
					displayName: userDetails?.displayName,
					uid: userDetails?.uid,
				}),
			);
			localStorage.setItem('collegeId', JSON.stringify(collegeId));
			setCollegeId(collegeId);
			toast.success('Login Successfully');
			window.location.reload();
		};
		socket.on(qrCode, handleQrCode);
		return () => {
			socket.off(qrCode, handleQrCode);
			socket.disconnect();
		};
	}, []);

	const verfiyOtp = async (otp: string) => {
		setIsLoading(true);
		const instance = await getAxiosInstance();
		instance
			.post(`/auth/verify-otp`, { token: confirm, otp })
			.then(async (response) => {
				Cookies.set('userToken', response.data.jwtToken, { expires: 365 * 10, sameSite: 'strict', secure: true });

				console.log(response.data);

				localStorage.setItem(
					'userDetails',
					JSON.stringify({
						phoneNumber: response.data.phoneNumber,
						username: response.data.userName,
						displayName: response.data.userName,
						uid: response.data.uid,
					}),
				);
				setIsLoading(false);
				if (collegeList?.length === 1) history.push('/app');
				else history.push('/college-list');
			})
			.catch((err) => {
				console.log(err);
				toast.error(err.message);
				setIsLoading(false);
			});
	};

	const login = async () => {
		const instance = await getAxiosInstance();
		if (filledData.mobile) {
			instance
				.post(`/auth/sent-otp`, {
					phoneNumber: `+91${filledData.mobile}`,
				})
				.then((response) => {
					const elem = document.getElementById('loginForm');
					if (elem) {
						elem.style.height = '400px';
						const otp = document.getElementById('otp');
						if (otp) {
							otp.style.display = 'flex';
						}
					}
					let second = 300;
					let intervalId = setInterval(() => {
						second = second - 1;
						setSendOtp(second);
						if (second <= 0) {
							clearInterval(intervalId);
						}
					}, 1000);
					setConfirm(response.data.token);
					console.log(response);
					toast.success('OTP Sended');
				})
				.catch((err) => {
					toast.error('Your Not authorized ');
				});
		} else {
			toast.error('Please give Phone number');
		}
	};

	return (
		<>
			<div className='min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-blue-500 to-blue-800 p-4'>
				<Toaster />

				<div className='flex flex-col sm:flex-row items-center justify-center bg-white bg-opacity-10 backdrop-blur-md rounded-xl shadow-lg w-full sm:w-[800px] p-6'>
					<div className='flex flex-col items-center justify-center text-center sm:w-1/2 p-4'>
						<h2 className='text-white text-xl font-bold mb-4'>Login with QR Code</h2>
						<QRCode size={200} className='mb-4' value={qrCode || 'https://example.com'} viewBox={`0 0 256 256`} />
						<p className='text-gray-300 text-sm'>Scan the QR code to log in using your mobile app.</p>
					</div>

					<div id='loginForm' className='flex flex-col bg-white bg-opacity-20 backdrop-blur-lg rounded-xl p-6 sm:w-1/2'>
						<img src='https://firebasestorage.googleapis.com/v0/b/necttos-production.appspot.com/o/Screenshot%202024-07-29%20at%2010.14.17%20AM.png?alt=media&token=15b88553-3b1c-4d7c-94af-7a6a4baf1046' className='w-24 h-24  text-center' />
						<h2 className='text-blue-600 text-2xl font-bold text-center mb-6'>Login</h2>
						<div className='space-y-4'>
							<div className='relative flex items-center border border-blue-300 rounded-full px-4 py-2 bg-transparent'>
								<input type='text' value={filledData.mobile} placeholder='Enter Phone Number' onChange={(e) => setFilledData({ ...filledData, mobile: e.target.value })} className='flex-grow bg-transparenttext-blue-900 outline-none placeholder-gray-400 ' aria-label='Phone Number' />
								<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='currentColor' className='text-blue-300 ml-3' viewBox='0 0 16 16'>
									<path d='M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z' />
								</svg>
							</div>

							<button disabled={isSendOtp >= 1} onClick={login} className={`w-full py-2 text-white rounded-full transitiontext-blue-900 ${isSendOtp < 1 ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-400 cursor-not-allowed'}`}>
								{isSendOtp < 1 ? 'Send OTP' : 'OTP Sent'}
							</button>

							{isSendOtp > 0 && (
								<div className='flex justify-between space-x-2'>
									{inputRefs.map((inputRef: any, index: any) => (
										<input
											key={index}
											type='text'
											maxLength={1}
											value={otp[index]}
											onChange={(e) => handleInputChange(index, e)}
											onKeyDown={(e) => {
												if (e.key === 'Backspace' && index > 0) {
													inputRefs[index - 1]?.current?.focus();
												}
											}}
											ref={inputRef}
											className='w-12 h-12 text-center bg-transparent border border-blue-300 rounded text-blue-900 placeholder-gray-400 outline-none focus:ring focus:ring-blue-500'
										/>
									))}
								</div>
							)}
						</div>

						{isSendOtp > 0 && (
							<div className='mt-4 text-centertext-blue-900'>
								<p>
									Time remaining: <span>{isSendOtp}</span>s
								</p>
							</div>
						)}
					</div>
				</div>
				<h6 className='bottom-3text-blue-900 text-sm'>Powered by Necttos</h6>

				{isLoading && <Loader loading={false} />}
			</div>
		</>
	);
}

export default Login;
