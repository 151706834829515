import React, { useContext, useEffect, useRef, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { Table, Tbody, Td, Th, Thead, Tr, useSortableData } from '../../NecttosComp/Table/Table';
import { useQuery } from 'react-query';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosInstance, getAxiosTokenInstance } from '../../utils/axiosInstance';
import moment from 'moment';
import { getDepartmentName, getMinorName } from '../Form/Component';
import StaticAllotment from '../Admission/StaticAllotment';
import ApplicationView from '../Admission/ApplicationView';
import RemoveAction from '../Admission/RemoveAction';
import { getAllMinor } from '../../NecttosComp/APICaller/ApiServices';

const getCollegeDetailsee = async ({ queryKey }: any) => {
	const [_key, collegeId] = queryKey;

	try {
		const instance = await getAxiosInstance();
		const { data } = await instance.get('/college/admissionProcess/getCollegeDetails', {
			params: { collegeId },
		});

		if (data?.statusCode === 200) {
			return data.collegeData;
		} else {
			throw new Error(data?.message || 'Failed to fetch college details.');
		}
	} catch (error) {
		throw new Error('API Error');
	}
};

function RealTimeAllotment({ onClose }: { onClose: () => void }) {
	const { collegeId, user } = useContext(FirebaseContext);
	const [addNew, setAddNew] = useState<any>({});
	const [tableData, setTableData] = useState<any>([]);
	const [statistics, setStatistics] = useState<any>([]);
	const [showAdvance, setAdvance] = useState<boolean>(false);
	const [needWl, setWl] = useState<boolean>(false);
	const [subjectId, setSelectClass] = useState('');
	const [date, setDate] = useState('--:--');
	const [type, setType] = useState('');
	const [newAll, setNewAll] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [catogory, setCatogory] = useState('');
	const [isLoading, setLoading] = useState<boolean>(false);
	const { data = {} } = useQuery('getAllMinor', getAllMinor(collegeId));

	const subjectList = data?.list;
	console.log({ statistics });

	const { data: collegeData } = useQuery([`collegddeData`, collegeId], getCollegeDetailsee);

	const [publishState, setPublish] = useState<any>({});

	const [table, setTable] = useState<any>([]);
	const ref = useRef<any>(null);

	const getAutoIndexdddd = async (uptodated: boolean | null) => {
		setLoading(true);
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.get('/college/fyugp/getRealTimeAllotmentFYUGP', {
				params: {
					collegeId,
					showAdvance,
					needWl,
					uptodated,
					catogory,
					type,
					subjectId,
				},
			});

			if (resp.data.statusCode === 400) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				if (resp?.data?.list?.length > 0) {
					setStatistics(resp.data.statistics);
					setTableData(resp?.data.list);
					setDate(moment(resp?.data?.date).format('hh:mm A (MMM DD)'));
					setNewAll(false);
				} else if (resp.data.statistics) {
					setStatistics(resp.data.statistics);
				} else {
					setTableData([]);
					setNewAll(false);
					setDate(moment(resp?.data?.date).format('hh:mm A (MMM DD)'));
				}
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (type) {
			getAutoIndexdddd(null);
		}
	}, [type]);

	useEffect(() => {
		if (catogory.length > 1) {
			getAutoIndexdddd(null);
		}
	}, [catogory]);

	useEffect(() => {
		if (tableData) {
			let data = tableData;
			if (searchTerm) {
				data = data?.filter((item: any) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
			}
			setTable(data);
		}
	}, [tableData, searchTerm]);

	const { tableItems, requestSort, getIcon } = useSortableData(table);
	const [progress, setProgress] = useState(0);
	const [remainingTime, setRemainingTime] = useState(5 * 60);

	useEffect(() => {
		const interval = setInterval(() => {
			setProgress((prev: any) => {
				if (prev < 1000) {
					return prev + 1;
				} else {
					clearInterval(interval);
					return 100;
				}
			});
			setRemainingTime((prev: any) => {
				if (prev > 0) {
					return prev - 1;
				} else {
					clearInterval(interval);
					return 0;
				}
			});
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	const formatTime = (seconds: number) => {
		const minutes = Math.floor(seconds / 60);
		const secs = seconds % 60;
		return `${minutes} minute(s) and ${secs} second(s)`;
	};
	return isLoading ? (
		<Main title='Real-Time Allotment' height='100%' width='100%'>
			<Header>
				<div className='flex'>
					<Button type='close' onClick={onClose}>
						Close
					</Button>
				</div>
			</Header>

			{!newAll ? (
				<Body>
					<div className='flex flex-col items-center justify-center h-screen text-center bg-gradient-to-b from-blue-800 to-blue-600 text-white overflow-hidden font-sans'>
						<p className='text-6xl font-bold my-10 animate-scroll'>Getting FYUGP allotment List...</p>
						<p className='text-4xl my-10 animate-scroll animation-delay-2000'>Please wait, this process may take some time.</p>
						<p className='text-3xl my-10 animate-scroll animation-delay-4000'>We appreciate your patience as we generate accurate and up-to-date rankings for you.</p>
						<p className='text-3xl my-10 animate-scroll animation-delay-4000'>We strive to save you time and improve your life balance.</p>
					</div>
				</Body>
			) : (
				<Body>
					<div className='flex flex-col items-center justify-center h-screen text-center bg-gradient-to-b from-blue-800 to-blue-600 text-white overflow-hidden font-sans'>
						<p className='text-6xl font-bold my-10 animate-scroll'>Calculating new FYUGP real-time allotment...</p>
						<p className='text-4xl my-10 animate-scroll animation-delay-2000'>Please wait, this process may take some time. Approximately 1,72,34,000 loops are being processed.</p>
						<p className='text-3xl my-10 animate-scroll animation-delay-4000'>We appreciate your patience as we generate accurate and up-to-date rankings for you.</p>
						<p className='text-3xl my-10 animate-scroll animation-delay-4000'>This may take approximately 2 to 5 minutes.</p>
						<p className='text-3xl my-10 animate-scroll animation-delay-4000'>We strive to save you time and improve your life balance.</p>
						<div className='w-full h-4 bg-gray-300 mt-8 rounded'>
							<div className='h-4 bg-green-500 rounded' style={{ width: `${progress}%` }}></div>
						</div>
						<p className='text-xl mt-4'>Estimated time remaining: {formatTime(remainingTime)}</p>
					</div>
				</Body>
			)}
		</Main>
	) : (
		<>
			{publishState?.publish ? (
				<Main title='Real-Time Allotment' height='100%' width='100%'>
					<Header>
						<div className='flex justify-end p-4'>
							<Button type='close' onClick={() => setPublish({ ...publishState, publish: false })}>
								Close
							</Button>
						</div>
					</Header>
					{publishState?.static ? (
						<Body>
							<StaticAllotment ids='sdfnkdjfhw32323sdfsdf' />
						</Body>
					) : (
						<Body>
							<div className='flex flex-col items-center justify-center h-screen text-center bg-gradient-to-b from-blue-800 to-blue-600 text-white font-sans overflow-hidden'>
								<p className='text-6xl font-bold my-4 animate-scroll'>Publishing new Real-Time or Static Allotment...</p>
								<p className='text-4xl my-4 animate-scroll animation-delay-2000'>Please ensure all data is accurate before publishing.</p>
								<p className='text-3xl my-4 animate-scroll animation-delay-4000'>Remember, this is a system-generated report. Any errors in the student mark list will appear here as well.</p>
								<div className='flex space-x-4 mt-8'>
									<Button type='save' width='300px' onClick={() => setPublish({ ...publishState, dynamic: false })}>
										Dynamic Publish
									</Button>
									<Button type='fetch' width='300px' onClick={() => setPublish({ ...publishState, static: true })}>
										Static Publish
									</Button>
								</div>
							</div>
						</Body>
					)}
				</Main>
			) : (
				<Main title={'FYUGP Real-Time Allotment: Total Applications: ' + tableItems?.length} height='100%' width='100%'>
					<Header>
						<div className='flex'>
							<Input fieldName='Filter by Catogory' state={type} setState={setType} options={['DSC_B', 'DSC_C', 'MDC']} type='drop' width='10vw' />
							<Input fieldName='Filter by Course' state={subjectId} setState={setSelectClass} didntShowKey optionDisplay='subjectName' optionKey='_id' options={subjectList?.filter((x: any) => x.type === type) || []} type='drop' width='35vw' />
							<Input fieldName='Filter by Catogory' state={catogory} setState={setCatogory} options={['SC', 'ST', 'MU', 'MUSLIM', 'MGMT', 'MGT', 'MANAGEMENT', 'EWS', 'PWD', 'COMT', 'COMMUNITY', 'SP', 'SPORTS', 'CU', 'CULTURAL']} type='drop' width='15vw' />
							<Input fieldName='Search' state={searchTerm} setState={setSearchTerm} type='text' width='15vw' />
						</div>
						<div className='flex'>
							<Button
								type='fetch'
								onClick={() => {
									setPublish({ ...publishState, publish: true });
								}}>
								Publish
							</Button>
							<Button
								type='save'
								onClick={() => {
									getAutoIndexdddd(true);
									setNewAll(true);
								}}>
								Last Updated {date}
							</Button>
							<Button
								type={needWl ? 'save' : 'fetch'}
								onClick={() => {
									setWl(!needWl);
								}}>
								{!needWl ? 'Show WL' : 'Show Seat Aloted'}
							</Button>
							<Button
								type={showAdvance ? 'save' : 'fetch'}
								onClick={() => {
									setAdvance(!showAdvance);
								}}>
								{showAdvance ? 'Show Normal Mode' : 'Show Advance Mode'}
							</Button>
							<Button fileName={`${tableItems?.[0]?.departmentName.slice(13)}_${type.toUpperCase()}_${tableItems?.[0]?.subjectName}`} type='print' tableRef={ref.current}>
								Print
							</Button>
							<Button type='excel' tableRef={ref.current}>
								Excel
							</Button>
							<Button type='close' onClick={onClose}>
								Close
							</Button>
						</div>
					</Header>

					<Body>
						<div ref={ref} className='printable overflow-auto p-[10%] '>
							<div className=' bg-white m-68 w-[50%]'>
								<Table width='100%' innerWidth={['25%', '45%', '30%']}>
									<table className='min-w-full table-auto'>
										<thead>
											<tr>
												<td
													className='p-1 bg-white'
													style={{
														width: '70%',
														fontFamily: 'Times New Roman',
													}}>
													<img src={collegeData?.dashBoardLink} alt='College Logo' className='h-[100px] w-auto  mx-auto' />
												</td>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td
													className='p-1 text-center text-black bg-white text-xl font-bold'
													style={{
														width: '70%',
														fontFamily: 'Times New Roman',
													}}>
													{'Allotment Metrics'}
												</td>
											</tr>
										</tbody>
									</table>

									<Thead>
										<Tr>
											<Th position={0} fontSize='30px' onClick={() => requestSort('name')} icon={getIcon('name')}>
												Priority
											</Th>

											<Th textAlign='center' fontSize='30px' position={1} onClick={() => requestSort('rank')} icon={getIcon('rank')}>
												Count
											</Th>

											<Th textAlign='center' fontSize='30px' position={2} onClick={() => requestSort('index')} icon={getIcon('index')}>
												Grivence
											</Th>
										</Tr>
									</Thead>

									<Tbody height='auto'>
										{statistics?.map((x: any, i: number) => (
											<>
												<Tr>
													<Td index={i} fontSize='30px' position={0}>
														{x?.title}
													</Td>

													<Td textAlign='center' fontSize='30px' index={i} position={1}>
														{x?.count}
													</Td>
													<Td textAlign='left' fontSize='30px' index={i} position={2}>
														--
													</Td>
												</Tr>
											</>
										))}
									</Tbody>
									<table className='min-w-full table-auto'>
										<tbody>
											<tr>
												<td
													className='p-6 text-right text-black  bg-white text-xl font-bold'
													style={{
														width: '70%',
														fontFamily: 'Times New Roman',
													}}>
													Signature
												</td>
											</tr>
										</tbody>
									</table>
								</Table>
							</div>
						</div>
						{addNew?.viewMode && <ApplicationView uid={addNew?._id} viewMode={true} params={{ type: addNew?.tyeUG }} onClose={() => setAddNew({ ...addNew, viewMode: false })} />}
						{addNew?.permanent && <ApplicationView uid={addNew?._id} viewMode={true} params={{ type: addNew?.tyeUG }} onClose={() => setAddNew({ ...addNew, permanent: false })} />}
						{addNew?.temparary && <ApplicationView uid={addNew?._id} viewMode={true} params={{ type: addNew?.tyeUG }} onClose={() => setAddNew({ ...addNew, temparary: false })} />}
						{addNew?.remove && <RemoveAction userId={addNew?._id} completedApplication={false} classId={subjectId} indexMark={addNew?.indexMark} quota={addNew.quota} params={{ type: addNew?.tyeUG }} onClose={() => setAddNew({ ...addNew, remove: false })} />}
					</Body>
				</Main>
			)}
		</>
	);
}

export default RealTimeAllotment;
